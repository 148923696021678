@import "../../App/Styles/Variables.scss";

.steps-container-component {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  .step {
    width: 12.5%;
    display: flex;
    flex-direction: column;
    text-align: center;

    .step-details {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .progress-line {
      width: 42%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-evenly;

      .the-line {
        border-bottom: 1px solid #efefef;
        height: 2px;
        width: 100%;
      }
    }

    .progress-white-space {
      width: 40%;
    }

    .detail-container {
      width: 24px;

      .icon {
        cursor: pointer;
      }

      .step-number {
        width: 24px;
        height: 24px;
        border: 1px solid $greyishBlue;
        border-radius: 50%;
        text-align: center;
        font-family: "NotoSans-Regular";
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.active-step-number {
          color: $azureBlue;
          border-color: $azureBlue;
          cursor: pointer;

          &::before {
            background-color: $azureBlue;
          }
        }

        &.inactive-step-number {
          color: $greyishBlue;
          border-color: $greyishBlue;

          &::before {
            background-color: $greyishBlue;
          }
        }
      }
    }

    .step-text {
      width: 100%;
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: $darkGreyishBlue;
      margin-top: 5px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .completed-step-text {
      font-family: "NotoSans-Medium";
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: $azureBlue;
      margin-top: 5px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .step-text-phone {
      display: none;

      @media (max-width: 768px) {
        display: block;
        font-family: "NotoSans-Medium";
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: $azureBlue;
        margin-top: 5px;
      }
    }
  }
}