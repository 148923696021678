@import "../Variables.scss";

.contact-us-outside-margins {
  padding: 0px 4px 0px 10px;

  @media (max-width: 1500px) {
    padding: 0px 9px 0px 10px;
  }

  @media (max-width: 1200px) {
    margin: 30px 1px 30px 0px;
    padding: 0px 9px 0px 5px;

    &.margin-change-complaint {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  @media (max-width: 768px) {
    margin: 10px 0px 0px 0px;
    padding: 0px;
  }

  .contact-us .cards-headings-cu .collapsable {
    height: fit-content;
    transition-duration: 1ms;
  }

  .contact-us {
    .background-card {
      margin-top: 10px;
      padding: 10px 20px 10px 20px;

      @media (max-width: 1500px) {
        padding-right: 10px;
      }

      @media (max-width: 1200px) {
        width: 100%;
        margin-left: 0px;
      }

      @media (max-width: 768px) {
        width: 98%;
        padding-right: 15px;
        margin-top: 5px;
        margin-left: auto;
      }
    }

    .heading-cu {
      width: auto;
      height: 35px;
      font-family: "NotoSans-Medium";
      font-weight: 500;
      font-size: 18px;
      line-height: 35px;
      letter-spacing: 0.2px;
      color: $standard-black;
      margin-bottom: 20px;

      @media (max-width: 400px) {
        margin-top: 5px;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .complaints-heading-cu {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $standard-black;
      margin-bottom: 10px;
    }

    .complaints-sub-heading-cu {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $standard-black;
      margin-bottom: 10px;
    }

    .cards-cu {
      position: center;
      width: 100%;
      box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
      border-radius: 4px;
      height: max-content;
      background: $white;
      margin-bottom: 20px;
      padding: 0px 0px 10px 0px;

      .card-header {
        background: $white;
        border: none;
      }

      .fa-angle-down:before {
        color: transparent;
        appearance: none;
        display: block;
        width: 24px;
        height: 24px;
        background-image: url("../../assets/img/drop-down-arrow.png");
        background-repeat: no-repeat;
        margin-top: 5px;
      }

      .fa-angle-up:before {
        color: transparent;
        display: block;
        width: 24px;
        height: 24px;
        background-image: url("../../assets/img/drop-up-arrow.png");
        background-repeat: no-repeat;
        margin-top: 5px;
      }

      @media (max-width: 1500px) {
        width: 99%;
      }

      @media (max-width: 400px) {
        font-size: 12px;
        line-height: 16.34px;
        margin: 0px 0px 20px 0px;
        padding: 0px 0px 10px 0px;
      }

      .card-header-right-section {
        padding-top: 5px;
      }
    }

    .neo-card {
      &.card {
        border: none;

        &.cards-headings-cu {
          &.collapsed {
            padding: 0px;

            .card-header {
              border-radius: 4px 4px 0px 0px;
              height: 60px;
              width: 100%;
              background-repeat: no-repeat;
              background-position: right;
              background-position-x: 99%;
              background-size: 23px;
              cursor: pointer;

              @media (max-width: 768px) {
                height: 40px;
                background-size: 16px;
                background-position-x: 95%;
              }

              .card-header-title {
                pointer-events: none;
              }
            }
          }
        }
      }
    }

    .neo-card.card.cards-cu.cards-headings-cu:not(.collapsed) {
      @media (max-width: 768px) {
        box-shadow: none;
      }

      .card-header {
        border: 2px solid $azureBlue;
        border-radius: 4px 4px 0px 0px;
        height: 60px;
        width: 100%;
        cursor: pointer;

        .card-header-title {
          pointer-events: none;
        }

        @media (max-width: 500px) {
          height: 40px;
        }

        @media (max-width: 400px) {
          border-radius: 4px;
        }
      }

      .card-body {
        .row {
          &.center-cards-mobile {
            @media (max-width: 768px) {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
            }
          }
        }
      }
    }

    .cards-headings-cu {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $standard-black;
      background: $white;

      @media (max-width: 500px) {
        padding-top: 0px;
        font-size: 14px;
        line-height: 16.34px;
      }
    }

    .icon-financial-advisers-cu {
      width: 80px;
      height: 80px;
      display: block;
      margin: 20px auto 20px auto;
    }

    .align-icon {
      text-align: center;

      .icon-employer-cu {
        width: 80px;
        height: 80px;
        margin: 20px auto 20px auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $purple;
        color: $white;
        font-size: 18px;
        font-family: "NotoSans-Regular";
        font-weight: 400;
        border-radius: 50%;
      }
    }

    .shadowbox-cu {
      box-sizing: border-box;
      background: $gray;
      border-radius: 5px;
      margin: 0px 0px 15px 0px;

      @media (max-width: 500px) {
        padding: 0px 2px 0px 2px;
      }
    }

    .row > .negate-col-padding {
      padding: 0px 4px 0px 4px;
    }

    .shadowbox-text-cu {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.133333px;
      color: $standard-black;
      padding: 30px;

      @media (max-width: 1500px) {
        padding: 20px;
      }

      @media (max-width: 1200px) {
        padding: 15px 20px 15px 20px;
      }

      @media (max-width: 400px) {
        padding: 10px 4px 10px 4px;
      }
    }

    .name-cu {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      text-align: center;
      color: $standard-black;
      margin-top: 10px;
      height: 25px;
    }

    .occupation-cu {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $darkerGreyishBlue;
      text-align: center;
      margin: 5px 8px 8px 8px;
    }

    .row {
      &.complaints-back-to-dashboard-padding {
        @media (max-width: 1200px) {
          padding-top: 5px;
        }
      }
    }
  }
}