@import "../Variables.scss";

.terms-conditions,
.modal {
  .modal-container {
    display: grid;
    place-items: center;

    @media (min-width: 768px) and (max-width: 1194px) {
      width: 111vw;
    }
  }
}

.terms-and-conditions-modal {
  .modal-content {
    border: none;
    border-radius: 5px;

    @media (max-width: 768px) {
      width: 400px;
    }

    @media (max-width: 400px) {
      width: 344px;
    }

    .modal-header {
      color: #00164e;
      border: none;
      padding-bottom: 0px;
      padding-left: 32px;

      h5 {
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 35px;
        letter-spacing: 0.2px;
        color: $standard-black;
        padding-bottom: 17px;
        padding-top: 9px;
      }
    }

    button.close {
      display: none;
    }

    .modal-body {
      border-top: 1px solid $greyLine;
      padding: 0px;

      .modal-content {
        .modal-text {
          overflow-y: scroll;
          overflow-x: none;
          height: 400px;
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: 0.133333px;
          color: $standard-black;
          padding-left: 26px;
          padding-right: 23px;
          padding-top: 20px;

          @media (max-width: 768px) {
            padding-left: 20px;
            padding-right: 16px;
          }

          ul {
            list-style-type: disc;
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      float: row;
      border: none;
      justify-content: center;
      flex-wrap: nowrap;
      border-top: 1px solid $greyLine;

      .btn-download {
        margin-right: 55%;
        padding-left: 0px;

        &.btn {
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          width: 420px;
          letter-spacing: 0.133333px;
          color: #0033aa;
          background-color: none;
          border: none;
          background: url("../../assets/img/download-img.png") no-repeat 0% 50%;
        }
      }

      .btn-success {
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 1.23px;
        color: $white;
        --bs-gradient: none;
        background-color: $azureBlue;
        border-color: $azureBlue;
        height: 40px;
        width: 205px;
        border-radius: 4px;
      }

      @media (max-width: 768px) {
        flex-direction: column-reverse;

        .btn-success {
          width: 90%;
        }

        .btn-download {
          margin-right: 0%;
          padding-left: 2px;

          &.btn {
            background: url("../../assets/img/download-img.png") no-repeat 10% 50%;
            margin-left: 10%;
            margin-top: 4%;
          }
        }
      }
    }
  }
}