@import "../../App/Styles/Variables.scss";

.product-css {
  @media (min-width: 1500px) {
    margin: 0px 4px 0px 10px;
  }

  @media (max-width: 1500px) {
    margin: 0px 9px 0px 10px;
  }

  @media (max-width: 1200px) {
    margin: 30px 10px 30px 5px;
  }

  @media (max-width: 768px) {
    margin: 10px 0px 0px 0px;
  }

  .background-card {
    &.products-view {
      margin-top: 10px;
      padding: 0px 18px 10px 18px;

      @media (max-width: 1200px) {
        width: 100%;
        margin-left: 0px;
      }

      @media (max-width: 768px) {
        padding: 0px 12px 10px 12px;
        width: 98%;
        margin-left: auto;
      }
    }
  }

  .savings-component-container {
    color: #424757;
    font-family: "NotoSans-SemiBold";
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;

    .savings-cards {
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      background: #fff;
      box-shadow: 0px 1px 4px 0px rgba(139, 146, 167, 0.5);
      padding: 15px;
      margin-top: 15px;

      &.total-balance {
        margin-top: 20px;
        border-top: 8px solid #214ebf;
      }

      &.vested-component {
        display: block;
        padding: 0px;

        .vested-heading {
          display: flex;
          justify-content: space-between;
          padding: 15px 15px 0px 15px;
        }

        .sub-vested-component {
          padding: 5px 15px 10px 30px;
          font-family: "NotoSans-Regular";
          font-weight: 400;

          .vested-sub-heading {
            display: flex;
            margin-bottom: 5px;
            justify-content: space-between;
          }
        }
      }

      &.savings-retirement-card {
        margin-bottom: 20px;
      }
    }
  }

  .card-heading-products {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $black;
    margin: 10px 0px 0px 0px;
    padding-top: 20px;
    padding-left: 8px;

    @media (max-width: 1500px) {
      padding-left: 2px;
    }

    @media (max-width: 500px) {
      font-weight: 500;
      padding-top: 10px;
      margin: 5px 0px 15px 0px;
    }

    @media (max-width: 400px) {
      font-weight: 500;
      margin-bottom: 0px;
    }
  }

  .nav {
    &.nav-pills {
      .nav-item:first-child {
        .nav-link {
          border-radius: 4px 0px 0px 4px;
        }
      }

      .nav-item:last-child {
        .nav-link {
          border-radius: 0px 4px 4px 0px;
        }
      }

      .nav-link {
        border: 2px solid $azureBlue;
        border-radius: 0px 0px 0px 0px;
        width: 203px;
        height: 48px;
        padding-top: 12px;

        @media (max-width: 900px) {
          width: 170px;
        }

        @media (max-width: 660px) {
          width: 100%;
        }

        @media (max-width: 500px) {
          display: none;
        }

        &.my-contribution {
          border-radius: 0px 4px 4px 0px;
        }

        &.active {
          background: $azureBlue;

          .card-tab-text-products {
            color: $white;
            width: 204px;
            padding-right: 22%;

            @media (max-width: 1150px) {
              width: 100%;
              padding-right: 0%;
            }
          }
        }
      }
    }
  }

  .dropdown-header-products {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $black;
    margin: 4% 0px 0px 1px;
  }

  .tabs-dropdown-products {
    display: none;

    @media (max-width: 500px) {
      display: contents;
      font-family: "NotoSans-Medium";
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $black;
    }
  }

  .tabs-dropdown-products {
    .react-select-bs__control {
      background:
        url("../../App/assets/img/drop-down-arrow.png") no-repeat 97% 50%,
        $shadowGrey;

      &.react-select-bs__control--menu-is-open {
        background:
          url("../../App/assets/img/drop-up-arrow.png") no-repeat 97% 50%,
          $shadowGrey;
      }

      .react-select-bs__indicators {
        display: none;
      }
    }
  }

  .card-tab-text-products {
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: $azureBlue;

    @media (max-width: 450px) {
      display: none;
    }
  }

  .line-products {
    width: 100%;
    fill: $gray;
    border: 1px solid $gray;
    margin: 5px 0px 10px 0px;

    &.line-two-products {
      margin-top: 20px;
    }

    @media (max-width: 500px) {
      &.line-two-products {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 400px) {
      margin-top: 10px;
    }
  }

  .scheme-information-cards-container {

    .scheme-information-cards {
      width: 100%;
      min-height: 95px;
      height: 100%;
      background: $white;
      box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
      border-radius: 5px;
      padding: 1.5% 1% 0px 2.5%;
      margin: 0px 0px 3% 0%;

      @media (max-width: 1500px) {
        margin: 0px 10px 3% 0%;
      }

      @media (max-width: 786px) {
        min-height: 95px;
        height: 100%;
        margin: 0px 10px 4.5% 0%;
      }


      &.card-one {
        border-left: 8px solid $azureBlue;
      }

      &.card-two {
        border-left: 8px solid #214ebf;
      }

      &.card-three {
        border-left: 8px solid $hoverBlue;
      }

      &.card-four {
        border-left: 8px solid #84a7fa;
      }
    }

    .scheme-information-heading {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 18px;
      line-height: 31px;
      color: #424757;
    }

    .scheme-information-description {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;
      color: $black;

      &.green {
        color: #36b37e;
      }

      &.yellow {
        color: #ffab00;
      }

      &.eligibility-reason {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .col-1 {
    &.error-icon {
      @media (min-width: 1100px) {
        width: 32px;
      }

      @media (max-width: 1099px) {
        width: 4%;
      }

      @media (max-width: 950px) {
        width: 6%;
      }

      @media (max-width: 500px) {
        width: 8%;
      }

      @media (max-width: 400px) {
        margin-right: 1px;
      }
    }
  }

  .table-headers-investment {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 31px;
    color: $black;
    text-align: center;
    margin: 20px 0px 5px 0px;

    @media (max-width: 1400px) {
      font-size: 15px;
    }

    @media (max-width: 500px) {
      font-size: 12px;
      line-height: 10px;
      text-align: left;
      margin: 5px 0px 0px 0px;
    }

    &.heading-name-padding-investment {
      text-align: left;
      padding-left: 20px;
    }

    &.value-padding-investment {
      @media (max-width: 1500px) {
        padding-left: 51%;
      }

      @media (max-width: 1200px) {
        padding-left: 27%;
      }

      @media (max-width: 500px) {
        padding-left: 0%;
        margin-top: 2%;
      }
    }

    &.heading-sheet-padding-investment {
      padding-left: 30%;

      @media (max-width: 1500px) {
        padding: 0px 1% 0px 37%;
      }

      @media (max-width: 1200px) {
        padding-left: 5%;
      }

      @media (max-width: 500px) {
        padding-left: 0%;
      }

      @media (max-width: 3500px) {
        padding-left: 20%;
      }
    }
  }

  .col-3 {
    &.padding-left-last-retirement-contribution {
      padding-left: 0px;
    }
  }

  .table-row-cards-investment {
    height: 74px;
    width: 99.5%;
    background: $white;
    box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
    border-radius: 4px;
    margin: 1.3% 0px 1% 3px;
    padding: 6px 0px 0px 1%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;

    @media (max-width: 1500px) {
      width: 99%;
      margin-bottom: 1.5%;
    }

    @media (max-width: 1200px) {
      width: 99%;
      margin-bottom: 1.5%;
    }

    @media (max-width: 500px) {
      height: 100px;
      width: 98%;
      padding: 4% 0px 0px 4%;
      margin-bottom: 3.5%;
      display: list-item;
    }

    @media (max-width: 400px) {
      width: 97%;
      margin-bottom: 3.8%;
    }

    @media (min-width: 2500px) and (max-width: 3500px) {
      width: 97%;
      margin: 1.3% 0px 1% 30px;
      padding: 0.8% 0px 0px 1%;
    }
  }

  .table-column-investment-name {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $black;

    @media (max-width: 1500px) {
      font-size: 12px;
      padding-left: 0.5%;
    }

    @media (max-width: 500px) {
      font-size: 12px;
      padding-left: 0%;
    }
  }

  .table-column-investment-amount {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $black;
    text-align: center;

    @media (max-width: 500px) {
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      margin-top: 10px;
      padding-left: 3%;
    }

    &.value-padding-investment {
      padding-left: 6%;

      @media (max-width: 1500px) {
        padding-left: 53%;
      }

      @media (max-width: 1200px) {
        padding-left: 28%;
      }

      @media (max-width: 500px) {
        padding-left: 3%;
        font-size: 12px;
      }
    }
  }

  .table-column-investment-sheet {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.133333px;
    color: #0033aa;
    text-align: center;
    padding-left: 30%;
    cursor: pointer;

    @media (max-width: 1500px) {
      padding-left: 40%;
    }

    @media (max-width: 1200px) {
      padding-left: 20%;
    }

    @media (max-width: 500px) {
      font-family: "NotoSans-Bold";
      font-weight: 700;
      font-size: 10px;
      line-height: 20px;
      text-decoration-line: underline;
      padding-left: 3%;
    }
  }

  .savings-cards-grid {
    background: $white;
    margin-bottom: 30px;

    .savings-container {
      height: max-content;

      @media (max-width: 1200px) {
        height: max-content;
      }
    }

    &.collapsed {
      .savings-container {
        height: 10px;
        mask-image: linear-gradient(rgba(255, 255, 255, 0.3) 0%, transparent);
      }
    }
  }

  .investment-table {
    background: $white;
    overflow: hidden;
    margin-bottom: 1%;

    .investment-container {
      height: max-content;
      transition: height 2s;

      @media (max-width: 1200px) {
        height: max-content;
      }
    }

    &.collapsed {
      .investment-container {
        height: 80px;
      }
    }

    @media (max-width: 500px) {
      display: none;
    }
  }

  .phone-investment-table {
    display: none;

    @media (max-width: 500px) {
      display: block;
      background: $white;
      overflow: hidden;
      margin-bottom: 1%;

      .phone-investment-container {
        height: max-content;
        transition: height 2s;
      }

      &.collapsed {
        .phone-investment-container {
          height: 110px;
        }
      }
    }
  }

  .member-investment-summary-products {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.133333px;
    color: #0033aa;
    text-align: right;
    padding: 5px 20px 0px 0px;
    margin-bottom: -40px;

    @media (max-width: 1500px) {
      padding-right: 0.5%;
    }

    @media (max-width: 1200px) {
      font-size: 14px;
    }

    @media (max-width: 1150px) {
      text-align: left;
      margin: 1% 0px 2% 0px;
    }

    @media (max-width: 786px) {
      text-align: left;
      font-size: 16px;
      margin: 6% 0px 0px 0px;
    }

    .align-investment-summary {
      cursor: pointer;
    }

    .align-benefit-summary {
      cursor: pointer;
    }
  }

  .row {
    &.neo-tab-container {
      .tabs-header {
        width: 80%;

        .nav {
          &.nav-pills {
            margin-left: 5px;

            @media (max-width: 1500px) {
              margin-left: 1px;
            }
          }
        }
      }
    }
  }

  .set-link-css {
    text-decoration: none;

    @media (max-width: 768px) {
      text-decoration: underline;
      padding-left: 5px;
    }
  }

  .pdf-icon-product {
    width: 24px;
    height: 24px;

    &.error {
      margin-right: 0px;
    }

    &.hover {
      cursor: pointer;
      margin-left: 5px;
    }

    @media (max-width: 500px) {
      &.table-icon {
        width: 13px;
        height: 13px;
        margin-right: 1%;
      }
    }
  }

  .show-more-overlay {
    display: flex;
    justify-content: space-evenly;

    .show-more-less-button-investment {
      &.btn {
        &.btn-primary {
          width: 176px;
          height: 40px;
          border-color: $azureBlue;
          border-width: 2px;
          background-color: $white;
          font-family: "NotoSans-Medium";
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 1.23px;
          color: $azureBlue;
          align-items: center;

          @media (max-width: 500px) {
            width: 131px;
            height: 36px;
            font-size: 14px;
            border: 2px solid $azureBlue;
            border-radius: 4px;
          }
        }
      }
    }
  }

  // TODO: Remove once background card has been fixed
  .blue-ribbon {
    .display-alert-profile {
      &.alert {
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  ////////////Risk benefits///////////////////
  .product-details-alert {
    width: 100%;
    height: fit-content;
    font-family: "NotoSans-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $standard-black;
    background: #deecff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin: 20px 0px 10px 0px;
    padding: 10px 5px 10px 10px;
  }

  .cards-risk {
    position: center;
    width: 100%;
    height: max-content;
    background: $text-color;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;

    .card-header {
      background: $white;
      border: none;
    }

    .fa-angle-down:before {
      color: transparent;
      appearance: none;
      display: block;
      width: 24px;
      height: 24px;
      background-image: url("../../App/assets/img/drop-down-arrow.png");
      background-repeat: no-repeat;
      margin-top: 10px;
    }

    .fa-angle-up:before {
      color: transparent;
      display: block;
      width: 24px;
      height: 24px;
      background-image: url("../../App/assets/img/drop-up-arrow.png");
      background-repeat: no-repeat;
    }

    @media (max-width: 1500px) {
      width: 100%;
    }

    @media (max-width: 1200px) {
      box-shadow: none;

      &.card {
        border: none;
      }
    }

    @media (max-width: 400px) {
      font-size: 12px;
      line-height: 16.34px;
      margin: 0px 0px 20px 0px;
    }
  }

  .cards-headings-risk {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $standard-black;
    background: $white;

    @media (max-width: 500px) {
      padding-top: 0px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .neo-card {
    &.card {
      &.cards-risk {
        &.cards-headings-risk {
          .card-header {
            height: 60px;
            line-height: 24px;
            border-radius: 4px;
            cursor: pointer;

            .card-header-title {
              height: 60px;
              display: flex;
              align-items: center;
              pointer-events: none;
            }

            .card-header-right-section {
              height: 60px;
            }
          }

          &.collapsed {
            height: 60px;
            background: $white;
            box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
            border-radius: 4px;
            border: none;
          }

          &.padding-top {
            &.collapsed {
              @media (max-width: 500px) {
                padding: 4px 0px;
              }
            }
          }

          &.padding-phone {
            &.collapsed {
              @media (max-width: 400px) {
                padding: 4px 0px;
              }
            }
          }

          .collapsable {
            padding-bottom: 0px;
          }
        }
      }
    }
  }

  .neo-card {
    &.card {
      &.cards-risk:not(.collapsed) {
        border: none;

        @media (max-width: 500px) {
          margin-bottom: 0px;
        }

        .card-header {
          font-family: "NotoSans-SemiBold";
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: $standard-black;
          border: 2px solid $azureBlue;
          border-radius: 4px 4px 0px 0px;
          height: 60px;
          width: 100%;

          .card-header-title {
            height: 60px;
            display: flex;
            align-items: center;
            pointer-events: none;
          }

          .card-header-right-section {
            height: 60px;
          }

          @media (max-width: 500px) {
            font-size: 14px;
            height: 60px;
          }

          @media (max-width: 400px) {
            border-radius: 4px;
          }
        }

        .card-body {
          padding-left: 0px;
          padding-right: 0px;
          padding-bottom: 8px;
        }
      }
    }
  }

  .card-body {
    .neo-card {
      &.card {
        &.cards-sub-risk {
          &.cards-sub-headings-risk {
            margin-bottom: 10px;

            &.collapsed {
              padding: 5px 0px 5px 0px;

              @media (max-width: 500px) {
                padding: 6px 0px 0px 0px;
                height: 41px;
              }

              .card-header {
                font-family: "NotoSans-Bold";
                font-weight: 700;
                font-size: 14px;
                line-height: 15px;
                letter-spacing: 0.133333px;
                color: $azureBlue;
                height: 40px;
                border: none;

                @media (max-width: 500px) {
                  font-size: 12px;
                  height: 38px;
                  padding-left: 13px;
                }

                .fa-angle-down:before {
                  @media (max-width: 768px) {
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .neo-card {
    &.card {
      &.cards-sub-risk {
        &.cards-sub-headings-risk {
          &.collapsed.card {
            font-size: 14px;
            box-shadow: 0px 1px 4px rgb(139 146 167 / 50%);
            border-radius: 4px;
            border: none;
            padding-top: 5px;
            width: 100%;

            @media (max-width: 500px) {
              font-size: 12px;
              padding-top: 3px;
              height: 41px;
            }
          }
        }
      }

      &.cards-sub-risk:not(.collapsed) {
        border: none;

        .card-header {
          font-family: "NotoSans-Bold";
          font-weight: 700;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: 0.133333px;
          color: $azureBlue;
          border: 2px solid $azureBlue;
          border-radius: 4px 4px 0px 0px;
          height: 50px;
          width: 100%;

          @media (max-width: 500px) {
            font-size: 12px;
            height: 41px;
            padding-left: 11px;
          }

          @media (max-width: 400px) {
            border-radius: 4px;
          }
        }
      }
    }
  }

  .cards-sub-headings-risk {
    font-family: "NotoSans-Bold";
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.133333px;
    color: $azureBlue;
  }

  .table-title-risk {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.133333px;
    color: $standard-black;

    @media (max-width: 500px) {
      font-size: 12px;
      padding-left: 6px;
    }

    &.bold {
      font-family: "NotoSans-Bold";

      @media (max-width: 500px) {
        padding-left: 0px;
      }
    }
  }

  .table-width-risk {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    border-collapse: separate;
    border: 1.5px solid $greyLine;
    border-radius: 5px;
    overflow: hidden;
    border-spacing: 0;
  }

  table.table-width-risk th:not(:last-child),
  table.table-width-risk td:not(:last-child) {
    border-right: 1px solid $greyLine;
  }

  table.table-width-risk > thead > tr:not(:last-child) > th,
  table.table-width-risk > thead > tr:not(:last-child) > td,
  table.table-width-risk > tbody > tr:not(:last-child) > th,
  table.table-width-risk > tbody > tr:not(:last-child) > td,
  table.table-width-risk > tfoot > tr:not(:last-child) > th,
  table.table-width-risk > tfoot > tr:not(:last-child) > td,
  table.table-width-risk > tr:not(:last-child) > td,
  table.table-width-risk > tr:not(:last-child) > th,
  table.table-width-risk > thead:not(:last-child),
  table.table-width-risk > tbody:not(:last-child),
  table.table-width-risk > tfoot:not(:last-child) {
    border-bottom: 1px solid $greyLine;
  }

  .table-border-risk {
    width: 25%;
    height: 60px;
    background: $white;
    font-family: "NotoSans-medium";
    color: $standard-black;
    line-height: 19px;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 10px 0px 12px;

    @media (max-width: 500px) {
      width: 33%;
      font-size: 12px;
      padding: 0px 10px 0px 10px;
    }

    &.padding-phone-screen {
      @media (max-width: 400px) {
        padding: 0px 5px 0px 3px;
      }
    }

    &.padding-phone-screen {
      &.remove-border-right-phone-risk {
        @media (max-width: 500px) {
          border-right: none;
        }
      }
    }

    &.table-column-content-risk {
      &.add-border {
        border-right: 1px solid $greyLine;
      }

      &.remove-border-right-phone-risk {
        @media (max-width: 500px) {
          border-right: none;
        }

        &.border-right-risk {
          @media (max-width: 500px) {
            border-right: none;
          }
        }
      }
    }

    &.table-column-underwriting-heading-risk {
      @media (max-width: 500px) {
        display: none;
      }
    }

    &.table-column-phone-screen-underwriting-risk {
      height: 0px;
      border: none;
      border-radius: 0px;

      @media (max-width: 500px) {
        border: 1px solid $greyLine;
        border-radius: 5px;
      }

      td {
        padding: 0px;
      }
    }

    &.table-row-header-risk {
      font-family: "NotoSans-Regular";
      font-weight: 600;

      &.remove-border-bottom-web-risk {
        @media (min-width: 500px) {
          border-bottom: none;
        }
      }
    }

    &.table-column-content-risk {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      text-align: right;

      &.border-right-risk {
        border-right: 1px solid $greyLine;
      }

      &.remove-border-bottom-web-risk {
        @media (min-width: 500px) {
          border-bottom: none;
        }
      }
    }

    &.table-column-underwriting-risk {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.133333px;

      @media (max-width: 500px) {
        display: none;
      }

      &.remove-border-bottom-web-risk {
        @media (min-width: 500px) {
          border-bottom: none;
        }
      }
    }

    &.table-blank-right {
      width: 75%;
      border-right: none;

      @media (max-width: 500px) {
        width: 25%;
      }
    }

    &.table-blank-column-risk {
      border-left: none;
      border-right: none;
    }

    &.table-column-green-header-risk {
      font-family: "NotoSans-Bold";
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #36b37e;

      @media (max-width: 500px) {
        font-size: 12px;
        line-height: 16.34px;
      }
    }

    &.table-column-green-content-risk {
      font-family: "NotoSans-Bold";
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #36b37e;
      text-align: right;

      @media (max-width: 500px) {
        width: 25%;
        font-size: 12px;
        line-height: 16.34px;
      }
    }
  }

  .table-column-phone-screen-heading-underwriting-risk {
    display: none;

    @media (max-width: 500px) {
      display: block;
      font-family: "NotoSans-medium";
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      color: $standard-black;
      padding: 8px 0px 0px 12px;
    }
  }

  .table-column-phone-screen-content-underwriting-risk {
    display: none;

    @media (max-width: 500px) {
      display: block;
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 0.133333px;
      color: $standard-black;
      padding: 5px 5px 15px 12px;
    }
  }

  .table-column-phone-screen-heading-underwriting-risk {
    font-family: "NotoSans-medium";
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    color: $standard-black;
  }

  .table-complete-now-risk {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.133333px;
    text-decoration-line: underline;
    color: #0033aa;
  }

  .grey-box-risk {
    width: 98%;
    height: 72px;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.133333px;
    color: $standard-black;
    background: $gray;
    border-radius: 5px;
    padding: 15px;
    margin: 20px 0px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 500px) {
      width: 100%;
      font-size: 12px;
      height: max-content;
      margin-left: 0px;
    }
  }

  .hide-on-phone-risk {
    @media (max-width: 500px) {
      display: none;
    }
  }

  .space-phone-screen-risk {
    display: none;

    @media (max-width: 400px) {
      display: contents;
    }
  }

  .line-break-buttons-risk {
    display: none;

    @media (max-width: 500px) {
      display: contents;
    }
  }

  .underline-green-risk {
    border-bottom: 2px solid #36b37e;
    pointer-events: none;
  }

  //////////////////////Contribution////////////////////////

  .row {
    &.contribution-cards-cb {
      margin-top: 20px;
    }
  }

  .card-heading-cb {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    color: #0033aa;
  }

  .card-sub-heading-cb {
    font-family: "NotoSans-Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    color: $standard-black;
    display: inline-flex;

    @media (min-width: 768px) and (max-width: 1200px) {
      font-size: 13px;
    }

    &.regular {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      margin-left: 5px;
    }
  }

  .card-header-height-cb {
    opacity: 0;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .neo-card {
    &.card {
      &.contribution-card-cb {
        box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
        border-radius: 5px;
        border: none;

        @media (max-width: 767px) {
          margin-bottom: 20px;
        }

        .card-header {
          background-color: #f2f6ff;
          border-bottom: none;

          @media (max-width: 1200px) {
            padding-right: 1px;
          }

          @media (max-width: 767px) {
            height: auto;
          }
        }

        .card-body {
          padding: 0px 0px;
        }
      }
    }
  }

  .column-contribution-expenses-cb {
    height: 60px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $standard-black;
    border: 1px solid $greyLine;
    padding: 15px;
    width: 50%;

    @media (max-width: 500px) {
      font-size: 12px;
      line-height: 16.34px;
    }

    @media (max-width: 400px) {
      width: 60%;
    }

    &.left-align {
      border-left: none;
    }

    &.right-align {
      border-right: none;
      text-align: right;
    }
  }

  tfoot {
    .column-contribution-expenses-cb {
      &.left-align {
        font-family: "NotoSans-Bold";
        font-weight: 700;
      }
    }
  }

  .column-details-cb {
    height: 48px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $standard-black;
    border: 1px solid $greyLine;
    padding: 15px;

    @media (max-width: 1200px) {
      padding: 2.5%;
      height: 48.9px;
    }

    @media (max-width: 860px) {
      height: 48.7px;
    }

    @media (max-width: 500px) {
      font-size: 12px;
      line-height: 16.34px;
    }

    @media (max-width: 400px) {
      width: 60%;
    }

    &.left-align {
      border-left: none;
    }

    &.right-align {
      border-right: none;
      text-align: right;
    }
  }

  .phone-line-break-contribution {
    display: none;

    @media (max-width: 767px) {
      display: contents;
    }
  }

  .col-6 {
    &.phone-width-cb {
      @media (max-width: 1200px) {
        width: 60%;
      }

      @media (max-width: 500px) {
        width: 50%;
      }

      @media (max-width: 400px) {
        padding: 15.25px 2px 15.25px 12px;
        width: 61%;
      }
    }

    &.phone-amount-width-cb {
      @media (max-width: 1200px) {
        width: 40%;
      }

      @media (max-width: 500px) {
        width: 50%;
      }

      @media (max-width: 400px) {
        padding: 15.25px 12px 15.25px 2px;
        width: 39%;
      }
    }
  }

  thead {
    display: none;
  }

  .align-claim-button {
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
    padding-right: 0px;
    align-items: center;
    margin-left: 30px;

    @media (max-width: 768px) {
      justify-content: flex-start;
      margin-left: 0px;
    }

    .btn-update-claim {
      background: $azureBlue;
      color: $white;
      border-radius: 4px;
      width: 30%;
      margin-right: 0%;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1.23px;
      height: 40px;

      @media (max-width: 768px) {
        width: 93%;
        margin-bottom: 6%;
      }

      @media (min-width: 768px) and (max-width: 1194px) {
        height: 42px;
      }
    }
  }
}

.pipe-separator {
  @media (max-width: 500px) {
    display: none;
  }
}