@import "../Variables.scss";

.page-not-found-container {
  min-height: calc(100vh - #{$page-height});
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  .page-not-found {
    display: flex;
    justify-content: center;

    .message-container {
      .error-row {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }

      .content-div {
        width: 50%;

        @media (max-width: 768px) {
          width: 100%;
        }

        .page-not-found-header {
          font-family: "NotoSans-SemiBold";
          font-style: normal;
          font-weight: 600;
          font-size: 60px;
          line-height: 68px;
          letter-spacing: 0.133333px;
          color: #00164e;
          margin-top: 5%;
          text-align: center;

          @media (max-width: 768px) {
            font-size: 40px;
            line-height: 50px;
          }

          p {
            @media (max-width: 768px) {
              margin-bottom: 0px;
            }
          }
        }

        .page-not-found-content {
          font-family: "NotoSans-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 32px;
          letter-spacing: 0.133333px;
          color: #00164e;
          text-align: center;
          margin-top: 3%;
          margin-left: 3%;
          width: 93%;

          @media (min-width: 768px) and (max-width: 1194px) {
            margin-left: 1%;
            width: 100%;
          }

          @media (max-width: 768px) {
            font-size: 20px;
            width: 100%;
            margin-left: 0%;
            margin-top: 0%;
          }
        }

        .img_container {
          text-align: center;

          .img_404_display {
            width: 444px;
            height: 174px;

            @media (max-width: 768px) {
              width: 211px;
              height: 87px;
            }
          }
        }

        .return-to-home {
          font-family: "NotoSans-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
          text-align: center;
          margin-top: 5%;

          a {
            text-decoration: none;
            color: $hoverBlue;
          }
        }
      }

      .employee-img-container {
        padding: 0px;

        @media (max-width: 768px) {
          text-align: center;
        }

        .employee-img {
          @media (max-width: 768px) {
            width: 168px;
            height: 142px;
            margin-top: 6%;
          }
        }
      }
    }
  }
}