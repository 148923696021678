@import "../Variables.scss";

.version-number {
  font-family: "NotoSans-Light";
  color: #00164e;
  font-size: 0.56rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

.member-profile-card-non-editable {
  width: auto;
  background: $white;
  box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  height: auto;
  border-radius: 5px;
  margin: 40px 15px 30px 15px;

  @media (max-width: 1200px) {
    margin: 30px 10px 30px 5px;
  }

  @media (max-width: 768px) {
    margin: 30px 3px 30px 5px;
  }

  .card-heading-profile {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;
    margin-left: 16px;
    margin-bottom: 0px;
  }

  .align-profile-info {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    margin-left: 8px;
    height: 67px;

    @media (max-width: 768px) {
      display: block;
      margin-left: 6px;
      height: auto;

      .card-heading-profile {
        margin-top: 6px;
        margin-bottom: 0px;
        height: 15px;
      }
    }
  }

  //Hiding align-alert-info for Launch 1

  .align-alert-info {
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 21px;

    img {
      display: none;
    }

    @media (max-width: 768px) {
      justify-content: flex-start;
      margin-left: 17px;

      .alert-text {
        display: none;
        padding-left: 3%;
      }
    }
  }

  .alert-text {
    font-family: "NotoSans-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 68px;
    letter-spacing: 0.133333px;
    color: $standard-black;
    padding-left: 1%;
    margin-bottom: 0px;
    display: none;
  }

  .form-group {
    .form-control {
      margin-left: 2%;
      height: 48px;
      width: 88%;
      color: $standard-black;
      background-color: $shadowGrey;
      font-size: 18px;
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #afb3c2;

      &:disabled {
        color: #afb3c2;
        border: 1px solid $darkShadowGrey;
        width: 88.8%;

        @media (max-width: 768px) {
          width: 96%;
        }
      }
    }

    label {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $standard-black;
      margin-left: 2%;
      margin-bottom: 1%;
      margin-top: 2rem;

      @media (max-width: 768px) {
        margin-top: 1.5rem;
      }
    }
  }

  .form-label {
    color: black;
    margin-left: 8px;
  }

  .line-profile {
    margin-top: 2rem;
    margin-bottom: 0rem;
    margin-left: 4%;
    width: 92%;
    fill: $gray;
    border: 1px solid $gray;
  }

  .row {
    &.align-divider {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .divider1 {
    padding-left: 0px;
    margin-top: 0rem;
    margin-bottom: 2rem;
    margin-left: 0px;
    width: 100%;
    height: 0px;
    border-bottom: 1px solid $greyLine;

    @media (max-width: 768px) {
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  .separating-line-profile {
    padding-left: 0px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 0px;
    width: 100%;
    height: 0px;
    border-bottom: 1px solid $greyLine;

    @media (max-width: 768px) {
      margin-bottom: 0.5rem;
    }
  }

  .align-form-group-row-profile {
    &.row {
      margin-left: 3%;
    }
  }

  .align-cell-label-profile {
    margin-left: 1%;

    &.form-label {
      margin-left: 14px;
      margin-top: 29.5px;
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      @media (max-width: 768px) {
        margin-left: 4px;
        margin-top: 1.5rem;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        margin-left: 8px;
      }

      @media (min-width: 3500px) {
        margin-left: 36px;
      }
    }
  }

  .country-dropdown-row-second-profile {
    &.row {
      margin-top: 0px;
      margin-left: 2%;
    }

    .input-group {
      padding-left: 0px;

      &.align-on-andriod {
        @media (max-width: 360px) {
          width: 100%;
        }
      }
    }
  }

  .dropdown-row {
    &.row {
      margin-top: 0px;
      margin-left: 2%;
      width: 100%;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    .input-group {
      padding-left: 0px;
    }
  }

  .country-dropdown-row-profile {
    &.row {
      margin-top: 0px;
      margin-left: 0px;

      @media (max-width: 768px) {
        margin-left: 5px;
        padding-left: 0px;
      }

      @media (max-width: 768px) {
        .country-codes-dropdown-profile {
          margin-bottom: 2%;
          padding-left: 0%;
          width: 53px;
          height: 48px;
        }
      }

      @media (max-width: 360px) {
        .country-codes-dropdown-profile {
          width: 43px;
        }
      }
    }

    .country-col {
      @media (min-width: 768px) and (max-width: 1200px) {
        margin-left: 0px;
        padding-left: 6px;
        padding-right: 0px;
      }
    }
  }

  .country-codes-dropdown-profile {
    margin-left: 2%;
    width: auto;
    height: 48px;

    .react-select-bs {
      &.react-select-bs.react-select-bs--is-disabled {
        border-radius: 4px;
        height: 48px;

        .react-select-bs__control {
          background-color: $shadowGrey;
          height: 48px;
          border: 1px solid $darkShadowGrey;

          .react-select-bs__indicators {
            display: none;
          }
        }
      }
    }
  }

  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0px;
  }

  .input-group {
    width: 90%;
    height: 48px;
    overflow: hidden;
    border-radius: 4px;
    border: none;

    @media (max-width: 768px) {
      width: 100%;
    }

    &.second {
      width: 93%;
      padding: 0px;
      border: 1px solid $darkShadowGrey;

      @media (max-width: 768px) {
        width: 89%;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        width: 89%;
      }
    }

    &.cell-group-control {
      width: 90%;
      border: 1px solid $darkShadowGrey;

      @media (max-width: 768px) {
        width: 100%;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        width: 90%;
      }
    }

    .form-control {
      border: none;
      border-top: none;
      border-bottom: none;
      border-left: none;

      &:disabled {
        border-radius: 4px;
        color: #afb3c2;
        border: none;
        background-color: $shadowGrey;
        border-right: none;
      }

      &.add-border {
        border: 1px solid $darkShadowGrey;
      }
    }

    .input-group-text {
      background-color: $white;
      border: none;
      border-left: 1px solid $darkShadowGrey;

      //For Editable profile uncomment it.
      display: none;

      .form-check-input {
        width: 1.5em;
        height: 1.5em;

        &:focus {
          box-shadow: none;
          border-color: #afb3c2;
        }
      }

      .form-check-input:checked {
        background: url(../../assets/img/checked.png);
        background-color: none;
        border-color: none;
        background-color: $azureBlue;
        padding: 0px;
      }
    }
  }

  .align-communication-method-profile {
    padding-top: 1.8rem;
    padding-left: 0px;
    color: black;

    &.show-radio-buttons {
      display: none;
    }

    .react-select-bs {
      .css-yk16xz-control {
        font-family: "NotoSans-Regular";
        box-sizing: border-box;
        background-color: $shadowGrey;
        border: none;
        border-radius: 4px;
        height: 46px;
      }
    }

    .css-2b097c-container {
      width: 89%;
      padding: 0px;
    }

    .align-communication-label {
      font-family: "NotoSans-Regular";
      margin-left: 2%;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    .align-province-label {
      margin-top: 0px;
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-left: 26px;
      margin-bottom: 0.37rem;

      @media (min-width: 768px) and (max-width: 1200px) {
        padding-bottom: 4px;
      }
    }

    @media (max-width: 768px) {
      padding-left: 0rem;

      .css-2b097c-container {
        margin-left: 1.5%;
        width: 90%;
      }

      .align-province-label {
        margin-left: 16px;
      }
    }

    .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      margin-left: 0px;
    }

    .input-group {
      width: 91.5%;
      height: 48px;
      border-radius: 4px;
      border: none;
      overflow: inherit;
      flex-wrap: nowrap;

      &.dropdown-input-group {
        width: 90%;
        border: 1px solid $darkShadowGrey;
        padding-right: 0px;
        height: 48px;
        border-radius: 4px;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;

        @media (min-width: 768px) and (max-width: 1200px) {
          width: 90%;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &.second {
        width: 93%;
        padding: 0px;
      }

      &.cell-group-control {
        width: 96%;
        border: 1px solid $darkShadowGrey;
      }

      .form-control {
        border: none;
        border-top: none;
        border-bottom: none;
        border-left: none;
        border-right: none;
        padding: 0px;
        margin-bottom: 0px;
      }

      .input-group-text {
        background-color: $white;
        border: none;

        &.dropdown-append-profile {
          border-left: 1px solid #afb3c2;
          background-color: $white;
          margin-left: 0px;
        }

        .form-check-input {
          width: 1.5em;
          height: 1.5em;

          &:focus {
            box-shadow: none;
            border-color: #afb3c2;
          }
        }

        .form-check-input:checked {
          background: url(../../assets/img/checked.png);
          background-color: none;
          border-color: none;
          background-color: $azureBlue;
          padding: 0px;
        }
      }
    }
  }

  .align-radio-profile {
    color: $standard-black;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-top: 2%;
    padding-left: 4%;

    label {
      flex: 1 1 auto;
      color: $standard-black;
    }
  }

  .custom-control-input {
    margin-right: 4%;
    width: 25px;
    height: 25px;
  }

  .custom-control-label {
    font-size: 16px;
    font-weight: 500;
    font-style: "NotoSans-Medium";
  }

  .custom-control-inline {
    width: 35%;
    display: flex;
    align-items: center;
  }

  .custom-control-inline {
    input[type="radio"] {
      accent-color: $azureBlue;
    }
  }
}