.editor {
  .sun-editor {
    .se-toolbar {
      z-index: 2;
    }

    .se-resizing-bar {
      display: none;
    }

    .se-table-selected-cell {
      outline: 1px double #e1e1e1e1;
    }
  }
}