@import "../Variables.scss";

.queries-css {
  .membership-number-control {
    &.col-md-12 {
      width: 88%;
    }
  }

  .align-buttons-desktop {
    &.row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 0rem 0rem 0.3rem;
    }
  }

  .align-buttons-mobile {
    &.row {
      display: none;
    }
  }

  .align-radio {
    color: $standard-black;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-top: 1%;

    label {
      flex: 1 1 auto;
    }
  }

  .align-communication-method {
    padding-top: 0px;
  }

  .custom-control-input {
    margin-right: 4%;
    width: 20px;
    height: 20px;
  }

  .custom-control-label {
    font-size: 16px;
    font-weight: 500;
    font-style: "NotoSans-Medium";
  }

  .custom-control-inline {
    width: 31%;
    display: flex;
    align-items: center;
  }

  .custom-control-inline {
    input[type="radio"] {
      accent-color: $azureBlue;
    }
  }

  .phone-control {
    label {
      display: none;
    }

    .form-control:disabled {
      height: 48px;
      box-sizing: border-box;
      background: $shadowGrey;
      border: 1px solid $darkShadowGrey;
      border-radius: 4px;
      font-family: "NotoSans-Regular";
      color: $standard-black;
    }
  }

  .query-control {
    label {
      display: none;
    }

    .form-control {
      font-family: "NotoSans-Regular";
      box-sizing: border-box;
      background: $shadowGrey;
      border-radius: 4px;
      border: 1px solid #afb3c2;
    }
  }

  .upload-card {
    padding: 8px;

    &.card {
      background: $white;
      border-radius: 4px;
      border: none;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    }
  }

  .email-control {
    .form-control {
      height: 48px;
      font-family: "NotoSans-Regular";
      box-sizing: border-box;
      background: $shadowGrey;
      border: 1px solid #afb3c2;
      border-radius: 4px;
      color: $standard-black;
    }
  }

  .tel-control-queries {
    width: 100%;

    .form-control {
      height: 48px;
      font-family: "NotoSans-Regular";
      box-sizing: border-box;
      background: $shadowGrey;
      border: 1px solid #afb3c2;
      border-radius: 4px;
      color: $standard-black;
    }
  }

  .scheme-card {
    &.card {
      padding-top: 0px;
      width: max-content;
      color: black;
      box-shadow: none;
      --bs-card-border-width: 0;
      width: max-content;
      font-family: "NotoSans-Regular";
      box-sizing: border-box;
      background: $shadowGrey;
    }

    &.collapsed.card {
      width: max-content;
      font-family: "NotoSans-Regular";
      box-sizing: border-box;
      background: $shadowGrey;
      --bs-card-border-width: 0;
      box-shadow: none;
      border: 1px solid #afb3c2;
      border-radius: 4px;
    }

    .collapsable {
      .card-body {
        padding: 0;
        background: rgba(4, 14, 40, 0.0411932);
      }
    }

    .card-header {
      display: flex;
      align-items: center;

      .card-header-title {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $standard-black;
      }
    }

    .fa-angle-down:before {
      color: transparent;
      appearance: none;
      display: block;
      width: 24px;
      height: 18px;
      background-image: url("../../assets/img/drop-down-arrow.png");
      background-repeat: no-repeat;
    }

    .fa-angle-up:before {
      color: transparent;
      display: block;
      width: 24px;
      height: 15px;
      background-image: url("../../assets/img/drop-up-arrow.png");
      background-repeat: no-repeat;
    }
  }

  .neo-card {
    &.card {
      &.scheme-card {
        &.mb-4 {
          &.collapsed {
            border: none;
          }

          .card-header:not(.collapsed) {
            margin-top: 0px;
            height: 48px;
            border: 1px solid #afb3c2;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .align-link-end {
    display: none;
  }

  .card-query {
    width: 99%;
    background: $white;
    margin-left: 5px;
    padding-left: 27px;

    @media (max-width: 1500px) {
      width: 99%;
      margin-left: 0px;
    }

    @media (max-width: 1000px) {
      width: 98%;
      margin-left: 5px;
    }

    @media (max-width: 500px) {
      width: 98%;
    }

    &.card {
      height: auto;
      border-radius: 5px;
      margin-bottom: 5%;
      border: none;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    }
  }

  .country-dropdown {
    margin-bottom: 1%;
    width: 17%;
  }

  .form-label {
    color: $standard-black;
  }

  .divider1 {
    width: 100%;
    height: 0px;
    border: 1px solid $greyLine;
  }

  .align-buttons-summary {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column-reverse;
    align-items: center;
  }

  .file-drop-area {
    background: #deecff;
    border-radius: 4px;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    margin: 1%;

    &.neo-file-drop {
      border: 2px dashed $azureBlue;
      margin-right: 12px;
      margin-left: 12px;
    }
  }

  .file-upload-label {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $azureBlue;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  /* Overriding React Dropdown Style Classes */

  .react-select-bs__indicators {
    display: none;

    &.css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
  }

  .css-4ljt47-MenuList {
    color: black;
  }

  .react-select-bs__menu-list {
    color: black;
  }

  .css-g1d714-ValueContainer {
    background: url(../../assets/img/arrow-down-solid.png) no-repeat right;
    height: 46px;
    color: black;
  }

  .react-select-bs__option {
    background: none;

    &.react-select-bs__option--is-focused {
      background: none;
    }

    &.react-select-bs__option--is-selected {
      background: rgba(4, 14, 40, 0.0411932);
      color: black;
    }
  }

  .react-select-bs {
    .css-yk16xz-control {
      font-family: "NotoSans-Regular";
      box-sizing: border-box;
      background: $shadowGrey;
      border: 1px solid #afb3c2;
      border-radius: 4px;
    }
  }

  @media (min-width: 768px) and (max-width: 1194px) {
    .scheme-card {
      &.card {
        width: max-content;
      }

      &.collapsed.card {
        width: max-content;
      }
    }

    .custom-control-inline {
      width: 44%;
    }

    .country-dropdown {
      &.col-md-2 {
        width: 21%;
      }
    }

    .align-link-end {
      display: none;
    }

    .align-buttons {
      .btn-cancel {
        width: 35%;
      }

      .btn-continue {
        width: 35%;
      }
    }
  }

  @media (max-width: 768px) {
    .align-buttons-desktop {
      &.row {
        display: none;
      }
    }

    .align-buttons-mobile {
      .divider1 {
        width: 100%;
        border-bottom: 0.5px solid $greyLine;
        display: inline-block;
      }

      &.row {
        display: flex;
      }
    }

    .scheme-card {
      &.card {
        width: 100%;
        --bs-card-cap-padding-x: 0rem;
      }

      &.collapsed.card {
        width: 100%;
        height: 40px;
      }

      .card-header {
        display: flex;
        align-items: center;
        padding-left: 12px;
        padding-right: 11px;

        .card-header-title {
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          color: $standard-black;
          white-space: nowrap;
        }
      }
    }

    .neo-card.card .card-header {
      height: 46px;
      width: 100%;
    }

    .neo-card.card .card-header:not(.collapsed) {
      height: 46px;
    }

    .align-communication-method {
      padding-top: 30px;
    }

    .dropdown-box {
      .dropdown-content {
        font-size: 12px;
      }
    }

    .custom-control-inline {
      width: 50%;
    }

    .tel-control-queries {
      .form-control {
        width: 100%;
        height: 48px;
      }
    }

    .css-g1d714-ValueContainer {
      height: 48px;
    }

    .phone-control {
      height: 40px;
    }

    .email-control {
      height: 40px;
    }

    .browse-button {
      &.btn {
        text-transform: capitalize;
      }
    }

    .country-dropdown {
      &.col-2 {
        width: 24%;

        @media (max-width: 400px) {
          width: 25%;
          margin: none;
        }
      }
    }

    .file-upload-label {
      display: none;
    }

    .col-12 {
      &.padding-fix-queries {
        padding-top: 10px;
      }
    }

    .align-link {
      display: none;
    }



    .align-link-end {
      display: flex;
      justify-content: center;
      padding-left: 0px;
    }
  }
}

/*SchemeInformationHeader*/

.background-card {
  box-shadow: none;

  &.scheme-information-header {
    min-height: 120px;
    margin: 40px 0px 10px 5px;
    padding: 5px 15px 0.1px 25px;

    @media (max-width: 1500px) {
      width: 99%;
    }

    @media (max-width: 1200px) {
      width: 100%;
      padding-left: 1.2%;
      margin: 0px 0px 10px 0px;
    }

    @media (max-width: 768px) {
      width: 98%;
      margin: 20px 0px 5px 5px;
      padding-left: 5px;
    }

    @media (max-width: 400px) {
      margin: 20px 0px 5px 5px;
      padding-left: 4px;
    }

    .scheme-information-header-dropdown {
      height: 45px;
      background: $shadowGrey;
      margin-top: 10px;

      .react-select-bs__indicators {
        display: none;
      }

      .react-select-bs__value-container {
        background: url(../../assets/img/drop-down-arrow.png) no-repeat right;
        background-position-x: 97%;
        background-position-y: 50%;
        height: 45px;
      }

      .react-select-bs__single-value {
        width: 85%;
      }

      .react-select-bs__menu-list {
        color: $standard-black;
        padding-bottom: 0px;
        padding-top: 0px;
      }

      .react-select-row.selected {
        background: rgba(4, 14, 40, 0.0411932);
        color: $standard-black;
      }

      .react-select-row.focused {
        background: rgba(4, 14, 40, 0.0411932);
        color: $standard-black;
      }

      .react-select-bs__control--menu-is-open {
        .react-select-bs__value-container {
          background: url("../../assets/img/drop-up-arrow.png") no-repeat right;
          background-position-x: 97%;
          background-position-y: 50%;
        }
      }
    }
  }
}

.col-md-4 {
  &.padding-top-scheme-header {
    margin-top: 5px;
    padding: 5px 0px 0px 5px;
    width: 36.33%;

    @media (max-width: 1200px) {
      padding: 5px 0px 0px 15px;
    }
  }
}

.col-12 {
  &.padding-top-scheme-header {
    @media (max-width: 768px) {
      margin-top: 5px;
      padding: 5px 5px 5px 22px;
      width: 100%;
    }
  }
}

.drop-down-label {
  color: $standard-black;
}

.scheme-card-header {
  font-family: "NotoSans-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: $standard-black;
}

.scheme-card-header-for-staff {
  font-family: "NotoSans-Medium";
  font-weight: 500;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.2px;
  color: $standard-black;
  padding: 8px 17px;

  .scheme-card-header-bold-for-staff {
    font-family: "NotoSans-Bold";
    font-weight: 800;
  }
}

.background-card-member-details-staff {
  height: 102px;
  background: $white;
  box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
  border-radius: 5px;
  padding: 15px;
  margin-top: 10px;

  .background-card-member-details-header-staff {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #424757;
  }

  .background-card-memver-details-name-staff {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: $standard-black;
  }
}

.col-md-8 {
  &.padding-top-scheme-header {
    margin-top: 5px;
    width: 62.5%;
  }
}

.col-6 {
  &.padding-top-scheme-header {
    margin-top: 8px;
    padding: 5px 30px;
  }
}

.dropdown-label {
  margin-top: 2px;
  color: $standard-black;
}

.modal {
  width: 500px;
}

.modal-text {
  margin-right: 5px;
  margin-left: 5px;
}

.scheme-header-card {
  &.card {
    padding-top: 0px;
    width: max-content;
    color: black;
    box-shadow: none;
    --bs-card-border-width: 0;
    width: max-content;
    font-family: "NotoSans-Regular";
    box-sizing: border-box;
    background: $shadowGrey;
    margin-bottom: 1.5rem;

    @media (max-width: 1200px) {
      width: max-content;
    }

    @media (max-width: 768px) {
      width: 100%;
      --bs-card-cap-padding-x: 0rem;
      margin-bottom: 0.5rem;
    }
  }

  &.collapsed.card {
    width: max-content;
    font-family: "NotoSans-Regular";
    box-sizing: border-box;
    background: $shadowGrey;
    --bs-card-border-width: 0;
    box-shadow: none;
    border: 1px solid #afb3c2;
    border-radius: 4px;

    @media (max-width: 1200px) {
      width: max-content;
    }

    @media (max-width: 768px) {
      width: 99%;
      height: 40px;
    }
  }

  .collapsable {
    .card-body {
      padding: 0;
      background: rgba(4, 14, 40, 0.0411932);
    }
  }

  .card-header {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      padding-left: 12px;
      padding-right: 11px;
    }

    .card-header-title {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $standard-black;

      @media (max-width: 768px) {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        color: $standard-black;
        white-space: nowrap;
      }
    }
  }

  .fa-angle-down:before {
    color: transparent;
    appearance: none;
    display: block;
    width: 24px;
    height: 18px;
    background-image: url("../../assets/img/drop-down-arrow.png");
    background-repeat: no-repeat;
  }

  .fa-angle-up:before {
    color: transparent;
    display: block;
    width: 24px;
    height: 15px;
    background-image: url("../../assets/img/drop-up-arrow.png");
    background-repeat: no-repeat;
  }
}

.neo-card {
  &.card {
    &.scheme-header-card {
      &.collapsed {
        border: none;
      }

      .card-header:not(.collapsed) {
        margin-top: 0px;
        height: 48px;
        border: 1px solid #afb3c2;
        border-radius: 4px;
        background-color: $white;
      }
    }
  }
}

.dropdown-box {
  font-family: "NotoSans-Regular";
  box-sizing: border-box;
  background: $shadowGrey;
  border-radius: 4px;

  .dropdown-content {
    margin: 5px;
    min-width: 150px;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
    border-radius: 4px;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    font-size: 16px;
    background: border-box;
    background-color: $white;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

//////////////////////////////////////////////////// Compliment Section /////////////////////////////////////////////////////////////////////////////
.compliments-css {
  .background-card {
    padding-bottom: 20px;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .row {
    &.pad-lr {
      .summary-container {
        &.border-pix {
          border: 1px solid $greyLine;
          border-radius: 5px;
        }
      }
    }
  }

  .border {
    border-radius: 5px;
  }

  .pad-header {
    padding-left: 50px;
  }

  .pad-lr {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pad-rl {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
  }

  .pad-left {
    padding-left: 15px;
  }

  .pad {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .heading-smr {
    color: black;
    text-align: center;
    justify-content: center;
  }

  .background-card {
    &.scheme-information-header {
      min-height: 0px;
      margin: 2% 0px 1% 5px;
      padding: 10px 15px 0.1px 1.5%;

      @media (max-width: 1500px) {
        width: 99%;
        margin-bottom: 1.5%;
      }

      @media (max-width: 1000px) {
        width: 98%;
      }

      @media (max-width: 500px) {
        margin: 5% 0px 3% 5px;
        padding-left: 5%;
      }

      @media (max-width: 400px) {
        margin: 6% 0px 3% 5px;
        padding-left: 4%;
      }
    }
  }

  .background-card-review {
    box-shadow: none;

    &.scheme-information-header {
      min-height: 0px;
      margin: 2% 0px 1% 5px;
      padding: 10px 15px 0.1px 1.5%;

      @media (max-width: 1500px) {
        width: 99%;
        margin-bottom: 1.5%;
      }

      @media (max-width: 1000px) {
        width: 98%;
      }

      @media (max-width: 500px) {
        margin: 5% 0px 3% 5px;
        padding-left: 5%;
      }

      @media (max-width: 400px) {
        margin: 6% 0px 3% 5px;
        padding-left: 4%;
      }
    }
  }

  .show-on-web-screen {
    display: contents;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .center-link {
    text-align: center;
  }

  .show-on-phone-screen {
    display: none;

    @media (max-width: 768px) {
      display: contents;

      .neo-card {
        &.card {
          .card-body {
            padding-left: 12px;
            padding-right: 12px;
          }
        }
      }
    }
  }

  .show-on-phone-screen-summary {
    display: none;

    @media (max-width: 768px) {
      display: contents;
    }
  }

  .card-summary-uploaded-document {
    font-family: "NotoSans-SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $azureBlue;
    padding-left: 18px;

    @media (max-width: 768px) {
      padding-left: 0px;
      margin: 10px 0px 0px 0px;
    }
  }

  .card-text-phone-screen-document {
    font-family: "NotoSans-SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
    color: $azureBlue;
  }

  .card-text-phone-screen {
    width: 28%;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #424757;
    padding-top: 10px;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 0px;
    }

    &.share {
      padding-top: 20px;
    }
  }

  .col-5 {
    &.card-text-phone-screen {
      width: 28%;
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $standard-black;
      padding-top: 10px;

      @media (max-width: 400px) {
        width: 37%;
      }

      &.share {
        padding-top: 20px;
      }
    }
  }

  .col-7 {
    &.padding-phone {
      padding-top: 6px;

      &.table-card-name {
        font-family: "NotoSans-SemiBold";
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        color: $standard-black;

        .beneficiary-number {
          font-family: "NotoSans-regular";
          font-weight: 400;
          text-decoration: underline;
          font-size: 12px;
        }
      }

      &.share {
        padding: 16px 0 8px 10px;
      }
    }
  }

  .approved-benefit-card-items {
    height: 74px;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    border: none;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: $standard-black;
    margin-top: 15px;
    padding-top: 5px;

    @media (max-width: 768px) {
      height: 200px;
      font-size: 12px;
      line-height: 20px;
      color: $standard-black;

      &.funeral-benefit-height {
        height: 162px;
        padding-top: 0px;
      }
    }
  }

  .tabs-dropdown-products {
    display: none;

    @media (max-width: 500px) {
      display: contents;
      font-family: "NotoSans-Medium";
      font-weight: 500;
      font-size: 12px;
      line-height: 19px;
      color: $black;
    }
  }

  .dropdown-header-products {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $black;
    margin: 4% 0px 0px 1px;
  }

  .tabs-dropdown-products {
    .react-select-bs__indicators {
      &.css-1hb7zxy-IndicatorsContainer {
        display: none;
      }
    }

    .css-4ljt47-MenuList {
      color: black;
    }

    .css-g1d714-ValueContainer {
      background: url("../../../App/assets/img/drop-down-arrow.png") no-repeat right;
      margin-right: 10px;
      height: 39px;
    }

    .react-select-bs__value-container--has-value {
      background: url("../../../App/assets/img/drop-down-arrow.png") no-repeat right;
      margin-right: 10px;
      height: 39px;
    }

    .react-select-bs__option {
      background: none;

      &.react-select-bs__option--is-selected {
        background-color: rgba(4, 14, 40, 0.0411932);
        color: black;
      }
    }

    .react-select-bs {
      .css-yk16xz-control {
        font-family: "NotoSans-Regular";
        box-sizing: border-box;
        background: $shadowGrey;
        border: 1px solid #afb3c2;
        border-radius: 4px;
      }
    }

    .react-select-bs__option:not(:checked) {
      background: none;
    }
  }

  .divider1 {
    width: 100%;
    border-bottom: 0.5px solid $greyLine;
    display: inline-block;
  }

  .row {
    &.table-heading-badb {
      margin-left: 3px;
    }
  }

  .card-heading-text {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    color: #424757;
  }

  .card-subheading-text {
    font-family: "NotoSans-Bold";
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    color: #424757;
  }

  .card-summary-text {
    font-family: "NotoSans-Bold";
    font-weight: 400;
    padding-left: 15px;
    font-size: 14px;
    line-height: 35px;
    color: #424757;
  }

  .card-summary-text-phone {
    font-family: "NotoSans-SemiBold";
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    color: #424757;
  }

  .card-tab-text-products {
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: $azureBlue;

    @media (max-width: 450px) {
      display: none;
    }
  }

  .top-pad {
    padding-top: 30px;
  }

  .heading-cu {
    width: auto;
    height: 35px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    padding-top: 8px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;

    @media (max-width: 400px) {
      margin-top: 5px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .heading-cu-phone {
    width: auto;
    height: 35px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: $standard-black;

    &.page-label-heading {
      padding-top: 8px;
      line-height: 35px;
    }

    &.query-note {
      padding-top: 12px;
      line-height: 35px;
    }

    &.document-label-heading {
      padding-top: 12px;
      line-height: 35px;
    }

    @media (max-width: 400px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .heading-co {
    width: auto;
    height: 35px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    padding-top: 8px;
    padding-left: 20px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;

    @media (max-width: 400px) {
      margin-top: 5px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .heading-cu-summary {
    height: 35px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 30px;
    padding-top: 8px;
    display: inline;
    text-align: center;
    line-height: 34px;
    letter-spacing: 0.2px;
    color: $standard-black;

    @media (max-width: 400px) {
      font-size: 16px;
      justify-content: center;
      line-height: 10px;
      display: flex;
      text-align: center;
    }
  }

  .img-container {
    height: 120px;

    @media (max-width: 400px) {
      height: 120px;
    }
  }

  .img-top-div {
    height: 290px;
  }

  .line-break {
    display: none;

    @media (max-width: 768px) {
      display: contents;
    }
  }

  .blue-block {
    border: 1px dashed $azureBlue;
    height: 74px;
    width: 100%;
    background: #deecff;
    border-radius: 4px;
    text-align: center;
    margin: 20px 0 20px 0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 25px;

    @media (max-width: 768px) {
      height: 131px;
      text-align: center;
    }

    .blue-block-text {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #0033aa;
      margin-right: 7px;
    }

    .or-text {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      line-height: 19px;
      font-size: 14px;
      color: $standard-black;
      text-decoration-line: none;
      margin: 0 18px 0 14px;

      @media (max-width: 768px) {
        line-height: 30px;
      }
    }

    .add-text {
      font-family: "NotoSans-Medium";
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 1.23px;
      color: $azureBlue;
      text-decoration-line: none;
    }
  }

  .blue-block-text {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #0033aa;
    margin-right: 7px;
  }

  .center {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    justify-content: center;
  }

  .summary-img {
    margin: auto;
    display: block;
    width: 180px;
    height: 80px;

    @media (max-width: 750px) {
      margin-bottom: 40px;
      font-size: 13px;
      line-height: 18px;
      width: 240px;
      height: 120px;
    }
  }

  .phone-heading {
    color: #424757;
    margin-top: 15px;
  }

  .phone-value {
    font-family: "NotoSans-SemiBold";
    font-size: 14px;
    line-height: 24px;
    color: #424757;
  }

  .sub-heading-cu {
    width: auto;
    height: 20px;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    padding-top: 10px;
    line-height: 20px;
    color: $standard-black;
    margin-bottom: 20px;

    @media (max-width: 750px) {
      margin-bottom: 40px;
      font-size: 13px;
      line-height: 18px;
    }

    @media (max-width: 400px) {
      margin-bottom: 40px;
      font-size: 13px;
    }
  }

  .nav {
    &.nav-pills {
      .nav-item:first-child {
        .nav-link {
          border-radius: 4px 0px 0px 4px;
        }
      }

      .nav-item:last-child {
        .nav-link {
          border-radius: 0px 4px 4px 0px;
        }
      }

      .nav-link {
        border: 2px solid $azureBlue;
        border-radius: 0px 0px 0px 0px;
        width: 203px;
        height: 48px;
        padding-top: 12px;

        @media (max-width: 900px) {
          width: 170px;
        }

        @media (max-width: 660px) {
          width: 100%;
        }

        @media (max-width: 500px) {
          display: none;
        }

        &.my-contribution {
          border-radius: 0px 4px 4px 0px;
        }

        &.active {
          background: $azureBlue;

          .card-tab-text-products {
            color: $white;
            width: 204px;
            padding-right: 22%;

            @media (max-width: 1150px) {
              width: 100%;
              padding-right: 0%;
            }
          }
        }
      }
    }
  }
}

.btn-width {
  @media (max-width: 430px) {
    width: 106%;
  }
}

.padding-around-background {
  padding-left: 10px;

  @media (max-width: 1500px) {
    padding-right: 9px;
  }

  @media (max-width: 1200px) {
    padding-left: 0px;
    padding-right: 6px;
  }

  @media (max-width: 768px) {
    padding: 10px 0px 0px 0px;
  }
}