@import "../../App/Styles/Variables.scss";

.legacy-member-dashboard-container {
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 4px;

  @media (min-width: 768px) {
    margin: 24px 0px 0px 0px;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  .hide-retirement {
    display: none;
  }

  .col-md-5 {
    &.fix-col-padding {
      padding-left: 0px;
    }
  }

  .align-arrow {
    margin-top: 4px;
    margin-bottom: 3px;
    cursor: pointer;
  }

  .goal-card {
    .projections-container {
      height: max-content;
      transition: height 2s;

      @media (max-width: 1200px) {
        height: max-content;
      }
    }

    &.collapsed {
      .projections-container {
        height: 0px;
        overflow: hidden;
      }
    }

    @media (max-width: 768px) {
      height: 100%;
    }

    .card-body {
      padding: 10px 12px 1px 16px;

      .fix-padding-col {
        padding-right: 0px;
      }

      .goal-card-title {
        min-height: auto;
        text-decoration: none;
        margin-bottom: 5px;
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.171429px;
        color: $standard-black;
        padding-left: 0px;
        cursor: pointer;

        @media (max-width: 768px) {
          font-size: 20px;
          line-height: 27px;
        }

        @media (min-width: 768px) and (max-width: 1200px) {
          font-size: 18px;
        }

        .align-arrow {
          float: right;
        }
      }

      .card-text-fund-legacy {
        font-family: "NotoSans-SemiBold";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $standard-black;
        cursor: pointer;
      }

      .goal-card-text {
        font-size: 14px;
        font-family: "NotoSans-Regular";
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: $standard-black;
        padding-left: 1px;
        margin-bottom: 0.5rem;
        cursor: pointer;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
        flex-wrap: nowrap;
      }

      .net-replacement-ratio-card {
        background: #2a333d;
        box-shadow: none;
        width: 100%;
        border-radius: 5px;
        height: 90%;

        @media (min-width: 768px) and (max-width: 1200px) {
          height: 157px;
        }

        .net-card-header {
          display: flex;
          justify-content: space-between;
          align-items: baseline;

          i {
            padding-right: 10px;

            @media (min-width: 768px) and (max-width: 1200px) {
              margin-bottom: 3px;
            }
          }
        }

        @media (max-width: 768px) {
          width: 98%;
          height: 92%;
        }

        .card-body {
          color: $white;
          padding: 0px;
        }

        h5 {
          font-size: 14px;
          font-family: "NotoSans-Medium";
          letter-spacing: 0.133333px;
          font-weight: 500;
          padding-top: 11px;
          padding-left: 16px;
          cursor: pointer;

          @media (min-width: 768px) and (max-width: 1200px) {
            margin-bottom: 0px;
          }
        }

        .speedometer-goal-card-dashboard {
          display: flex;
          justify-content: center;
          padding-top: 8px;

          @media (min-width: 768px) and (max-width: 1200px) {
            padding-top: 0px;
          }

          .speedometer {
            .label {
              .segment-value {
                font-family: "NotoSans-SemiBold";
                line-height: 41px;
                letter-spacing: 0.171429px;
                color: $standard-black;
              }
            }
          }
        }
      }

      .monthly-income-card {
        background: var(--linear, linear-gradient(214deg, #f46363 0%, #ff3636 100%));
        border-radius: 5px;
        width: 100%;
        height: 90%;
        border: none;

        @media (min-width: 768px) and (max-width: 1200px) {
          height: 157px;
        }

        &.yellow {
          background: linear-gradient(212.61deg, #ffab00 -4.48%, #fe8655 83.79%);
        }

        &.green {
          background: linear-gradient(217.39deg, #84c83a 7.59%, #10a565 97.75%);
        }

        .net-card-header {
          display: flex;
          justify-content: space-between;
          align-items: baseline;

          i {
            padding-right: 10px;
            padding-bottom: 0px;

            @media (min-width: 768px) and (max-width: 1200px) {
              padding-right: 10px;
            }
          }
        }

        @media (max-width: 768px) {
          width: 98%;
          margin-top: 0%;
          height: 155px;
        }

        .card-body {
          color: $white;
          padding: 0px;
          cursor: pointer;
        }

        h5 {
          font-size: 14px;
          font-family: "NotoSans-Medium";
          letter-spacing: 0.133333px;
          font-weight: 500;
          padding-top: 11px;
          padding-left: 16px;
        }

        .monthly-income-card-content {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          padding-top: 8%;

          p {
            font-size: 18px;
            font-family: "NotoSans-Regular";
            font-style: normal;
            font-weight: 400;
          }

          .monthly-income-card-divider {
            width: 55%;
            height: 1px;
            background: $white;
            border-radius: 0.8px;
          }
        }
      }
    }

    .card-footer {
      &.display-goal-footer-legacy {
        background: none;
        text-align: center;
        border: none;
        margin-top: 0%;
        height: 53px;
        display: flex;
        justify-content: center;
        padding-top: 6px;
        margin-top: 1px;

        @media (max-width: 420px) {
          margin-top: 0%;
        }

        .show-more-btn {
          height: 40px;
          background: $white;
          border: 2px solid $azureBlue;
          border-radius: 4px;
          color: $azureBlue;
          font-family: "NotoSans-Medium";
          width: 190px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "NotoSans-Medium";
          font-style: normal;
          font-size: 15px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 1.23px;
          color: $azureBlue;

          @media (min-width: 768px) and (max-width: 1200px) {
            width: 159px;
            height: 38px;
          }

          @media (max-width: 768px) {
            width: 159px;
            height: 38px;
          }

          @media (max-width: 360px) {
            height: 33px;
          }
        }
      }
    }
  }

  .get-in-touch-card-legacy {
    &.card {
      margin-left: 0%;
      width: auto;
      padding: 0px;
      background-color: $white;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
      border-radius: 5px;
      border: none;
      height: 201px;

      .card-body {
        padding: 10px 18px 16px 16px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .get-in-touch-card-title {
      min-height: auto;
      text-decoration: none;
      margin-bottom: 15px;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.171429px;
      color: $standard-black;
      padding-left: 0px;

      @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 18px;
      }
    }

    .align-arrow {
      float: right;
    }

    .align-inner-cards-legacy {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      display: flex;
      overflow: hidden;

      &.card-body {
        padding-bottom: 0%;
      }

      .inner-cards-legacy {
        display: flex;
        width: 24%;
        background: #0232a3;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;

        &.card {
          height: 132px;
          border-radius: 5px;
          border: none;
          color: white;

          @media (min-width: 768px) and (max-width: 1200px) {
            height: 118px;
          }
        }

        .align-inner-card-content-legacy {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          h5 {
            font-size: 16px;
            font-style: normal;
            font-family: "NotoSans-Regular";
            font-weight: 400;
            text-align: center;
            width: min-content;

            @media (min-width: 768px) and (max-width: 1200px) {
              font-size: 14px;
              width: max-content;
            }
          }

          i {
            height: 50px;
            width: 50px;
          }

          .align-queries-legacy {
            width: 45px;
            height: 45px;

            @media (min-width: 768px) and (max-width: 1200px) {
              height: 40px;
              width: 40px;
              margin-left: 7px;
              margin-top: 7px;
            }
          }

          .align-contact-us-text {
            width: fit-content;
          }

          .align-vas-legacy {
            width: 45px;
            height: 45px;
            margin-top: 21px;

            @media (max-width: 768px) {
              margin-top: 13px;
            }

            @media (min-width: 768px) and (max-width: 1200px) {
              margin-top: 26px;
              margin-left: 1px;
            }
          }

          .align-vas-text-legacy {
            width: 45px;
            height: 45px;
            margin-top: 21px;
            width: 100px;

            @media (max-width: 768px) {
              margin-top: 24px;
            }
          }

          .align-queries-compliments-legacy {
            width: 45px;
            height: 45px;
            margin-top: 21px;

            @media (min-width: 768px) and (max-width: 1200px) {
              height: 35px;
              width: 35px;
              margin-left: 7px;
            }

            @media (max-width: 768px) {
              margin-top: 13px;
            }
          }

          .align-queries-compliments-text-legacy {
            margin-top: 22px;

            @media (min-width: 768px) and (max-width: 1200px) {
              width: auto;
              margin-top: 15px;
            }

            @media (max-width: 768px) {
              margin-top: 24px;
            }
          }
        }
      }
    }
  }

  .my-profile-card {
    margin-top: 0rem;
    margin-left: 0%;
    color: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    width: 99%;
    height: 205px;
    cursor: pointer;

    @media (min-width: 768px) and (max-width: 1200px) {
      height: 200px;
      min-height: 0%;
    }

    @media (max-width: 768px) {
      margin-left: 12px;
      width: 95%;
    }

    h5 {
      color: $darkishBlue;
      min-height: auto;
      text-decoration: none;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.171429px;
      padding-left: 16px;

      @media (max-width: 768px) {
        font-size: 20px;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 18px;
      }
    }

    p {
      color: $darkishBlue;
      font-family: "NotoSans-Regular";
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      line-height: 20px;
      padding-left: 18px;
    }

    i {
      float: right;
    }

    &.card {
      border: none;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    }

    .align-progress {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: stretch;
      justify-content: space-evenly;
      align-items: center;
    }

    .align-complete {
      width: 20%;
      white-space: nowrap;
      margin-left: 0%;
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: 0.133333px;
      color: $darkishBlue;

      @media (max-width: 768px) {
        font-size: 14px;
        margin-left: 3%;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 14px;
      }
    }

    .align-span {
      display: flex;
      padding-top: 10px;
      padding-bottom: 3%;
      width: 92%;

      @media (max-width: 768px) {
        width: 95%;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        padding: 10px 0px 12px 20px;
      }

      &.card-footer {
        border-top: none;
      }
    }

    .box {
      flex: 1 1;
      display: flex;
      justify-content: flex-end;
      color: $darkishBlue;
    }

    .progress-footer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 8% 0 auto 0;

      &.card-footer {
        border-top: none;
        padding: 40px 3px 0px 0px;
        background-color: $white;

        @media (min-width: 3500px) {
          padding: 3.5% 2% 0% 0%;
        }

        @media (max-width: 768px) {
          padding: 20px 4px 0px 2px;
        }

        @media (min-width: 768px) and (max-width: 1200px) {
          padding: 35px 3px 0px 3px;
        }
      }
    }
  }

  .beneficiaries-card {
    height: 205px;
    width: 99%;
    color: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    border: none;

    @media (min-width: 768px) and (max-width: 1200px) {
      height: 200px;
      min-height: 0%;
    }

    @media (max-width: 768px) {
      width: 95%;
      margin-left: 12px;
    }

    .card-title {
      color: $darkishBlue;
      min-height: auto;
      text-decoration: none;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.171429px;
      padding-left: 0px;
      cursor: pointer;

      &-no-pointer {
        cursor: default;
      }

      @media (max-width: 768px) {
        font-size: 20px;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 18px;
      }
    }

    .align-arrow {
      float: right;
      cursor: pointer;

      &-no-pointer {
        cursor: default;
      }
    }

    .card-body-text {
      font-family: "NotoSans-SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: $darkishBlue;
      opacity: 0.6;
      cursor: pointer;

      &-no-pointer {
        cursor: default;
      }
    }
  }

  .dashboard-right-content {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "H"
      "E"
      "F";
    height: 100%;

    .my-savings-content-container {
      .my-savings-content {
        .my-retirement-card-legacy {
          background: $white;
          overflow: hidden;
          margin-bottom: 1%;
          z-index: 2;
          width: 99%;
          margin-left: 0px;
          background: $white;
          padding-left: 0%;
          background-color: $white;
          box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
          border-radius: 5px;
          border: none;
          height: 85%;

          @media (max-width: 768px) {
            z-index: 1;
            width: 95%;
            margin-left: 12px;
            padding-right: 0px;
          }

          &.collapsed {
            height: 258px;

            @media (max-width: 768px) {
              z-index: 1;
            }

            @media (max-width: 370px) {
              height: 290px;
            }

            @media (min-width: 768px) and (max-width: 1200px) {
              height: 277px;
            }

            .projections-container {
              height: 0px;
              overflow: hidden;
              width: auto;
              margin-top: 0%;

              @media (max-width: 768px) {
                margin-top: 0%;
              }
            }
          }

          .savings-card-title {
            min-height: auto;
            text-decoration: none;
            margin-bottom: 5px;
            font-family: "NotoSans-Medium";
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.171429px;
            color: $standard-black;
            padding-left: 0px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 768px) {
              font-size: 20px;
              line-height: 27px;
            }

            @media (min-width: 768px) and (max-width: 1200px) {
              font-size: 18px;
            }
          }

          .savings-sub-card {
            margin-top: 13px;
            margin-left: 2px;
            border: none;
            cursor: pointer;

            @media (min-width: 768px) and (max-width: 1200px) {
              margin-top: 5px;
            }

            &.card {
              .card-header {
                background: $white;
                padding-right: 0px;
                padding-left: 0px;

                .card-header-title {
                  color: $standard-black;
                  font-family: "NotoSans-SemiBold";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  width: 100%;

                  .align-savings-header-text {
                    width: 60%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    @media (min-width: 768px) and (max-width: 1200px) {
                      width: 100%;
                    }

                    @media (max-width: 768px) {
                      width: 100%;
                    }
                  }
                }
              }

              .card-body {
                padding: 5px 15px 5px 13px;
                background-color: $lightBlue;
                border-radius: 4px;
                height: 74px;

                @media (min-width: 768px) and (max-width: 1200px) {
                  height: 94px;
                }

                @media (max-width: 360px) {
                  height: 98px;
                }

                .value-text {
                  width: 100%;
                  display: flex;

                  .value-title {
                    width: 50%;
                    color: $white;
                    font-family: "NotoSans-Regular";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    letter-spacing: 0.133px;

                    @media (min-width: 768px) and (max-width: 1200px) {
                      width: 100%;
                    }
                  }

                  .amount-text {
                    width: 50%;
                    color: $white;
                    text-align: right;
                    font-family: "NotoSans-Regular";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 32px;
                    letter-spacing: 0.133px;

                    @media (min-width: 768px) and (max-width: 1200px) {
                      width: 100%;
                    }
                  }
                }

                .accessibility-text {
                  width: 100%;
                  display: flex;

                  .accessibility-title {
                    width: 50%;
                    color: $white;
                    font-family: "NotoSans-Regular";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    letter-spacing: 0.133px;

                    @media (min-width: 768px) and (max-width: 1200px) {
                      width: 100%;
                    }
                  }

                  .accessibility-value {
                    width: 50%;
                    color: $white;
                    text-align: right;
                    font-family: "NotoSans-Regular";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 32px;
                    letter-spacing: 0.133px;
                  }
                }
              }
            }
          }

          .savings-link {
            color: #006eff;
            text-align: center;
            font-family: "NotoSans-Regular";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.133px;
            text-decoration-line: underline;
            margin-top: 10px;

            @media (min-width: 768px) and (max-width: 1200px) {
              margin-top: 16px;
            }

            .align-savings-link-text {
              margin-bottom: 3px;
              color: #006eff;
              text-align: center;
              font-family: "NotoSans-Regular";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0.133px;
              text-decoration-line: underline;
              cursor: pointer;
            }
          }

          .savings-card-footer-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            height: 53px;

            .show-more-btn {
              display: inline-flex;
              height: 40px;
              padding: 11px 30px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              flex-shrink: 0;
              border-radius: 4px;
              border: 2px solid $azureBlue;
              background: $white;
              font-size: 15px;
              font-weight: 500;
              font-family: "NotoSans-Medium";
              font-style: normal;
              line-height: normal;
              letter-spacing: 1.23px;
              color: $azureBlue;

              @media (min-width: 768px) and (max-width: 1200px) {
                height: 38px;
              }
            }
          }
        }
      }
    }
  }

  .get-in-touch-container-mobile {
    @media (max-width: 768px) {
      margin: 14px 0px 5px 0px;
      width: 100%;
    }

    .get-in-touch-card-legacy-mobile {
      &.card {
        display: none;

        @media (max-width: 768px) {
          display: flex;
          margin-left: 10px;
          background-color: $white;
          box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
          border-radius: 5px;
          padding-left: 0px;
          border: none;
          width: 95.5%;
        }

        @media (min-width: 768px) and (max-width: 1200px) {
          display: none;
        }
      }

      .get-in-touch-card-title-mobile {
        min-height: auto;
        text-decoration: none;
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: $standard-black;
      }

      .align-arrow-mobile {
        float: right;
      }

      .align-inner-cards-legacy-mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        overflow: hidden;

        &.card-body {
          padding: 0.5% 0% 1% 0%;
        }

        .inner-cards-legacy-mobile {
          background: #0232a3;
          width: 48%;
          margin-bottom: 4px;
          cursor: pointer;
          margin-right: 4px;

          &.card {
            height: 136px;
            border-radius: 5px;
            border: none;
          }

          .card-body {
            cursor: pointer;

            &.align-inner-card-content-legacy-mobile {
              display: flex;
              cursor: pointer;
              flex-direction: column;
              align-items: center;
              justify-content: space-evenly;
              overflow: hidden;
              color: $white;

              .align-contact-us {
                margin-top: 5px;
                width: max-content;
              }

              h5 {
                font-size: 14px;
                font-style: normal;
                font-family: "NotoSans-Regular";
                font-weight: 400;
                text-align: center;
              }

              i {
                height: 40px;
                width: 40px;
                margin-bottom: 7px;
              }

              .align-queries-legacy-mobile {
                width: 45px;
                height: 45px;
              }

              .align-vas-legacy-mobile {
                width: 45px;
                height: 45px;
              }

              .align-vas-text-legacy-mobile {
                height: 45px;
                width: 100px;
              }

              .align-queries-compliments-legacy-mobile {
                width: 45px;
                height: 45px;
              }

              .align-queries-compliments-text-legacy-mobile {
                font-size: 14px;
                font-style: normal;
                font-family: "NotoSans-Regular";
                font-weight: 400;
                text-align: center;
                margin-bottom: 0px;
                margin-top: 0px;
                padding-top: 3px;
              }
            }
          }
        }

        .inner-cards-legacy-mobile:hover {
          cursor: pointer;
        }
      }
    }
  }

  .dashboard-content {
    &.hide-retirement-grid {
      grid-template-areas:
        "B"
        "C";

      @media (max-width: 768px) {
        height: 189px;
      }
    }

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "A"
    "B"
    "C";
    height: 100%;

    .my-retirement {
      height: 315px;
      margin: 16px 16px 5px 16px;

      @media (max-width: 1200px) {
        margin: 5px 16px 5px 5px;
      }

      @media (max-width: 768px) {
        height: 494px;
        margin: 10px 0px 0px 9px;
        width: 95%;
      }

      .my-retirement-content-container {
        .my-retirement-content {
          .goal-card {
            padding-right: 14px;
            background: $white;
            overflow: hidden;
            margin-bottom: 1%;
            z-index: 2;
            width: auto;
            margin-left: 0px;
            background: $white;
            padding-left: 0%;
            background-color: $white;
            box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
            border-radius: 5px;
            border: none;
            height: 85%;

            @media (max-width: 768px) {
              height: 100%;
            }

            .projections-container {
              height: max-content;
              transition: height 2s;
              margin-top: 1%;

              @media (max-width: 768px) {
                margin-top: 3%;
              }

              @media (max-width: 1200px) {
                height: max-content;
              }
            }

            &.collapsed {
              height: 312px;

              @media (max-width: 768px) {
                height: 494px;
              }

              .projections-container {
                height: 0px;
                overflow: hidden;
                width: auto;
                margin-top: 0%;

                @media (max-width: 768px) {
                  margin-top: 0%;
                }
              }
            }

            .row {
              &.goal-row {
                height: 160px;

                @media (max-width: 768px) {
                  height: auto;
                }
              }
            }
          }
        }
      }
    }

    .my-products {
      &.my-products-with-investments-only {
        @media (max-width: 768px) {
          height: 158px;
        }
      }

      &.align-products-without-investments {
        margin-top: 20px;

        @media (min-width: 768px) and (max-width: 1200px) {
          margin-top: 6px;
        }
      }

      height: 158px;
      margin: 6px 16px 4px 16px;

      @media (max-width: 1200px) {
        margin-left: 5px;
      }

      @media (max-width: 768px) {
        height: 259px;
        margin: 15px 0px 0px 10px;
        width: 100%;
      }

      .products-card {
        &.card {
          box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
          border-radius: 5px;
          background-color: $white;
          margin-top: 0%;
          margin-left: 0%;
          width: auto;
          padding-left: 0px;
          margin-bottom: 0%;
          border: none;
          height: 158px;
          padding-right: 14px;

          @media (min-width: 768px) and (max-width: 1200px) {
            margin-bottom: 0%;
          }

          @media (max-width: 768px) {
            height: 100%;
            width: 95%;
          }
        }

        .align-product-inner-cards {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          display: flex;
          margin-right: 0%;
          overflow: hidden;
          margin-left: 0%;
          cursor: pointer;

          @media (max-width: 768px) {
            flex-direction: column;
          }

          &.card-body {
            padding: 10px 0px 0px 0px;
          }
        }

        .card-body {
          padding: 10px 2px 16px 16px;
          color: white;

          @media (min-width: 3500px) {
            padding: 0px 2px 16px 16px;
            color: white;
          }

          .product-card-title {
            min-height: auto;
            text-decoration: none;
            margin-bottom: 5px;
            font-family: "NotoSans-Medium";
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.171429px;
            color: $standard-black;
            padding-left: 0px;
            cursor: pointer;

            @media (max-width: 768px) {
              font-size: 20px;
              line-height: 27px;
            }

            @media (min-width: 768px) and (max-width: 1200px) {
              font-size: 18px;
            }

            .align-arrow {
              float: right;
            }
          }
        }

        .product-investment-card-legacy {
          width: 50%;
          background: $azureBlue;
          border: none;
          height: 92px;
          padding-right: 14px;

          @media (max-width: 768px) {
            width: 100%;
          }

          &.card {
            background: $azureBlue;
            border-radius: 4px;
            border: none;
            margin-right: 10px;

            @media (max-width: 768px) {
              margin-right: 0px;
            }
          }

          .investment-content-legacy {
            margin-left: 2%;
            margin-top: 1%;
            display: flex;
            width: max-content;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            @media (min-width: 3500px) {
              margin-top: 2%;
            }

            i {
              height: 69%;
            }

            span {
              cursor: pointer;
            }
          }

          .align-investment-legacy-heading {
            @media (min-width: 768px) and (max-width: 1200px) {
              margin-top: 8px;
              font-size: 14px;
            }

            .investment-heading-legacy {
              margin-bottom: 1%;
              font-family: "NotoSans-Medium";
              font-size: 20px;
              cursor: pointer;

              @media (min-width: 768px) and (max-width: 1200px) {
                font-size: 16px;
              }
            }
          }
        }

        .product-inner-cards-legacy {
          width: 50%;
          padding-right: 5px;
          margin-left: 0px;
          border: none;

          @media (max-width: 768px) {
            width: 100%;
          }

          &.card {
            background: $azureBlue;
            border-radius: 4px;
            border: none;
            height: 92px;

            @media (max-width: 768px) {
              margin-top: 2%;
              margin-left: 2px;
            }
          }

          .align-product-card-content-legacy {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
            display: flex;
            margin-left: 2%;
            margin-top: 1%;

            @media (min-width: 768px) and (max-width: 1200px) {
              margin-top: 4%;
              margin-left: 0%;
            }

            @media (max-width: 768px) {
              margin-left: 3%;
              margin-top: 2%;
            }

            i {
              margin-bottom: 0%;
              margin-left: 10px;
            }

            h5 {
              font-size: 20px;
              font-family: "NotoSans-Regular";
              font-weight: 100;
              height: 60%;
              top: calc(50% - 32px / 2 + 171px);
              font-style: normal;
              font-weight: 500;
              line-height: 32px;
              letter-spacing: 0.133333px;
              margin-left: 14px;
              margin-bottom: 14px;

              @media (max-width: 768px) {
                margin-left: 18px;
              }
            }

            .align-risk-legacy {
              margin-top: 16px;
              font-family: "NotoSans-Medium";
              font-size: 20px;
              cursor: pointer;

              @media (min-width: 768px) and (max-width: 1200px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .get-in-touch {
      height: 201px;
      margin: 8px 16px 4px 16px;

      @media (max-width: 1200px) {
        margin-left: 5px;
      }

      @media (max-width: 768px) {
        display: none;
      }

      &.align-get-in-touch-no-investments {
        height: 238px;

        .get-in-touch-card-legacy {
          &.card {
            height: 250px;

            @media (min-width: 768px) and (max-width: 1200px) {
              height: 237px;
            }
          }

          .inner-cards-legacy {
            &.card {
              height: 173px;

              .card-body {
                padding: 20px 16px 16px 16px;
              }

              @media (min-width: 768px) and (max-width: 1200px) {
                height: 137px;
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        &.align-get-in-touch-mobile-with-only-investments {
          height: 99px;
        }
      }
    }
  }

  .my-profile {
    height: 205px;
    margin: 16px 8px 4px 0px;

    @media (min-width: 768px) and (max-width: 1200px) {
      margin: 5px 8px 0px 0px;
      height: 200px;
    }

    &.my-profile-without-investments {
      margin-top: 20px;

      @media (min-width: 768px) and (max-width: 1200px) {
        margin: 5px 16px 0px 0px;
      }
    }

    @media (max-width: 768px) {
      margin: 15px 0px 0px 10px;

      &.my-profile-without-investments {
        margin: 15px 0px 0px 10px;
      }
    }
  }

  .my-beneficiaries {
    height: 205px;
    margin: 11px 8px 4px 0px;

    @media (max-width: 768px) {
      margin: 15px 0px 0px 10px;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      margin: 5px 8px 0px 0px;
      height: 200px;
    }
  }

  .savings-card {
    margin: 16px 8px 2px 0px;
    height: 255px;

    @media (max-width: 768px) {
      margin: 15px 0px 0px 10px;
    }

    @media (max-width: 370px) {
      height: 287px;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      margin: 5px 16px 0px 0px;
      height: 277px;
    }
  }

  .my-placeholder {
    margin: 11px 8px 2px 0px;
    height: 255px;

    @media (max-width: 500px) {
      display: none;
    }

    @media (max-width: 768px) {
      margin: 15px 0px 0px 10px;
    }

    @media (max-width: 370px) {
      height: 287px;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      margin: 5px 8px 0px 0px;
      height: 277px;
    }

    &.hide-placeholder {
      display: none;
    }
  }

  .placeholder-card {
    height: 99%;
    width: 99%;
    color: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    border: none;

    @media (min-width: 768px) and (max-width: 1200px) {
      min-height: 0%;
    }

    @media (max-width: 768px) {
      width: 95%;
      margin-left: 12px;
    }

    .card-body {
      height: 99%;
      padding: 0px;

      .placeholder-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
        border-radius: 5px;
      }
    }

  }

  .my-retirement-card-legacy {
    .card-body {
      padding: 10px 16px 4px 16px;
    }

    .savings-projections-container {
      height: max-content;
      transition: height 2s;

      @media (max-width: 1200px) {
        height: max-content;
      }
    }

    &.collapsed {
      @media (min-width: 768px) and (max-width: 1200px) {
        .card-body {
          padding: 10px 12px 1px 16px;
        }
      }

      .savings-projections-container {
        height: 0px;
        overflow: hidden;
      }
    }

    @media (max-width: 768px) {
      height: 100%;
    }
  }
}