@import "../../App/Styles/Variables.scss";

.liberty-pager {
  .neo-pager-controls {
    align-items: baseline;
  }

  .align-page-number {
    padding-left: 4px;
    padding-right: 5px;
  }

  .static-text {
    font-family: "NotoSans-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: $standard-black;
  }

  .pagination {
    align-items: center;
  }

  .pager-page-buttons {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    align-items: center;

    .page-item .page-link {
      width: 36px;
      height: 36px;
      padding: 2px 13px 2px 14px;
      border-radius: 6px;

      &.page-number {
        line-height: 2.6;
        color: black;
      }
    }

    .page-item {
      width: 36px;
      height: 74px;
      margin-right: 4px;
      margin-left: 4px;
      display: flex;
      align-items: center;

      &.active {
        .page-number {
          color: black;
        }
      }
    }

    .previous-page,
    .next-page {
      .page-link {
        line-height: 1.8;
      }
    }
  }

  .page-item .page-link {
    color: gray;
    background-color: transparent;
    border: none;
    font-family: "NotoSans-Regular";
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 0px;
  }

  .first-page {
    width: 110px;
    height: 36px;
    padding: 8px 15px 9px 11px;
    border-radius: 6px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .last-page {
    width: 47px;
    height: 36px;
    padding: 0px;
    border-radius: 6px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .previous-page {
    width: 110px;
    height: 36px;
    padding: 8px 15px 9px 11px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .next-page {
    width: 110px;
    height: 36px;
    padding: 9px 10px 9px 14px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 500px) {
    .next-page {
      padding: 4px;

      i {
        display: none;
      }
    }

    .previous-page {
      padding: 4px;

      i {
        display: none;
      }
    }

    .last-page {
      padding: 4px;

      i {
        display: flex;
      }
    }

    .first-page {
      padding: 4px;

      i {
        display: none;
      }
    }
  }
}