@import "../Variables.scss";

.reports-and-documents-card {
  width: auto;
  background: $white;
  box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  height: auto;
  border-radius: 5px;
  margin: 40px 15px 30px 15px;

  &.tab-header-card {
    margin-bottom: 15px;
  }

  &.body {
    margin-top: 20px;
  }

  @media (max-width: 1200px) {
    margin: 30px 10px 30px 5px;
  }

  @media (max-width: 768px) {
    margin: 30px 3px 30px 5px;
  }

  .row {
    &.align-link-reports {
      padding: 10px 10px 0px 10px;
    }
  }

  .css-1wa3eu0-placeholder {
    top: 46%;
  }

  .save-portfolio-link {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $azureBlue;
    text-decoration: none;
    margin-left: 2rem;
    padding-bottom: 24px;
    padding-top: 0px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      margin-left: 0px;
      text-align: center;
    }

    @media (min-width: 360px) and (max-width: 428px) {
      color: $azureBlue;
      display: block;
      text-decoration: none;
      text-align: center;
      margin-left: 0rem;
    }
  }

  .separating-line-status {
    width: 99.8%;
    fill: $lightPurpleGrey;
    border-bottom: 1px solid $lightPurpleGrey;
    margin: 1% 0% 24px 0%;
  }

  .line-reports {
    fill: $gray;
    border: 1px solid $gray;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0.2rem;
    width: 100%;
  }

  .reports-header {
    margin-left: 0.2rem;
    font-family: "NotoSans-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-left: 5px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: $standard-black;
  }

  .align-report-tab-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: 2px solid $azureBlue;
    width: 99%;
    border-radius: 4px;
    margin-left: 0.8%;

    &.col-md-6 {
      width: fit-content;
      padding-left: 0%;
      padding-right: 0%;
    }

    .btn-contact {
      border: none;
      border-radius: 0px;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1.23px;

      @media (max-width: 768px) {
        font-size: 14px;
      }

      &.btn {
        background-color: $azureBlue;
        color: $white;
        width: 185px;
        height: 40px;
        --bs-gradient: none;

        @media (max-width: 768px) {
          width: 149.81px;
        }
      }

      &.btn-primary {
        --bs-btn-hover-bg: #{$azureBlue};
        --bs-btn-hover-color: #{$white};
        --bs-btn-active-color: #{$white};
        --bs-btn-active-bg: #{$azureBlue};
      }
    }

    .btn-complain {
      box-sizing: border-box;
      border-radius: 0px;
      width: auto;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1.23px;

      @media (max-width: 768px) {
        font-size: 14px;
      }

      &.btn {
        background-color: $white;
        color: $azureBlue;
        border: none;
        width: 185px;
        height: 40px;
        --bs-gradient: none;

        @media (max-width: 768px) {
          width: 149.81px;
        }
      }

      &.btn-primary {
        --bs-btn-hover-bg: $white;
        --bs-btn-hover-color: #{$azureBlue};
      }
    }
  }

  .reports-card {
    width: auto;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    height: auto;
    border-radius: 5px;
    margin-bottom: 16px;
    margin-top: 13px;

    &.add-padding-bottom {
      padding-bottom: 20px;
    }
  }

  .reports-card {
    .at-class {
      .neo-datepicker-popup {
        width: 50%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .react-select-bs__indicators {
      display: none;

      &.css-1hb7zxy-IndicatorsContainer {
        display: none;
      }
    }

    .react-select-bs__value-container {
      background: url(../../assets/img/drop-down-arrow.png) no-repeat right;
      background-position-x: 97%;
      background-position-y: 50%;
      height: 45px;

      .react-select-bs__single-value {
        width: 85%;
      }
    }

    .react-select-bs__menu {
      .react-select-bs__menu-list {
        color: $standard-black;
      }
    }

    .form-group {
      .form-control {
        margin-left: 2%;
        height: 48px;
        width: 90%;
        color: $standard-black;
        background-color: $shadowGrey;
        font-size: 18px;
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #afb3c2;
        background: url(../../assets/img/drop-down-blue.png) no-repeat 96% 50%;

        &:checked {
          color: pink;
          background-color: blue;
        }
      }

      label {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $standard-black;
        margin-left: 2%;
      }
    }

    .align-reports-dropdown {
      color: black;

      .css-1hb7zxy-IndicatorsContainer {
        display: none;
      }

      .css-yk16xz-control {
        font-family: "NotoSans-Regular";
        box-sizing: border-box;
        background: $shadowGrey;
        border: 1px solid #afb3c2;
        border-radius: 4px;
        height: 48px;
      }

      .css-b8ldur-Input {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #afb3c2;

        .react-select-bs__input {
          :input {
            width: 110px;
          }
        }
      }

      .css-4ljt47-MenuList {
        padding-bottom: 0px;
        padding-top: 0px;
      }

      .css-g1d714-ValueContainer {
        color: black;
        background: url(../../assets/img/drop-down-blue.png) no-repeat 96% 50%;
      }

      .reports-label {
        margin-left: 3px;
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }

      @media (max-width: 768px) {
        padding-top: 10px;
      }

      .react-select-bs__single-value {
        width: 85%;
      }

      @media (min-width: 768px) and (max-width: 1194px) {
        .react-select-bs__single-value {
          width: 80%;
        }

        .react-select-bs__option {
          &.css-1n7v3ny-option {
            text-overflow: ellipsis;
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
          }

          &.css-yt9ioa-option {
            text-overflow: ellipsis;
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
          }

          &.react-select-bs__option--is-selected {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      .react-select-bs__option {
        background: none;

        &.react-select-bs__option--is-focused {
          background: none;
        }

        &.react-select-bs__option--is-selected {
          background: rgba(4, 14, 40, 0.0411932);
          color: black;
        }
      }

      .react-select-bs {
        .react-select-bs__control {
          &.react-select-bs__control--is-focused {
            height: 48px;
          }
        }
      }
    }
  }

  .neo-datepicker-container {
    @media (min-width: 768px) and (max-width: 1194px) {
      width: 101.5%;
    }

    .form-control {
      font-family: "NotoSans-Regular";
      box-sizing: border-box;
      background: $white;
      border: 1px solid #afb3c2;
      border-radius: 4px;
      height: 48px;
      width: 100%;
      background:
        url(../../assets/img/calendar.png) no-repeat 96% 50%;
    }

    .neo-datepicker-popup {
      margin-top: 15px;
      width: 100%;
      background: $white;
      box-shadow:
        0px 0px 2px rgba(0, 0, 0, 0.03),
        0px 6px 10px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      border: none;

      @media (min-width: 768px) and (max-width: 1194px) {
        width: 104%;
      }

      .neo-datepicker-header {
        background-color: $white;
        border-bottom: 1px solid $lightPurpleGrey;
        margin-left: 12px;
        margin-right: 12px;

        @media (min-width: 768px) and (max-width: 1194px) {
          margin-left: 8px;
          margin-right: 8px;
        }

        .left-button {
          color: $azureBlue;
          padding: 0px;
          font-size: 28px;
          line-height: 0px;
          font-weight: 100;
        }

        .left-button:hover {
          background: #deecff;
        }

        .right-button {
          color: $azureBlue;
          padding: 0px;
          font-size: 28px;
          line-height: 0px;
          font-weight: 100;
        }

        .right-button:hover {
          background: #deecff;
        }

        .header-text {
          font-family: "NotoSans-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 34px;
          letter-spacing: 0.142857px;
          color: $standard-black;
          background: none;

          @media (min-width: 768px) and (max-width: 1194px) {
            font-size: 16px;
          }
        }

        .header-text:hover {
          background: #deecff;
        }
      }

      .neo-datepicker-content {
        margin: 0px;
        margin-left: 0px;
        margin-right: 5px;
        margin-bottom: 8px;
        width: 325px;
        position: initial;

        @media (min-width: 768px) and (max-width: 1194px) {
          position: initial;
          margin-left: 2px;
          margin-right: 2px;
        }

        @media (max-width: 768px) {
          margin: 0px;
        }

        table {
          width: 100%;

          @media (min-width: 768px) and (max-width: 1194px) {
            width: 96%;
          }
        }

        .day-view {
          thead {
            font-family: "NotoSans-Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: #afb3c2;

            tr {
              th {
                font-weight: 400;
              }
            }
          }

          tbody {
            background: $white;
            border-radius: 4px;

            tr {
              :hover {
                background: url("../../assets/img/oval.png") no-repeat 50% 50%;
                color: $white;
              }

              td {
                font-family: "NotoSans-Regular";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                text-align: center;
                padding: 3px;
                color: $standard-black;

                @media (min-width: 768px) and (max-width: 1194px) {
                  padding: 2px;
                }

                &.selected {
                  font-family: "NotoSans-Regualar";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 12px;
                  text-align: center;
                  color: $white;
                  background: url("../../assets/img/oval.png") no-repeat 50% 50%;
                  height: 30px;
                }

                &.today {
                  border: none;
                }
              }
            }
          }
        }

        .month-view {
          tbody {
            tr {
              td {
                &.today {
                  border: none;
                }

                &.selected {
                  border-radius: 100px;
                  background: $azureBlue;
                }
              }

              :hover {
                border-radius: 100px;
                background: $azureBlue;
                color: $white;
              }
            }
          }
        }

        .year-view {
          tbody {
            tr {
              td {
                &.today {
                  border: none;
                }

                &.selected {
                  border-radius: 100px;
                  background: $azureBlue;
                }
              }

              :hover {
                border-radius: 100px;
                background: $azureBlue;
                color: $white;
              }
            }
          }
        }
      }
    }

    .neo-datepicker-popup::after,
    .neo-datepicker-popup::before {
      bottom: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .neo-datepicker-popup::after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: $white;
      border-width: 9px;
      left: 80%;
    }

    .neo-datepicker-popup::before {
      border-color: rgba(113, 158, 206, 0);
      border-bottom-color: $white;
      border-width: 9px;
      left: 80%;
    }
  }

  .react-select-row {
    .with-description {
      .react-select-name {
        width: 100%;
        text-overflow: ellipsis;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .react-select-row {
    padding: 8px 12px;

    &.focused {
      background: $white;
      color: $black;
      padding: 8px 12px;
    }

    &.hover {
      background: $white;
      color: $black;
      padding: 8px 12px;
    }

    &.selected {
      background: rgba(4, 14, 40, 0.0411932);
      color: $black;
      padding: 8px 12px;
    }
  }

  .report-grid {
    display: flex;

    .table {
      border-collapse: separate;
      border-spacing: 0 15px;

      thead {
        border: none;
        display: contents;

        tr,
        td,
        th {
          text-overflow: ellipsis;
          max-width: 200px;
          background: none;
          color: $standard-black;
          font-family: "NotoSans-SemiBold";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 31px;
          border: none;
          border-style: hidden;
          text-align: left;
          padding-left: 1%;
        }
      }

      tbody {
        tr {
          height: 75px;
          background: $white;
          box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
          border-radius: 4px;
          border: none;
        }
      }

      td {
        font-family: "NotoSans-SemiBold";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        border-style: hidden;
        color: $standard-black;
        padding-left: 1%;

        &.display-date-column {
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: $standard-black;
        }
      }

      td.neo-grid-button-column {
        text-align: end;
        padding-right: 9.7%;

        .display-view-link {
          color: #0033aa;
          text-decoration: none;
        }
      }

      th.neo-grid-button-column {
        text-align: end;
        padding-right: 10%;
      }

      .btn-link {
        text-decoration: none;
        font-family: "NotoSans-Light";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        width: 34%;
        letter-spacing: 0.133333px;

        &.btn {
          color: #0033aa;
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0.133333px;

          @media (min-width: 2000px) and (max-width: 3500px) {
            padding-right: 80px;
          }
        }
      }
    }
  }

  .hide-grid {
    display: none;
  }

  .empty-grid-container {
    width: 100%;
    height: 412px;
    background-color: #f5f5f5;
    border-radius: 6px;
    border: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    .grid-info {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
      letter-spacing: 0.2px;
      color: $lighterGrey;
      padding-top: 40px;
      margin-bottom: 9rem;

      @media (max-width: 768px) {
        line-height: 24px;
        margin-top: 10px;
      }
    }

    @media (max-width: 768px) {
      height: 392px;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;

      .grid-info {
        margin-bottom: 0px;
        text-align: center;
        padding: 0px 40px;
      }
    }
  }

  .show-grid-mobile {
    display: none;
  }

  .display-mobile-card {
    display: none;
  }

  .align-generate-button {
    display: flex;
    justify-content: flex-end;

    .btn-generate {
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      padding-top: 10px;
      width: 154px;

      &.btn:hover {
        background-color: $azureBlue;
        --bs-gradient: none;
        color: white;
      }

      @media (max-width: 768px) {
        width: 100%;
        margin-right: 0px;

        &.search {
          margin: 0px 15px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .reports-and-documents-card {
    .show-grid-mobile {
      display: flex;
      flex-direction: column;
      margin-left: 14px;

      &.documents {
        margin-left: 0px;
      }
    }

    .report-grid {
      display: none;

      .document {
        display: unset;
      }
    }

    .reports-grid-card {
      width: 93%;
      background: $white;
      box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
      border-radius: 4px;
      border: none;

      &.documents {
        width: 100%;

        .card-body {
          padding: 6px 6px 6px 10px;
        }
      }

      .card-body {
        padding: 6px;
      }

      span {
        font-family: "NotoSans-Semibold";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 10px;
        color: $standard-black;
      }

      .report-grid-value {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $standard-black;
      }

      .align-report-value {
        padding-left: 3.5px;
      }

      img {
        width: 18px;
      }

      .btn-sm {
        text-decoration: none;
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.133333px;
        color: #0033aa;

        &.remove-right-margin {
          @media (max-width: 768px) {
            padding: 0px 0px 0px 4px;
          }
        }
      }
    }

    .align-reports-dropdown {
      .react-select-bs__value-container {
        &.react-select-bs__value-container--has-value {
          height: 50px;

          @media (max-width: 768px) {
            height: 45px;
          }
        }
      }
    }
  }
}

.css-13cymwt-control {
  height: 48px;
}