@import "../Variables.scss";

.show-notification {
  .show-notification-card {
    margin-top: 2%;
    height: auto;
    width: 98%;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    margin-left: 16px;
    padding: 0px;
    border: none;
    margin-left: 1.5%;

    @media (min-width: 768px) and (max-width: 1194px) {
      width: 96%;
    }

    @media (max-width: 768px) {
      width: 92%;
      margin-left: 4%;
      margin-top: 4%;
    }

    .card-body {
      padding: 12px 0px 0px 0px;

      @media (max-width: 768px) {
        padding: 8px 0px 0px 0px;
      }

      @media (max-width: 400px) {
        padding: 8px 0px 5px 5px;
      }

      .align-text-member-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 5px;
        padding-bottom: 5px;

        @media (max-width: 768px) {
          flex-direction: column;
          padding-bottom: 2px;
        }

        .notification-text-heading {
          font-family: "NotoSans-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 35px;
          letter-spacing: 0.2px;
          color: $standard-black;
          padding-left: 12px;
          padding-top: 7px;
        }

        .notification-sub-heading {
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 32px;
          text-align: right;
          letter-spacing: 0.171429px;
          color: $standard-black;

          .display-caret {
            display: contents;

            @media (max-width: 768px) {
              display: none;
            }
          }

          @media (max-width: 768px) {
            display: block;
            padding-left: 13px;
            text-align: left;
            line-height: 30px;
          }
        }
      }

      .notification-heading {
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 35px;
        letter-spacing: 0.2px;
        color: $standard-black;
        padding-left: 12px;
        padding-top: 7px;
      }

      .line-notifications-info-card {
        margin-bottom: 1rem;
        width: 100%;
        fill: $gray;
        border: 1px solid $gray;
      }

      .notification-date {
        font-family: "NotoSans-light";
        font-style: normal;
        font-weight: 300;
        font-size: 11px;
        letter-spacing: 0.171429px;
        color: $standard-black;
        padding-left: 17px;
        margin-bottom: 0px;
      }

      .notification-information {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        letter-spacing: 0.171429px;
        padding-left: 17px;
        color: $standard-black;

        @media (max-width: 768px) {
          padding: 10px 12px 10px 17px;
          line-height: 29px;
        }

        @media (min-width: 768px) and (max-width: 1194px) {
          line-height: 28px;
        }
      }

      .align-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
          flex-direction: column;
          margin-bottom: 2%;
        }

        .btn-notifications {
          box-sizing: border-box;
          background: $white;
          border: 2px solid $azureBlue;
          border-radius: 4px;
          font-family: "NotoSans-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 1.23px;
          color: $azureBlue;
          height: 40px;
          width: 17%;
          margin: 5% 1% 2% 1%;

          @media (max-width: 768px) {
            width: 94%;
          }

          @media (min-width: 768px) and (max-width: 1194px) {
            width: 25%;
            margin: 5% 1% 3% 1%;
          }
        }

        .btn-instructions {
          width: 17%;
          height: 40px;
          color: $white;
          border: 2px solid $azureBlue;
          background-color: $azureBlue;
          border-radius: 4px;
          font-family: "NotoSans-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 1.23px;
          margin: 5% 1% 2% 1%;
          --bs-gradient: none;

          @media (max-width: 768px) {
            width: 94%;
            margin: 2% 1% 2% 1%;
          }

          @media (min-width: 768px) and (max-width: 1194px) {
            width: 25%;
            margin: 5% 1% 3% 1%;
          }
        }

        .btn-instructions-liberty {
          width: 17%;
          height: 40px;
          color: $white;
          border: 2px solid $azureBlue;
          background-color: $azureBlue;
          --bs-gradient: none;
          border-radius: 4px;
          font-family: "NotoSans-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 1.23px;
          margin-bottom: 2%;

          @media (max-width: 768px) {
            width: 94%;
          }

          @media (min-width: 768px) and (max-width: 1194px) {
            width: 25%;
          }
        }
      }
    }
  }
}

.notifications-container {
  .mt-4 {
    &.notification-card {
      width: auto;
      background: $white;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
      height: auto;
      border-radius: 5px;
      margin-bottom: 50px;
      border: none;
      margin: 30px 10px 30px 10px;

      @media (min-width: 768px) and (max-width: 1194px) {
        margin: 30px 5px 30px 5px;
      }

      .show-on-desktop {
        &.row {
          display: flex;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }

      .card-heading-notifications {
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 35px;
        letter-spacing: 0.2px;
        color: $standard-black;
        margin-left: 16px;
        padding-top: 12px;
        padding-bottom: 5px;

        @media (max-width: 768px) {
          display: none;
        }

        @media (min-width: 768px) and (max-width: 1194px) {
          padding-top: 9px;
          padding-bottom: 0px;
        }
      }

      .line-notifications {
        margin-bottom: 2rem;
        margin-left: 12px;
        width: 98.5%;
        fill: $gray;
        border: 1px solid $gray;

        @media (max-width: 768px) {
          display: none;
        }
      }

      .line-notifications-thin {
        margin-bottom: 1rem;
        margin-left: 13px;
        width: 98.5%;
        fill: $gray;
        border: 1px solid $gray;
      }

      .notifications-form-group {
        &.form-group {
          label {
            display: none;
          }

          .form-control {
            margin-left: 5px;
            height: 48px;
            width: 105%;
            color: $standard-black;
            background-color: $shadowGrey;
            font-size: 18px;
            font-family: "NotoSans-Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            padding-left: 40px;
            border-radius: 4px;
            border: 1px solid #afb3c2;
            background: url("../../assets/img/img-search.png") no-repeat 0.8% 50%;

            @media (max-width: 768px) {
              width: 102%;
            }
          }
        }
      }

      .btn-search {
        background: $azureBlue;
        color: $white;
        border-radius: 4px;
        width: 11.6%;
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 1.23px;
        height: 40px;
        margin-left: 5%;
        --bs-gradient: none;
        --bs-btn-border-color: none;

        @media (max-width: 768px) {
          width: 95.4%;
          margin: 0% 5% 0% 5%;
        }

        &.btn:hover {
          background-color: $azureBlue;
          --bs-gradient: none;
          color: white;
        }
      }

      .display-alert {
        margin-right: 4%;

        &.alert {
          background: url(../../assets/img/info.png) no-repeat 1% 45%;
          padding-left: 3%;
          margin-left: 6px;
          background-size: 1.5%;

          h6 {
            font-family: "NotoSans-Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: $standard-black;
            padding-top: 4px;
          }
        }

        &.alert-primary {
          background-color: #deecff;
          box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
          border: none;
          width: 102%;
        }

        @media (min-width: 768px) and (max-width: 1194px) {
          &.alert {
            background-position-y: 47%;
            background-size: 2.5%;
            padding-left: 4%;
            width: 104%;

            h6 {
              font-size: 14px;
            }
          }
        }

        @media (max-width: 768px) {
          &.alert {
            background-position-y: 33%;
            background-size: 6.5%;
            padding-left: 8%;

            h6 {
              font-size: 14px;
              padding-left: 5px;
            }
          }

          &.alert-primary {
            width: 109%;
          }
        }
      }

      .notifications-tab-container {
        .tabs-header {
          padding-right: 9px;

          @media (max-width: 768px) {
            padding-left: 0px;
            padding-top: 13px;
          }
        }

        .nav {
          &.nav-pills {
            margin-left: 5px;
            width: 100%;
            fill: $gray;
            border-bottom: 2px solid $gray;

            @media (max-width: 768px) {
              margin-left: 17px;
              width: 97.5%;
            }

            .row {
              display: none;
            }

            .nav-item:first-child {
              .nav-link {
                border-radius: 4px 0px 0px 4px;
              }
            }

            .nav-item:last-child {
              .nav-link {
                border-radius: 0px 4px 4px 0px;
              }
            }

            .nav-link {
              color: $azureBlue;
              border-radius: 0px 0px 0px 0px;
              width: 176px;
              height: 48px;
              align-items: center;
              display: flex;
              justify-content: center;
              align-items: center;

              .card-tab-text-notifications {
                font-family: "NotoSans-Regular";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 42px;
                color: $standard-black;
                position: relative;

                .align-ellipse {
                  position: absolute;
                }

                .align-number {
                  position: absolute;
                  font-family: "NotoSans-Bold";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 10px;
                  line-height: 23px;
                  padding-left: 4px;
                  color: $white;
                }
              }

              &.my-contribution {
                border-radius: 0px 4px 4px 0px;
              }

              &.active {
                border-bottom: 4px solid $azureBlue;
                background: none;
                border-radius: 100px 100px 0px 0px;

                &.nav-link {
                  border-radius: 100px 100px 0px 0px;
                }

                .card-tab-text-notifications {
                  color: $standard-black;
                  font-family: "NotoSans-SemiBold";
                  font-weight: 600;
                }
              }
            }

            @media (max-width: 768px) {
              flex-wrap: nowrap;

              .nav-link {
                width: 173px;

                @media (max-width: 400px) {
                  width: 135px;
                }
              }
            }
          }
        }
      }

      .actions-container {
        .action-header {
          font-family: "NotoSans-Bold";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 35px;
          letter-spacing: 0.2px;
          color: $standard-black;
          margin-left: 7px;
          padding-bottom: 17px;
          padding-top: 6px;
        }

        .action-cards-member {
          width: 100%;
          background: $white;
          box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
          height: auto;
          border: none;
          margin-left: 8px;
          border-radius: 5px;
          margin-bottom: 10px;
          box-shadow: 0px 1px 4px rgb(139 146 167 / 50%);
          border-left: 7px solid #70adff;
          background: url("../../assets/img/line-control-big.jpg") no-repeat 94% 50%,
            url("../../assets/img/chevron-right.png") no-repeat 98% 50%;

          @media (min-width: 768px) and (max-width: 1194px) {
            width: 100.5%;
            background: url("../../assets/img/line-control-big.jpg") no-repeat 94% 50%,
              url("../../assets/img/chevron-right.png") no-repeat 99% 50%;
          }

          @media (max-width: 768px) {
            width: 100%;
            background: url("../../assets/img/line-control-big.jpg") no-repeat 88% 50%,
              url("../../assets/img/chevron-right.png") no-repeat 98% 50%;
          }

          &.action-cards-scheme {
            border-left: 7px solid $azureBlue;
          }

          &.action-cards-broadcast {
            border-left: 7px solid #36b37e;
          }

          &.neo-card {
            .card-body {
              padding: 12px 0px 5px 16px;

              .align-scheme {
                @media (max-width: 400px) {
                  width: 60%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }

              .align-info-status {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
              }

              .status-text-box {
                box-sizing: border-box;
                background: $white;
                border: 1.5px solid #006eff;
                border-radius: 4px;
                margin-right: 8%;
                width: 78px;
                height: 21px;

                .status-text {
                  font-family: "NotoSans-SemiBold";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 13px;
                  text-align: center;
                  color: #006eff;
                }
              }

              .read-status-box {
                width: 78px;
                height: 21px;
                margin-right: 8%;
                background: $white;
                border: 1px solid #767676;
                border-radius: 4px;
                box-sizing: border-box;

                .status-text {
                  font-family: "NotoSans-SemiBold";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 13px;
                  text-align: center;
                  color: #767676;
                }
              }

              .action-text {
                font-family: "NotoSans-Medium";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                height: 23px;
                letter-spacing: 0.2px;
                color: $standard-black;
              }

              .action-text-info {
                font-family: "NotoSans-Regular";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                height: 23px;
                color: $standard-black;

                @media (max-width: 768px) {
                  width: 78%;
                }
              }

              .action-date {
                font-family: "NotoSans-Light";
                font-style: normal;
                font-weight: 300;
                font-size: 11px;
                padding-top: 5px;
                letter-spacing: 0.171429px;
                color: $standard-black;
              }
            }
          }
        }
      }

      .show-on-mobile {
        &.row {
          display: none;

          @media (max-width: 768px) {
            display: flex;

            .action-text-mobile {
              font-family: "NotoSans-Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              height: 23px;
              letter-spacing: 0.2px;
              color: $standard-black;

              @media (max-width: 400px) {
                font-size: 14px;
                width: 48%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .align-text-status {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: baseline;

              .status-text-box-mobile {
                box-sizing: border-box;
                background: $white;
                border: 1.5px solid #006eff;
                border-radius: 4px;
                margin-right: 16%;
                width: 78px;
                height: 21px;
                color: #006eff;
                font-family: "NotoSans-SemiBold";
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                padding: 0% 4% 0% 4%;
              }

              .read-status-box-mobile {
                width: 78px;
                height: 21px;
                margin-right: 16%;
                background: $white;
                border: 1px solid #767676;
                border-radius: 4px;
                box-sizing: border-box;
                font-family: "NotoSans-SemiBold";
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                text-align: center;
                color: #767676;
                padding: 0% 4% 0% 4%;
              }
            }

            .action-text-info-mobile {
              font-family: "NotoSans-Regular";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              height: 23px;
              color: $standard-black;

              .align-scheme {
                @media (max-width: 400px) {
                  width: 60%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }

  .line-notifications-mobile {
    display: none;

    @media (max-width: 768px) {
      display: block;
      margin-top: 2rem;
      margin-left: 18px;
      width: 100%;
      fill: $gray;
      border: 1px solid $gray;
    }
  }

  .neo-pager {
    thead {
      display: none;
    }

    .pager-controls-container-bottom {
      width: 101.5%;
      text-align: inherit;

      @media (max-width: 768px) {
        width: 106.5%;
      }
    }
  }

  .neo-pager-controls {
    .neo-pager-page-size {
      color: black;
      width: 85%;
      padding-left: 16px;

      @media (min-width: 768px) and (max-width: 1194px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        width: 100%;
        padding-left: 11px;
      }

      select.form-control {
        display: none;
      }
    }

    .pagination {
      .page-item {
        &.active {
          .page-link {
            background-color: $azureBlue;
            color: white;
            border-radius: 50%;
            --bs-gradient: none;
          }
        }

        .page-link {
          border: none;
        }
      }
    }
  }
}