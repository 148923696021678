@import "../Variables.scss";

.instructions-card {
  @media (max-width: 1194px) {
    .col-2 {
      width: 15%;
      padding-left: 2px;
    }
  }

  .drop-color {
    color: $standard-black;
  }

  .card-heading-instructions {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;
    margin-left: 8px;

    @media (max-width: 428px) {
      margin-left: 10px;
    }

    @media (max-width: 1194px) {
      font-size: 18px;
    }

    @media (max-width: 260px) {
      margin-left: 10px;
    }
  }

  .top-pad {
    padding-top: 20px;
  }

  .card-sub-heading {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;
    margin-left: 8px;

    @media (max-width: 480px) {
      line-height: 20px;
    }

    @media (max-width: 428px) {
      margin-left: 10px;
    }

    @media (max-width: 260px) {
      margin-left: 10px;
    }
  }

  .alert-text {
    font-family: "NotoSans-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 68px;
    letter-spacing: 0.133333px;
    color: $standard-black;
    padding-left: 1%;
  }

  .drop-down {
    color: $standard-black;
  }

  .info-card {
    border-radius: 5px;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  }

  .form-group {
    .form-control {
      height: 48px;
      width: 100%;
      color: $standard-black;
      background-color: $shadowGrey;
      font-size: 18px;
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #afb3c2;

      &:disabled {
        color: #afb3c2;
        border: 1px solid $darkShadowGrey;
        width: 93%;
      }
    }

    label {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $standard-black;
      margin-left: 2%;
      margin-bottom: 1%;
      margin-top: 1rem;
    }
  }

  .form-label {
    color: black;
    margin-left: 8px;
  }

  .line-profile {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 4%;
    width: 92%;
    fill: $gray;
    border: 1px solid $gray;
  }

  .alert-text {
    font-family: "NotoSans-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 68px;
    letter-spacing: 0.133333px;
    color: $standard-black;
    padding-left: 1%;
  }

  .update-bf-height {
    line-height: 5px;

    @media (max-width: 428px) {
      line-height: 16px;
    }

    @media (max-width: 360px) {
      line-height: 16px;
    }
  }

  .divider1 {
    padding-left: 0px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    height: 0px;
    border: 1px solid $greyLine;

    @media (min-width: 768px) and (max-width: 1194px) {
      margin-top: 0.2rem;
      margin-bottom: 0.5rem;
      width: 100%;
    }

    @media (min-width: 360px) {
      margin-top: 1rem;
      margin-bottom: 1rem;

      width: 100%;
    }

    @media (min-width: 428px) {
      margin-top: 1rem;

      margin-bottom: 1rem;
      width: 100%;
    }
  }

  .neo-pager-controls .neo-pager-page-size select.form-control {
    display: none;
  }

  .align-profile-info {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      display: block;
      margin-left: 6px;

      .card-heading-profile {
        margin-top: 16px;
        margin-bottom: 0px;
        height: 15px;
      }
    }

    .align-alert-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;
      padding-left: 46px;

      @media (max-width: 768px) {
        justify-content: flex-start;
        margin-left: 17px;
        padding-left: 10px;

        .alert-text {
          padding-left: 3%;
        }
      }
    }
  }

  .heading-img {
    width: 13px;
    height: 13px;
    padding-bottom: 1px;
    padding-left: 5px;
  }

  .card-heading-text {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    color: $standard-black;

    @media (max-width: 1194px) {
      font-size: 12px;
    }
  }

  .card-heading-text-status {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    color: $standard-black;

    @media (max-width: 1194px) {
      font-size: 12px;
    }
  }

  .card-small-label {
    color: $standard-black;
  }

  .card-small-heading-text {
    font-family: "NotoSans-SemiBold";
    font-weight: 300;
    font-size: 14px;
    line-height: 31px;
    color: $standard-black;
  }

  .table-column-instruction-name {
    font-family: "NotoSans-Regular";
    font-style: normal;
    font-weight: 300;
    display: flex;
    flex-wrap: nowrap;
    font-size: 14px;
    white-space: nowrap;
    color: $standard-black;

    @media (max-width: 1194px) {
      font-size: 10px;
      padding-left: 0.5%;
    }

    @media (max-width: 500px) {
      font-size: 12px;
      padding-left: 0%;
    }
  }

  .table-heading-badb {
    padding-left: 24px;

    @media (max-width: 1194px) {
      padding-left: 20px;
    }
  }

  .table-row-cards-investment {
    height: 74px;
    width: 99.5%;
    background: $white;
    box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
    border-radius: 4px;
    margin: 1.3% 0px 1% 5px;
    padding: 1.2% 0px 0px 1%;

    background:
      url("../../assets/img/chevron-right.png") no-repeat 99% 50%,
      url("../../assets/img/line-control-big.jpg") no-repeat 96.3% 0%;

    @media (max-width: 1194px) {
      width: 99%;
      padding-top: 2.3%;
      margin-bottom: 1.5%;
      background:
        url("../../assets/img/chevron-right.png") no-repeat 98% 50%,
        url("../../assets/img/line-control-big.jpg") no-repeat 93% 0%;
    }

    @media (max-width: 1500px) {
      width: 99%;
      padding-top: 2.3%;
      margin-bottom: 1.5%;
    }

    @media (min-width: 1440px) and (max-width: 1500px) {
      margin: 1.3% 0px 1% 0px;
      padding: 1.5% 0px 0px 1%;
    }

    @media (min-width: 1600px) and (max-width: 200px) {
      margin: 1.3% 0px 1% 0px;
      padding: 2.5% 0px 0px 1%;
    }

    @media (max-width: 1200px) {
      width: 99%;
      padding-top: 2.7%;
      margin-bottom: 1.5%;
    }

    @media (max-width: 500px) {
      height: 127px;
      width: 98%;
      padding: 4% 0px 0px 4%;
      margin-bottom: 3.5%;
    }

    @media (max-width: 400px) {
      width: 97%;
      margin-bottom: 3.8%;
    }

    @media (min-width: 2500px) and (max-width: 3500px) {
      width: 97%;
      margin: 1.3% 0px 1% 30px;
      padding: 0.5% 0px 0px 1%;
    }
  }

  .tab-content > .tab-pane {
    padding-left: 7px;

    @media (max-width: 360px) {
      padding-left: 1px;
    }

    @media (max-width: 428px) {
      padding-left: 1px;
    }
  }

  .vertical {
    border-left: 2px solid #dbdbdb;
  }

  .btn-search {
    background: $azureBlue;
    color: $white;
    border-radius: 4px;
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1.23px;
    height: 40px;
    border: none;
    width: 90%;

    &.btn:hover {
      background-color: $azureBlue;
      --bs-gradient: none;
      color: white;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .search-div {
    &.col-md-2 {
      @media (max-width: 428px) {
        margin-top: 10px;
      }
    }
  }

  .search-box {
    .form-control {
      padding-left: 40px;
      background: url("../../assets/img/img-search.png") no-repeat 0.8% 50%;
    }
  }

  .search-btn {
    @media (max-width: 360px) and (max-width: 429px) {
      padding-top: 10px;
    }
  }

  .sort-text {
    color: $standard-black;
    padding-bottom: 0px;
  }

  .headerSortDown:after,
  .headerSortUp:after {
    content: " ";
    position: relative;
    left: 8px;
    border: 8px solid transparent;
  }

  .headerSortDown:after {
    top: 10px;
    border-top-color: silver;
  }

  .headerSortUp:after {
    bottom: 18px;
    border-bottom-color: silver;
  }

  .headerSortDown,
  .headerSortUp {
    padding-right: 10px;
  }

  .pb-message {
    padding-bottom: 10px;
  }

  .right-arrow {
    background-color: transparent;
    background-image: url("../../assets/img/down-right-black.png");
    background-repeat: no-repeat;
    border: none;
  }

  .btn-instruction {
    background: #deecff;
    color: $azureBlue;
    border-radius: 4px;
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1.23px;
    height: 40px;
    border: none;
    width: 20%;

    &.btn:hover {
      background-color: $azureBlue;
      --bs-gradient: none;
      color: white;
    }

    @media (max-width: 1200px) {
      width: 30%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    @media (max-width: 365px) {
      width: 100%;
    }

    @media (max-width: 428px) {
      width: 100%;
    }
  }

  .card-container {
    padding-right: 1px;
  }

  div.tabs-header {
    margin-bottom: 5px;
    padding-left: 21px;

    @media (max-width: 360px) {
      padding-left: 14px;
    }

    @media (max-width: 428px) {
      padding-left: 12px;
    }
  }

  .show-on-phone-screen {
    display: none;

    @media (max-width: 768px) {
      display: contents;
    }
  }

  .show-on-web-screen {
    display: contents;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .alert-heading {
    padding-left: 14px;
  }

  .neo-pager-controls .neo-pager-page-size {
    padding-left: 10px;
    width: 85%;
  }

  .search-container {
    padding-left: 1px;

    &.new-instructions-search-bar {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .display-alert-intsructions {
    &.alert {
      background: url(../../assets/img/info.png) no-repeat 1% 45%;
      padding-left: 3%;
      background-size: 1.5%;

      h6 {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: $standard-black;

        @media (min-width: 360px) and (max-width: 428px) {
          padding-left: 3%;
        }
      }
    }

    &.alert-primary {
      background-color: #deecff;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
      border: none;
    }

    @media (min-width: 768px) and (max-width: 1194px) {
      &.alert {
        background-position-y: 32%;
        background-size: 2.5%;
        padding-left: 4%;

        h6 {
          font-size: 14px;
        }
      }
    }

    @media (max-width: 768px) {
      &.alert {
        background-position-y: 24%;
        background-size: 6.5%;
        padding-left: 8%;

        h6 {
          font-size: 14px;
        }
      }
    }
  }

  .card-subheading-text {
    font-family: "NotoSans-Bold";
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    color: #424757;
  }

  .card-summary-text {
    font-family: "NotoSans-Bold";
    font-weight: 400;
    padding-left: 15px;
    font-size: 14px;
    line-height: 35px;
    color: #424757;
  }

  .heading-cu {
    width: auto;
    height: 35px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;

    @media (max-width: 400px) {
      margin-top: 5px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .phone-heading {
    color: #424757;
    padding-left: 25px;
  }

  .phone-value {
    color: black;
    font-weight: bold;
    padding-left: 25px;
  }

  .heading-cu-phone {
    width: auto;
    height: 35px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    padding-top: 8px;
    padding-left: 12px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: black;

    @media (max-width: 400px) {
      margin-top: 5px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .pad-left {
    padding-left: 15px;
  }

  .query-heading {
    padding-left: 15px;
    padding-top: 5px;
  }

  .truncate {
    display: block;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
  }

  .truncate:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }

  .file-upload-label {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $azureBlue;
    justify-content: center;
    align-items: center;
    align-content: center;

    @media (max-width: 428px) {
      display: none;
    }

    @media (max-width: 1194px) {
      display: none;
    }

    @media (max-width: 320px) {
      display: none;
    }
  }

  .file-download-label {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $azureBlue;
    float: left;
  }

  .file-drop-area {
    background: #deecff;
    border-radius: 4px;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    margin: 1%;

    &.neo-file-drop {
      border: 2px dashed $azureBlue;
      margin-right: 12px;
      margin-left: 12px;
    }
  }

  .file-download-area {
    background: #deecff;
    border-radius: 4px;
    display: flex;
    font-size: 12px;
    align-items: center;
    margin: 1%;
    width: 97%;

    &.neo-file-drop {
      border: none;
      margin-right: 12px;
      margin-left: 12px;
    }

    @media (max-width: 360px) {
      width: 93%;
    }

    @media (max-width: 428px) {
      width: 93%;
    }
  }

  .instructon-view-heading {
    padding-left: 5px;
    padding-bottom: 3px;
  }

  .instructon-view-status {
    float: right;
    color: $standard-black;
  }

  .fl-right {
    float: right;
  }

  .instructon-view-subheading {
    padding-left: 10px;
    color: $standard-black;
  }

  .instructon-view-message {
    padding-left: 18px;
    color: $standard-black;
    font-weight: 600;
  }

  .instructon-view-submessage {
    padding-left: 20px;
    color: $standard-black;
    font-weight: 600;
  }

  .up-docs {
    padding-left: 10px;
  }

  .align-filter {
    display: relative;
    float: right;
    padding-top: 5px;
    background: none;
    border: none;
    padding-right: 0px;

    &.btn:hover {
      border: none;
      background: none;
    }

    &:focus {
      border: none;
      background: none;
    }
  }

  .row {
    &.search-by {
      margin-left: 0px;
    }
  }

  .instructon-view-query-pdf {
    padding-left: 20px;
    color: $azureBlue;
    font-weight: bold;
  }

  .instructon-view-docs-label {
    padding-left: 20px;
  }

  .instructon-view-uploaded-docs {
    padding-left: 20px;
    color: $standard-black;
  }

  .instructon-view-date {
    padding-left: 18px;
    color: $standard-black;
    float: left;
  }

  .instructon-view-datelabel {
    padding-left: 18px;
    float: left;
    color: $standard-black;
  }

  .in-progress-pill {
    background-color: #84c83a;
    border: none;
    color: $white;
    padding: 4px 4px;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    width: 65%;
    display: inline-block;
    cursor: pointer;
    border-radius: 16px;

    @media (max-width: 1194px) {
      width: 100%;
      font-size: 12px;
    }

    @media (max-width: 468px) {
      width: 100%;
      font-size: 14px;
    }

    @media (max-width: 360px) {
      width: 100%;
      font-size: 13px;
    }
  }

  .incomplete-pill {
    background-color: #ffad30;
    border: none;
    color: $white;
    padding: 4px 4px;
    text-align: center;
    text-decoration: none;
    width: 65%;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    border-radius: 16px;

    @media (max-width: 1194px) {
      width: 100%;
      white-space: break-spaces;
      padding: 2% 2%;
      font-size: 12px;
    }

    @media (max-width: 468px) {
      width: 100%;
      font-size: 14px;
    }

    @media (max-width: 360px) {
      width: 100%;
      font-size: 13px;
    }
  }

  .btn-submit {
    background: $azureBlue;
    color: $white;
    border-radius: 4px;
    width: 23%;
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    height: 40px;
    letter-spacing: 1.23px;

    @media (max-width: 428px) and (max-width: 428px) {
      width: 93.5%;
    }
  }

  .center-submit {
    justify-content: center;
    position: relative;
  }

  .container {
    height: 50px;
    position: relative;
    padding-top: 210px;

    @media (max-width: 428px) {
      margin-top: 50%;
    }
  }

  .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .completed-pill {
    background-color: #84a7fa;
    border: none;
    color: $white;
    padding: 4px 4px;
    text-align: center;
    text-decoration: none;
    width: 70%;
    display: inline-block;
    cursor: pointer;
    border-radius: 16px;

    @media (max-width: 1194px) {
      width: 90%;
      white-space: break-spaces;
      padding: 2% 2%;
      font-size: 10px;
    }

    @media (max-width: 428px) {
      width: 100%;
      font-size: 14px;
    }

    @media (max-width: 360px) {
      width: 100%;
      font-size: 13px;
    }
  }

  .neo-pager-controls .neo-pager-page-size {
    @media (max-width: 428px) {
      display: none;
    }
  }

  .pill-container {
    display: inline-table;
    align-items: center;
    height: 100%;
    padding-bottom: 50%;
  }

  .save-portfolio-link {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $azureBlue;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 360px) and (max-width: 428px) {
      color: $azureBlue;
      display: block;
      text-decoration: none;
      font-size: 12px;
      text-align: center;
    }
  }

  .align-back-btn {
    padding-left: 14px;

    @media (max-width: 428px) {
      text-align: center;
      justify-content: center;
    }

    @media (max-width: 360px) {
      text-align: center;
      justify-content: center;
    }
  }

  .back-link {
    font-size: 16px;
    color: $azureBlue;
    line-height: 35px;
    letter-spacing: 0.2px;
    text-decoration: none;
    padding-left: 24px;
    font-weight: 600;

    @media (max-width: 428px) {
      font-size: 14px;
      color: $azureBlue;
      display: block;
      text-decoration: none;
      font-size: 14px;
      text-align: center;
    }

    @media (max-width: 1194px) {
      font-size: 14px;
      color: $azureBlue;
      display: block;
      text-decoration: none;
      font-size: 14px;
    }

    @media (max-width: 360px) {
      font-size: 14px;
      color: $azureBlue;
      display: block;
      text-decoration: none;
      font-size: 14px;
      text-align: center;
    }
  }

  .instruction-card {
    @media (max-width: 768px) {
      width: 100%;
      background: url("../../assets/img/chevron-right.png") no-repeat 98% 50%;
    }
  }

  .instruction-tab {
    color: $standard-black;
    font-weight: 600;

    @media (max-width: 360px) {
      display: table-caption;
    }
  }

  .nav-tabs .nav-link.active {
    border-bottom: solid 4px $azureBlue;
    border-left: none;
    border-right: none;
    border-top: none;
    padding-left: 3px;

    @media (max-width: 428px) {
      padding-left: 7px;
    }
  }

  .nav-item {
    overflow-wrap: unset;
  }

  .profile-modal {
    .react-select-bs__option {
      color: $standard-black !important;
    }

    .react-select-bs__indicators {
      color: $azureBlue !important;
    }

    .css-6q0nyr-Svg {
      color: $azureBlue !important;
      size: 0.5px;
    }
  }

  .member-profile-card {
    .css-2b097c-container {
      position: relative;
      box-sizing: border-box;
      border: none;
      width: 100%;
      margin-left: 0%;
    }

    .react-select-bs__indicators {
      &.css-1hb7zxy-IndicatorsContainer {
        display: none;
      }
    }

    .css-4ljt47-MenuList {
      color: black;
    }

    .css-g1d714-ValueContainer {
      background: url(../../assets/img/dropdown-blue.png) no-repeat 98% 50%;
      height: 48px;
    }

    .react-select-bs__option {
      &.react-select-bs__option--is-focused {
        background: none;
      }

      &.react-select-bs__option--is-selected {
        background: rgba(4, 14, 40, 0.0411932);
        color: black;
      }
    }

    .react-select-bs {
      .css-yk16xz-control {
        font-family: "NotoSans-Regular";
        box-sizing: border-box;
        background-color: $shadowGrey;
        border: 1px solid #afb3c2;
        border-radius: 4px;
        height: 48px;
      }
    }

    .react-select-bs__control--menu-is-open {
      .css-g1d714-ValueContainer {
        background: url("../../assets/img/drop-up-arrow.png") no-repeat 98% 50%;
      }
    }

    .custom-control-input {
      margin-right: 4%;
      width: 25px;
      height: 25px;
    }

    .custom-control-label {
      font-size: 16px;
      font-weight: 500;
      font-style: "NotoSans-Medium";
    }

    .custom-control-inline {
      width: 35%;
      display: flex;
      align-items: center;
    }

    .custom-control-inline {
      input[type="radio"] {
        accent-color: $azureBlue;
      }
    }

    .align-radio {
      color: $standard-black;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      padding-top: 2%;
      padding-left: 4%;

      label {
        flex: 1 1 auto;
        color: $standard-black;
      }
    }

    .align-communication-method {
      padding-top: 1.8rem;
      padding-left: 1rem;
      color: black;

      .align-communication-label {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }

      .align-province-label {
        padding-bottom: 0.5rem;
        margin-top: 0px;
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        @media (min-width: 768px) and (max-width: 1194px) {
          padding-bottom: 4px;
        }
      }

      @media (max-width: 768px) {
        padding-left: 0rem;

        .css-2b097c-container {
          margin-left: 1.5%;
          width: 90%;
        }

        .align-province-label {
          margin-left: 6px;
        }
      }
    }

    .align-buttons {
      display: flex;
      justify-content: flex-end;


      &.btn:hover {
        color: $azureBlue;
        background-color: white;
      }

      .btn-update {
        background: $azureBlue;
        color: $white;
        border-radius: 4px;
        width: 30%;
        margin-right: 2%;
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 1.23px;
        height: 40px;

        &.btn:hover {
          background-color: $azureBlue;
          --bs-gradient: none;
          color: white;
        }
      }

      @media (min-width: 768px) and (max-width: 1194px) {
        .btn-update {
          width: 35%;
        }

        .btn-cancel {
          width: 35%;
        }
      }
    }
  }

  .start-new-instruction {
    padding: 30px 15px;

    .instruction-background {
      border-radius: 5px;
      background: $white;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 20px 0px 15px 0px;

      .card-heading-new-instructions {
        color: $standard-black;
        font-family: "NotoSans-Regular";
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.133px;
        margin-left: 20px;

        .card-sub-heading-new-instructions {
          font-size: 14px;
          font-weight: 400;
          margin: 10px 0px;
        }
      }

      .container-new-instruction {
        padding: 15px;

        .new-instruction-tab-container {
          .tabs-header {
            text-align: center;
            font-family: "NotoSans-Regular";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 15px;

            .nav-link {
              color: $standard-black;
              border: none;

              &.active {
                font-family: "NotoSans-SemiBold";
                font-weight: 600;
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 100px 100px 0px 0px;
                border-bottom: 4px solid $azureBlue;
                padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
              }

              &.hover {
                border: none;
              }
            }
          }
        }

        .new-instruction-table {
          &.table {
            border-top: white;
            border-collapse: inherit;
            border-spacing: 0px 15px;

            thead {
              display: contents;
              color: #0d1325;
              font-family: "NotoSans-SemiBold";
              font-size: 14px;
              font-weight: 600;
              line-height: 31px;

              th {
                border: none;
                padding: 7px;
                vertical-align: middle;
                background: none;

                .column-sort-icon {
                  margin-top: 8px;
                  margin-left: 15px;
                  position: relative;
                  // display: flex;
                }
              }
            }

            tbody {
              border-style: unset;

              tr {
                height: 60px;
                fill: #fff;
                box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
                margin-bottom: 10px;
                padding-left: 10px;
                border-radius: 5px;

                .name-column {
                  padding-left: 15px;
                }

                td {
                  border: none;

                  .form-control {
                    border: none;
                  }
                }
              }

              .add-circle-image {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}