@import "../Variables.scss";

.knowledge-forms-container {
  @media (min-width: 1200px) {
    margin: 40px 15px 0px 15px;
  }

  @media (max-width: 1200px) {
    margin: 30px 10px 30px 5px;
  }

  @media (max-width: 768px) {
    margin: 0px 3px 0px 5px;
  }

  .knowledge-forms-card {
    width: 100%;
    background: $white;
    box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
    border-radius: 4px;
    border: none;
    margin: 30px 15px 30px 0px;

    @media (max-width: 768px) {
      margin: 30px 15px 30px 0px;
    }

    .sub-card-container {
      padding-top: 2%;

      @media (max-width: 768px) {
        padding-top: 20px;
      }

      .knowledge-forms-inner-card {
        background: $white;
        box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
        border-radius: 5px;
        margin-left: 8%;
        width: 84%;
        min-height: 270px;
        max-height: 100%;
        flex-wrap: wrap;
        padding-top: 3%;
        margin-bottom: 5%;

        @media (max-width: 768px) {
          min-height: 200px;
          height: fit-content;
          margin-left: 7%;
          width: 86%;
        }

        .align-image {
          padding-top: 2%;
          padding-bottom: 2%;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 768px) {
            margin-bottom: 8%;
          }
        }

        .sub-card-header {
          font-family: "NotoSans-SemiBold";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 31px;
          color: $standard-black;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          @media (max-width: 768px) {
            line-height: 35px;
          }
        }

        .inner-card-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          padding: 10px 30px 0px 30px;
          margin-bottom: 20px;
          text-align: center;
          letter-spacing: 1px;
          color: $black;
          height: 102px;

          @media (max-width: 1200px) {
            height: 120px;
          }
        }

        .btn-kf {
          font-size: 16px;
          height: 40px;
          margin-bottom: 5px;
          --bs-gradient: none;
          --bs-btn-border-color: none;
        }
      }

      .align-second-card {
        margin-left: 2%;

        @media (max-width: 768px) {
          margin-left: 7%;
        }
      }
    }

    .row {
      &.add-padding-to-back-to-dashboard-button {
        padding-top: 5px;
        padding-bottom: 8px;
      }
    }
  }
}

.modal-container {
  .modal-dialog {
    &.modal-xl {
      &.k-and-c-template-modal {
        .modal-content {
          max-width: 100%;
          align-items: normal;

          .modal-body {
            text-align: left;
            padding: 10px 10px 0px 10px;
          }

          .modal-footer {
            width: 100%;
          }
        }
      }
    }
  }
}

.k-and-c-template-modal,
.k-c-maintenance-view {
  .background-card {
    background-color: $white;

    .neo-card {
      &.card {
        color: $standard-black;

        .card-body {
          padding: 0px;

          .align-header {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            text-align: center;
            align-items: baseline;
            padding-top: 10px;
            border-bottom: 1px solid rgb(139 146 167 / 50%);
            background-color: rgb(139 146 167 / 10%);
          }

          .align-card-body {
            color: $standard-black;
            height: 80px;
            padding: 8px;
          }

          .align-url {
            color: blue;
            padding: 8px;
          }

          .align-title {
            flex: 10;
            margin-right: 115px;
          }
        }
      }
    }

    fieldset {
      padding: 20px;

      legend {
        color: #000;
        border-bottom: 1px solid rgb(139 146 167 / 50%);
      }
    }
  }

  .btn.btn-primary {
    background: $azureBlue;
    color: $white;
    font-family: "NotoSans-Light";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    border-radius: 4px;
    width: auto;
    height: 40px;
    line-height: 20px;

    &:hover {
      background: $azureBlue;
      color: $white;
    }

    &.btn-sm {
      padding: 13px 16px 15px 16px;
      width: 100px;
      height: 32px;
      font-size: 14px;
    }
  }

  .modal-header {
    color: #00164e;
  }

  .modal-body {
    .form-group {
      margin-bottom: 20px;
    }

    .form-group label {
      color: black;
    }
  }

  .modal-content .modal-body {
    padding: var(--bs-modal-padding);
  }

  .form-control {
    background: $shadowGrey;
    border: 1px solid #8b92a7;
    border-radius: 4px;
  }

  .background-card {
    padding-right: 30px;
  }

  button.close {
    background: none;
    border: none;

    span {
      font-size: 24px;
    }
  }
}