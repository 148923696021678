@import "../Variables.scss";

.cards-top-section-cu {
  max-height: 70px;
  min-height: 30px;
}

.line-cu {
  width: 100%;
  fill: $lightPurpleGrey;
  border-bottom: 1px solid $lightPurpleGrey;
  margin: 15px 0px 15px 0px;

  &.bigger-card {
    margin-top: 0px;
  }
}

.cards-headings-cu {
  font-family: "NotoSans-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $standard-black;
  background: $white;

  @media (max-width: 500px) {
    padding-top: 0px;
    font-size: 14px;
    line-height: 16.34px;
  }
}

.icon-cu {
  width: 32px;
  height: 32px;
  float: left;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $purple;
  color: $white;
  font-size: 12px;
  font-family: "NotoSans-Regular";
  font-weight: 400;
  border-radius: 50%;
}

.information-cards-cu {
  width: 265px;
  height: 95%;
  background: $white;
  box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
  border-radius: 4px;
  padding: 18px;
}

.row > .negate-col-information-card-margin {
  width: 265px;
  padding-top: 0px;
  margin: 10px 16px 10px 0px;

  @media (max-width: 768px) {
    padding-left: 0px;
    margin-inline: auto;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.information-heading-cards-cu {
  font-family: "NotoSans-SemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $standard-black;
  margin: 0px 0px 10px 40px;
  text-align: left;
}

.information-heading-financial-advisers-cards-cu {
  font-family: "NotoSans-SemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: $standard-black;
  text-align: center;

  &.financial-advisers-cards {
    font-size: 16px;
    line-height: 22px;
  }
}

.information-available-cards-cu {
  font-family: "NotoSans-Regular";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $darkerGreyishBlue;
  margin: 0px 0px 10px 40px;
}

.information-description-cards-cu {
  font-family: "NotoSans-Regular";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $darkerGreyishBlue;
  margin-top: 15px;

  &.additional-email-heading {
    color: $standard-black;
    font-family: "NotoSans-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-top: 10px;
  }
}

.additional-tel-heading {
  color: $standard-black;
  font-family: "NotoSans-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  a {
    color: $hoverBlue;
    text-decoration: none;
  }
}

.information-detail-contact-information-cards-cu {
  font-family: "NotoSans-SemiBold";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  line-height: 14px;
  color: $standard-black;
  margin-bottom: 10px;
  height: 10px;

  a {
    color: $hoverBlue;
    text-decoration: none;
  }
}

.information-detail-cards-cu {
  font-family: "NotoSans-SemiBold";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  line-height: 14px;
  color: $standard-black;
  margin-bottom: 10px;

  &.remove-margin {
    margin-bottom: 0px;
  }

  a {
    color: $hoverBlue;
    text-decoration: none;
  }

  &.remove-margin {
    margin-bottom: 0px;
  }
}

.information-detail-adress-cards-cu {
  font-family: "NotoSans-SemiBold";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  line-height: 18px;
  color: $standard-black;
  margin-bottom: 10px;
}

.information-complain-detail-cards-cu {
  font-family: "NotoSans-SemiBold";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  line-height: 18px;
  color: $standard-black;
  margin-bottom: 5px;

  a {
    color: $hoverBlue;
    text-decoration: none;
  }
}