@import "../Variables.scss";

.member-profile-container {
  @media (min-width: 1000px) {
    margin: 0px 10px 0px 10px;
  }
}

.address-tick-section {
  color: $standard-black;
  text-align: right;
  font-family: "NotoSans-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding-right: 10px;
  width: 80%;
}

.checkbox-container {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
}

.checkbox-container input[type="checkbox"] {
  width: 24px;
  height: 24px;
  margin-top: 0px;

  &:checked {
    background: url("../../assets/img/checked.png");
    margin-top: 0px;
    border: none;
  }
}

.btn-bottom-padding {
  padding-right: 35px;
  padding-left: 50px;

  @media (max-width: 1500px) {
    padding-right: 4px;
    padding-left: 4px;
  }
}

.profile-modal {
  .modal-header {
    color: #00164e;
    border: none;
    padding-bottom: 0px;
    justify-content: center;
  }

  button.close {
    background: none;
    border: none;

    span {
      font-size: 31px;
      float: right;
      color: $azureBlue;
    }
  }

  .modal-content {
    padding: var(--bs-modal-padding);
    border: none;

    .modal-heading {
      width: 128px;
      height: 26px;
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.142857px;
      color: $standard-black;
      margin: auto;
    }

    .model-body {
      padding: 0;
    }

    .modal-text {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: $standard-black;
      padding-top: 3%;
    }

    .modal-footer {
      border: none;
      justify-content: center;
      border-radius: 4px;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $white;

      &.btn-success {
        width: 100%;
        color: $white;
        --bs-gradient: none;
        background-color: $azureBlue;
        border-color: $azureBlue;
        padding: 3%;
      }

      .btn-secondary {
        display: none;
      }
    }
  }
}

.member-profile-card {
  .divider-profile-header {
    width: 100%;
    height: 1px;
    background: $greyLine;
    margin-bottom: 30px;

    &.divider-profile-footer {
      margin-bottom: 0px;
    }
  }

  .container-div {
    display: flex;
    align-items: baseline;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  .right-div {
    padding: 10px;
    justify-self: end;

    @media (max-width: 768px) {
      display: contents;
    }

    .cancel-btn-css {
      background: $white;
      color: $azureBlue;
      border-radius: 4px;
      width: 176px;
      margin: 10px;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1.23px;
      height: 40px;

      @media (max-width: 768px) {
        width: 90%;
      }

      &.btn:hover {
        background-color: $azureBlue;
        --bs-gradient: none;
        color: $white;
      }

      &.update-btn {
        background: $azureBlue;
        color: $white;

        @media (max-width: 768px) {
          width: 90%;
        }
      }
    }
  }

  .card-heading-profile {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;
    margin-left: 14px;
    margin-bottom: 0px;

    @media (max-width: 500px) {
      margin-left: 6px;
    }
  }

  .alert-text {
    font-family: "NotoSans-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 68px;
    letter-spacing: 0.133333px;
    color: $standard-black;
    padding-left: 1%;
    margin-bottom: 0px;
  }

  .form-container {
    margin-left: 60px;
    margin-right: 60px;

    @media (max-width: 768px) {
      margin-left: 30px;
      margin-right: 30px;
    }

    .form-group {
      .form-control {
        height: 48px;
        color: $standard-black;
        background-color: $white;
        font-size: 18px;
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid $lightGrey;

        &:disabled {
          color: $lightGrey;
          border: 1px solid $darkShadowGrey;
        }
      }

      label {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $standard-black;
        margin-bottom: 1%;
        margin-top: 1rem;

        @media (max-width: 1200px) {
          font-size: 12px;
        }
      }
    }

    .divider-profile-content {
      width: 100%;
      height: 1px;
      background: $greyLine;
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }

  .form-label {
    color: black;
  }

  .employer-member-profile-dropdown {
    .react-select-bs__value-container {
      background:
        url(../../assets/img/dropdown-blue.png) no-repeat 97% 50%;

      &.react-select-bs__control--menu-is-open {
        background: url(../../assets/img/drop-up-arrow.png) no-repeat 97% 50%;
      }
    }
  }
}

.align-profile-info {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: baseline;
  margin-left: 8px;
  height: 67px;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
    margin-left: 6px;
    height: auto;

    .card-heading-profile {
      margin-top: 16px;
      margin-bottom: 0px;
    }
  }

  .align-alert-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 21px;

    @media (max-width: 768px) {
      justify-content: flex-start;
      margin-left: 8px;

      .alert-text {
        padding-left: 3%;
      }
    }
  }
}

.member-profile-card {
  width: auto;
  background: $white;
  box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  height: auto;
  border-radius: 5px;
  margin: 30px 15px 30px 5px;

  .css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    border: none;
  }

  .react-select-bs__control {
    border: 1px solid $lightGrey;
  }

  .react-select-bs--is-disabled {
    height: 48px;
    background-color: $shadowGrey;

    .react-select-bs__control--is-disabled {
      height: 48px;
      background-color: $shadowGrey;
    }
  }

  .react-select-bs__indicators {
    display: none;

    &.css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
  }

  .css-4ljt47-MenuList {
    color: black;
  }

  .react-select-bs__menu-list {
    color: black;
  }

  .css-g1d714-ValueContainer {
    background: url(../../assets/img/dropdown-blue.png) no-repeat right;
    height: 48px;
  }

  .react-select-bs__value-container--has-value {
    background: url(../../assets/img/arrow-down-solid.png) no-repeat right;
    height: 48px;
  }

  .react-select-bs__option {
    &.react-select-bs__option--is-focused {
      background: none;
    }

    &.react-select-bs__option--is-selected {
      background: rgba(4, 14, 40, 0.0411932);
      color: black;
    }
  }

  .react-select-bs {
    .css-yk16xz-control {
      font-family: "NotoSans-Regular";
      box-sizing: border-box;
      border: 1px solid $lightGrey;
      border-radius: 4px;
      height: 48px;
    }
  }

  .react-select-bs__control--menu-is-open {
    .css-g1d714-ValueContainer {
      background: url("../../assets/img/drop-up-arrow.png") no-repeat right;
    }
  }

  .custom-control-input {
    width: 25px;
    height: 25px;
    margin: 5px;
  }

  .custom-control-label {
    font-size: 16px;
    font-weight: 500;
    font-style: "NotoSans-Medium";
  }

  .custom-control-inline {
    width: 35%;
    display: flex;
    align-items: center;
  }
}

.form-container-second {
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .align-cell-label {
    &.form-label {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .address-card {
    border-radius: 10px;
    background: #eef0f5;
    margin-top: 30px;
    margin-bottom: 30px;

    .align-address-box {
      padding: 20px 30px 20px 30px;

      @media (max-width: 768px) {
        padding: 20px 10px 20px 10px;
      }

      .address-header {
        color: $standard-black;
        font-family: "NotoSans-Regular";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.2px;
      }
    }

    .form-group {
      .form-control {
        height: 48px;
        width: 100%;
        color: $standard-black;
        background-color: $white;
        font-size: 18px;
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        border-radius: 4px;

        &:disabled {
          color: $lightGrey;
          border: 1px solid $darkShadowGrey;
          width: 100%;

          @media (max-width: 768px) {
            width: 96%;
          }
        }
      }

      label {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $standard-black;
        margin-bottom: 1%;
        margin-top: 2rem;

        @media (max-width: 768px) {
          margin-top: 1.5rem;
        }
      }
    }

    .custom-control.custom-checkbox {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-top: 4rem;

      label {
        color: #0d1325;
        text-align: right;
        font-family: "NotoSans-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        margin-right: 10px;
        pointer-events: none;
      }
    }

    .custom-control-input {
      &:checked {
        accent-color: $azureBlue;
      }
    }

    .employer-member-profile-dropdown {
      color: $black;
      margin-top: 2rem;

      .employer-card-header {
        color: $standard-black;
        font-family: "NotoSans-Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 0.2px;
      }

      .css-1hb7zxy-IndicatorsContainer {
        display: none;
      }

      .css-yk16xz-control {
        font-family: "NotoSans-Regular";
        box-sizing: border-box;
        background: $shadowGrey;
        border: 1px solid $lightGrey;
        border-radius: 4px;
        height: 48px;
        color: $standard-black;
      }

      .react-select-bs__control--menu-is-open {
        height: 48px;
      }

      .react-select-bs__control--is-focused {
        height: 48px;
      }

      .css-b8ldur-Input {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $lightGrey;

        .react-select-bs__input {
          :input {
            width: 110px;
          }
        }
      }

      .css-4ljt47-MenuList {
        padding-bottom: 0px;
        padding-top: 0px;
        max-height: 200px;
      }

      .dropdown-label {
        margin-top: 0px;
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 1%;
      }

      .css-1uccc91-singleValue {
        width: 86%;
      }

      .form-group {
        label {
          display: none;
        }
      }
    }
  }
}

.input-form-group {
  &.cell-info {
    padding-left: 0px;

    .input-group {
      z-index: 1;
    }
  }

  label {
    color: $standard-black;
    font-family: "NotoSans-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0.5rem;
    padding-right: 20%;

    @media (max-width: 768px) {
      margin-top: 1rem;
      font-size: 12px;
    }
  }

  .input-group {
    .form-control {
      border: 1px solid $lightGrey;
      height: 48px;
    }
  }

  .input-group-append {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    border: 1px solid $lightGrey;
    height: 48px;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;

    .custom-control-input {
      &:checked {
        accent-color: $azureBlue;
      }
    }

    .custom-checkbox {
      height: 35px;
    }

    .form-check-input {
      width: 1.5em;
      height: 1.5em;
      margin-top: 0px;

      &:checked {
        background: url(../../assets/img/checked.png);
        border: none;
      }
    }

    .custom-checkbox:checked {
      background: url(../../assets/img/checked.png);
      background-color: none;
      border-color: none;
      background-color: $azureBlue;
      padding-right: 23px;
    }
  }
}

.col-2 {
  &.countries-container {
    padding-right: 0px;
  }

  .country-codes-dropdown {
    width: auto;

    .css-g1d714-ValueContainer {
      background: url(../../assets/img/chevron-down.png) no-repeat right;

      @media (max-width: 768px) {
        padding-left: 0px;
      }
    }

    .react-select-bs__control--menu-is-open {
      .css-g1d714-ValueContainer {
        background: url("../../assets/img/chevron-down.png") no-repeat right;

        @media (max-width: 768px) {
          padding-left: 0px;
        }
      }
    }
  }
}