@import "../Variables.scss";

.background-card {
  &.complaint-summary-card {
    margin: 28px 15px 30px 10px;
    padding-bottom: 20px;

    @media (max-width: 768px) {
      margin: 20px 15px 30px 5px;
    }
  }
}

.complaints-review-css {
  .align-buttons-complaints-summary {
    margin-top: 2%;

    @media (max-width: 768px) {
      margin-top: 8%;
    }

    .align-buttons {
      display: flex;
      justify-content: center;

      .btn-continue {
        background: $azureBlue;
        color: $white;
        border-radius: 4px;
        width: 19%;
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        height: 40px;
        letter-spacing: 1.23px;

        @media (max-width: 768px) {
          width: 72%;
        }

        @media (min-width: 768px) and (max-width: 1194px) {
          width: 24%;
        }
      }
    }
  }

  .row {
    &.pad-lr {
      .summary-container {
        &.border-pix {
          border: 1px solid $greyLine;
          border-radius: 5px;
        }
      }
    }
  }

  .border {
    border-radius: 5px;
  }

  .pad-header {
    padding-left: 50px;
  }

  .pad-lr {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pad-rl {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
  }

  .pad-left {
    padding-left: 15px;
  }

  .pad {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .heading-smr {
    color: black;
    text-align: center;
    justify-content: center;
  }

  .background-card {
    &.scheme-information-header {
      min-height: 0px;
      margin: 2% 0px 1% 5px;
      padding: 10px 15px 0.1px 1.5%;

      @media (max-width: 1500px) {
        width: 99%;
        margin-bottom: 1.5%;
      }

      @media (max-width: 1000px) {
        width: 98%;
      }

      @media (max-width: 500px) {
        margin: 5% 0px 3% 5px;
        padding-left: 5%;
      }

      @media (max-width: 400px) {
        margin: 6% 0px 3% 5px;
        padding-left: 4%;
      }
    }

    .align-buttons-complaints-summary {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .background-card-review {
    box-shadow: none;

    &.scheme-information-header {
      min-height: 0px;
      margin: 2% 0px 1% 5px;
      padding: 10px 15px 0.1px 1.5%;

      @media (max-width: 1500px) {
        width: 99%;
        margin-bottom: 1.5%;
      }

      @media (max-width: 1000px) {
        width: 98%;
      }

      @media (max-width: 500px) {
        margin: 5% 0px 3% 5px;
        padding-left: 5%;
      }

      @media (max-width: 400px) {
        margin: 6% 0px 3% 5px;
        padding-left: 4%;
      }
    }
  }

  .show-on-web-screen {
    display: contents;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .center-link {
    text-align: center;
  }

  .show-on-phone-screen {
    display: none;

    @media (max-width: 768px) {
      display: contents;
    }
  }

  .show-on-phone-screen-summary {
    display: none;

    @media (max-width: 768px) {
      display: contents;
    }
  }

  .card-text-phone-screen {
    width: 50%;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 15px;
    color: #424757;
    padding-bottom: 15px;
    padding-top: 5px;

    @media (max-width: 768px) {
      margin-top: 20px;
      margin-bottom: 0px;
      margin-right: 50px;
    }

    @media (max-width: 400px) {
      width: 50%;
      color: #424757;
    }

    &.share {
      padding-top: 20px;
    }
  }

  .card-text-phone-screen-document {
    font-family: "NotoSans-SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
    color: $azureBlue;
    margin-bottom: 15px;
  }

  .col-5 {
    &.card-text-phone-screen {
      width: 28%;
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $standard-black;
      padding-top: 10px;

      @media (max-width: 400px) {
        width: 37%;
      }

      &.share {
        padding-top: 20px;
      }
    }
  }

  .col-7 {
    &.padding-phone {
      padding-top: 6px;

      &.table-card-name {
        font-family: "NotoSans-SemiBold";
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        color: $standard-black;

        .beneficiary-number {
          font-family: "NotoSans-regular";
          font-weight: 400;
          text-decoration: underline;
          font-size: 12px;
        }
      }

      &.share {
        padding: 16px 0 8px 10px;
      }
    }
  }

  .approved-benefit-card-items {
    height: 74px;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    border: none;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: $standard-black;
    margin-top: 15px;
    padding-top: 5px;

    @media (max-width: 768px) {
      height: 200px;
      font-size: 12px;
      line-height: 20px;
      color: $standard-black;

      &.funeral-benefit-height {
        height: 162px;
        padding-top: 0px;
      }
    }
  }

  .tabs-dropdown-products {
    display: none;

    @media (max-width: 500px) {
      display: contents;
      font-family: "NotoSans-Medium";
      font-weight: 500;
      font-size: 12px;
      line-height: 19px;
      color: $black;
    }
  }

  .dropdown-header-products {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $black;
    margin: 4% 0px 0px 1px;
  }

  .tabs-dropdown-products {
    .react-select-bs__indicators {
      &.css-1hb7zxy-IndicatorsContainer {
        display: none;
      }
    }

    .css-4ljt47-MenuList {
      color: black;
    }

    .css-g1d714-ValueContainer {
      background: url("../../../App/assets/img/drop-down-arrow.png") no-repeat right;
      margin-right: 10px;
      height: 39px;
    }

    .react-select-bs__option {
      background: none;

      &.react-select-bs__option--is-selected {
        background-color: rgba(4, 14, 40, 0.0411932);
        color: black;
      }
    }

    .react-select-bs {
      .css-yk16xz-control {
        font-family: "NotoSans-Regular";
        box-sizing: border-box;
        background: $shadowGrey;
        border: 1px solid #afb3c2;
        border-radius: 4px;
      }
    }

    .react-select-bs__option:not(:checked) {
      background: none;
    }
  }

  .divider1 {
    width: 100%;
    border-bottom: 0.5px solid $greyLine;
    display: inline-block;
  }

  .row {
    &.table-heading-badb {
      margin-left: 3px;
    }
  }

  .card-heading-text {
    font-family: "NotoSans";
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    color: #424757;
  }

  .card-subheading-text {
    font-family: "NotoSans-Bold";
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    color: #424757;
  }

  .card-summary-text {
    font-family: "NotoSans-Bold";
    font-weight: 400;
    padding-left: 15px;
    font-size: 14px;
    line-height: 35px;
    color: #424757;
  }

  .card-summary-uploaded-document {
    font-family: "NotoSans-SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.2px;
    color: $azureBlue;
    margin-bottom: 15px;
    padding-bottom: 15px;

    @media (max-width: 786px) {
      margin: 15px 0px 0px 0px;

    }
  }

  .card-summary-text-phone {
    font-family: "NotoSans-SemiBold";
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    color: #424757;
  }

  .card-tab-text-products {
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: $azureBlue;

    @media (max-width: 450px) {
      display: none;
    }
  }

  .top-pad {
    padding-top: 30px;
  }

  .heading-cu {
    width: auto;
    height: 41px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    padding-top: 8px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;

    @media (max-width: 400px) {
      margin-top: 5px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .heading-cu-phone {
    width: auto;
    height: 35px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: $standard-black;

    &.page-label-heading {
      padding-top: 8px;
      line-height: 35px;
    }

    &.complaint-note {
      padding-top: 12px;
      line-height: 35px;
    }

    &.document-label-heading {
      padding-top: 12px;
      line-height: 35px;
    }

    @media (max-width: 400px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .heading-co {
    width: auto;
    height: 35px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    padding-top: 8px;
    padding-left: 20px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;

    @media (max-width: 400px) {
      margin-top: 5px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .heading-cu-summary {
    height: 35px;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 30px;
    padding-top: 8px;
    display: inline;
    text-align: center;
    line-height: 34px;
    letter-spacing: 0.2px;
    color: $standard-black;

    @media (max-width: 400px) {
      font-size: 16px;
      justify-content: center;
      line-height: 10px;
      display: flex;
      text-align: center;
    }
  }

  .img-container {
    height: 120px;

    @media (max-width: 400px) {
      height: 120px;
    }
  }

  .img-top-div {
    height: 290px;
  }

  .line-break {
    display: none;

    @media (max-width: 768px) {
      display: contents;
    }
  }

  .blue-block {
    border: 1px dashed $azureBlue;
    height: 74px;
    width: 100%;
    background: #deecff;
    border-radius: 4px;
    text-align: center;
    margin: 20px 0 20px 0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 25px;

    @media (max-width: 768px) {
      height: 131px;
      text-align: center;
    }

    .blue-block-text {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #0033aa;
      margin-right: 7px;
    }

    .or-text {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      line-height: 19px;
      font-size: 14px;
      color: $standard-black;
      text-decoration-line: none;
      margin: 0 18px 0 14px;

      @media (max-width: 768px) {
        line-height: 30px;
      }
    }

    .add-text {
      font-family: "NotoSans-Medium";
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 1.23px;
      color: $azureBlue;
      text-decoration-line: none;
    }
  }

  .blue-block-text {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #0033aa;
    margin-right: 7px;
  }

  .center {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 15px;

    @media (max-width: 768px) {
      line-height: 23px;
    }
  }

  .summary-img {
    margin: auto;
    display: block;
    width: 180px;
    height: 80px;

    @media (max-width: 750px) {
      margin-bottom: 40px;
      font-size: 13px;
      line-height: 18px;
      width: 240px;
      height: 120px;
    }
  }

  .phone-heading {
    color: #424757;
    margin-top: 15px;
  }

  .phone-value {
    font-family: "NotoSans-SemiBold";
    font-size: 14px;
    line-height: 24px;
    color: #424757;
  }

  .sub-heading-cu {
    width: auto;
    height: 20px;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    padding-top: 10px;
    line-height: 20px;
    color: $standard-black;
    margin-bottom: 20px;

    @media (max-width: 750px) {
      margin-bottom: 40px;
      font-size: 13px;
      line-height: 18px;
    }

    @media (max-width: 400px) {
      margin-bottom: 40px;
      font-size: 13px;
    }
  }

  .nav {
    &.nav-pills {
      .nav-item:first-child {
        .nav-link {
          border-radius: 4px 0px 0px 4px;
        }
      }

      .nav-item:last-child {
        .nav-link {
          border-radius: 0px 4px 4px 0px;
        }
      }

      .nav-link {
        border: 2px solid $azureBlue;
        border-radius: 0px 0px 0px 0px;
        width: 203px;
        height: 48px;
        padding-top: 12px;

        @media (max-width: 900px) {
          width: 170px;
        }

        @media (max-width: 660px) {
          width: 100%;
        }

        @media (max-width: 500px) {
          display: none;
        }

        &.my-contribution {
          border-radius: 0px 4px 4px 0px;
        }

        &.active {
          background: $azureBlue;

          .card-tab-text-products {
            color: $white;
            width: 204px;
            padding-right: 22%;

            @media (max-width: 1150px) {
              width: 100%;
              padding-right: 0%;
            }
          }
        }
      }
    }
  }
}

.btn-width {
  @media (max-width: 430px) {
    width: 106%;
  }
}

.btn-complain {
  box-sizing: border-box;
  border-radius: 0px;
  width: auto;
  font-family: "NotoSans-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.23px;

  &.btn {
    background-color: $white;
    color: $azureBlue;
    border: none;
    width: 185px;
    height: 40px;
    --bs-gradient: none;
  }

  &.btn-primary {
    --bs-btn-hover-bg: $white;
    --bs-btn-hover-color: #{$azureBlue};
  }
}

.btn-complain {
  box-sizing: border-box;
  border-radius: 0px;
  width: auto;
  font-family: "NotoSans-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.23px;

  &.btn {
    background-color: $white;
    color: $azureBlue;
    border: none;
    width: 185px;
    height: 40px;
    --bs-gradient: none;
  }

  &.btn-primary {
    --bs-btn-hover-bg: $white;
    --bs-btn-hover-color: #{$azureBlue};
  }
}

.member-info-card {
  .card-body {
    padding: 11px;
  }
}