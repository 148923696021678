@import "../Variables.scss";

.custom-error-handler {
  @media (max-width: 428px) {
    width: 100%;
  }

  .modal-button {
    margin-left: auto;
    width: 70%;
    height: 40px;
    margin-right: auto;
    display: block;
    background-color: $azureBlue;
    --bs-gradient: none;
    color: $white;

    &.btn-primary {
      --bs-btn-hover-bg: #{$azureBlue};
      --bs-btn-hover-color: $white;
      --bs-btn-active-bg: #{$white};
      color: $white;
    }
  }

  .blue-guy {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .link-under {
    text-decoration: none;
  }

  .modal-header-text {
    color: #00164e;
    font-size: 28px;
    font-weight: 600;
    font-family: NotoSans-SemiBold;
    text-align: center;
    word-wrap: break-word;
  }

  .modal-text {
    margin-left: 5%;
    font-size: 16px;
    margin-right: 5%;
    color: #00164e;
    text-align: center;
    word-wrap: normal;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    line-height: 22px;
  }
}