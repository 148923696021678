@import "./Variables.scss";

.container-footer-component {
  .link-container {
    margin-top: 1%;
    margin-bottom: 1%;

    &-mobile {
      .back-link-mobile {
        height: 40px;
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 1em;
        line-height: 35px;
        letter-spacing: 0.2px;
        color: $azureBlue;
        text-decoration: none;
        display: flex;
        justify-content: center;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      @media (min-width: 1024px) {
        display: none;
      }
    }

    .back-link {
      height: 40px;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      padding-right: 2%;
      font-size: 1em;
      line-height: 35px;
      letter-spacing: 0.2px;
      color: $azureBlue;
      text-decoration: none;
      margin-left: 1.2rem;
      padding-left: 2%;

      @media (max-width: 450px) {
        padding-right: 0px;
        margin-top: 10px;
        width: 100%;
        text-align: center;
        margin-left: 0;
      }

      @media (max-width: 1020px) {
        display: none;
      }

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    @media (max-width: 768px) {
      margin-top: 1%;
      text-align: center;
    }
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 3%;
    margin-top: 1%;
    margin-bottom: 1%;

    @media (max-width: 768px) {
      margin-top: 2%;
      margin-right: 0;
      text-align: center;
      flex-direction: column-reverse;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }

    .footer-button {
      width: 23%;
      height: 40px;
      margin-left: 3%;
      min-width: fit-content;

      &.looks-disabled {
        background: $greyishBlue;
        border-color: $greyishBlue;
      }

      @media (max-width: 768px) {
        width: 90%;
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 3%;
      }

      &.btn-secondary {
        @media (max-width: 768px) {
          margin-bottom: 0;
        }
      }
    }
  }
}