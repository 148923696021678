@import "../../App/Styles/Variables.scss";

.savings-withdrawal-claim-view-container {
  @media (max-width: 1200px) {
    margin-top: 30px;
  }

  @media (max-width: 786px) {
    margin-top: 10px;
  }

  .savings-withdrawal-claim-view {
    border-radius: 5px;
    background: $white;
    box-shadow: 0px 3px 7px 0px rgba(139, 146, 167, 0.5);
    width: 99%;
    margin-left: 5px;
    margin-bottom: 20px;

    @media (max-width: 1200px) and (min-width: 786px) {
      width: 100%;
      margin-left: 0px;
    }

    @media (max-width: 786px) {
      width: 98%;
    }

    .card-margin {
      .savings-withdrawal-claim-view-heading {
        color: $standard-black;
        font-family: "NotoSans-SemiBold";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;

        @media (max-width: 786px) {
          font-size: 16px;
        }
      }

      .card-container {
        margin: 20px 0px;

        .neo-card {
          &.card {
            &.withdrawal-claims-cards {

              &.collapsed {
                .card-header {

                  .card-header-title {
                    .align-title {

                      .accessible-image {
                        margin-left: 10px;
                        margin-right: 20px;

                        @media (max-width: 768px) {
                          margin-left: 5px;
                          margin-right: 10px;
                        }
                      }
                    }

                    @media (max-width: 768px) {
                      font-size: 12px;
                      line-height: 13px;
                    }

                  }

                }
              }
            }
          }
        }

        .neo-card.card.withdrawal-claims-cards:not(.collapsed) {
          box-shadow: none;

          .card-header {

            .card-header-title {

              .align-title {

                .accessible-image {
                  margin-left: 10px;
                  margin-right: 20px;

                  @media (max-width: 768px) {
                    margin-left: 5px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }

      .start-claim-button {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 768px) {
          justify-content: center;
          margin-bottom: 20px;
        }

        .btn {
          min-height: 40px;
          height: fit-content;
        }
      }
    }

  }
}