@import "../Variables.scss";

.profile-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: $purple;
  color: $white;
  font-size: 12px;
  font-family: "NotoSans-Regular";
  font-weight: 400;
  border-radius: 50%;
  margin-right: 8px;
}