@import "../Variables.scss";

.useful-links-container {
  .useful-links-card {
    width: 100%;
    background: $white;
    box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
    border-radius: 4px;
    border: none;
    margin-bottom: 10%;

    .align-header {
      display: flex;
      justify-content: space-between;

      .align-edit-button {
        background: $azureBlue;
        border-radius: 4px;
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 1px;
        color: $white;
        width: 5%;
        height: 40px;
        --bs-gradient: none;
        --bs-btn-border-color: none;

        @media (min-width: 768px) and (max-width: 1194px) {
          width: 8%;
        }

        @media (max-width: 768px) {
          width: 20%;
        }
      }

      .card-header-ul {
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.2px;
        color: $standard-black;
        margin: 1.5rem 1.5rem 1rem 1.5rem;
      }
    }

    .line-ul {
      height: 1px;
      background: $lightPurpleGrey;
      border-radius: 0.5px;
      margin-bottom: 0%;
      margin-top: 0.5%;
    }

    .line-ul-kc {
      height: 1px;
      background: $lightPurpleGrey;
      border-radius: 0.5px;
      margin-top: 5%;
    }

    .regulatory-bodies-card {
      margin: 30px;

      @media (max-width: 768px) {
        margin: 5px;
      }

      &.neo-card {
        &.card {
          background: $white;
          border: none;
          border-radius: 4px;

          .card-body {
            padding: 0px;

            .sun-editor {
              border: none;
              pointer-events: none;
              width: 100%;

              a {
                text-decoration: underline;
                pointer-events: initial;
              }
            }

            .sub-card-header {
              font-family: "NotoSans-SemiBold";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: $standard-black;
              padding: 1rem 1rem 0.5rem 1rem;
            }

            .line-ul {
              height: 1px;
              background: $lightPurpleGrey;
              border-radius: 0.5px;
              margin-bottom: 0%;
            }

            .regulatory-bodies-ul {
              margin: 1% 1% 1% 2%;
              color: black;
              font-family: "NotoSans-Regular";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;

              @media (max-width: 768px) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}