@import "../../App/Styles/Variables.scss";

.btn {
  font-family: "NotoSans-Medium";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1.23px;
  display: flex;
  justify-content: center;
  font-style: normal;
  text-align: center;
  height: 40px;
  --bs-gradient: none;
  --bs-btn-focus-border: none;
  --bs-btn-focus-box-shadow: 0;
  --bs-btn-focus-shadow-rgb: 0;
  --bs-btn-border-radius: 4px;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-box-shadow: none;
  --bs-btn-hover-bg: none;
  --bs-btn-hover-color: none;
  --bs-btn-hover-border-color: none;
  --bs-btn-active-color: #{$white};
  --bs-btn-active-bg: none;
  --bs-btn-active-border-color: #{$azureBlue};
  align-items: center;

  &.btn-primary {
    box-shadow: none;
    font-weight: 500;
    background-color: $azureBlue;
    color: $white;
    border-color: $azureBlue;
    --bs-btn-hover-bg: #{$azureBlue};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-border-color: #{$azureBlue};
    --bs-btn-active-color: #{$white};
    --bs-btn-active-bg: #{$azureBlue};
    --bs-btn-active-border-color: #{$azureBlue};
    --bs-btn-disabled-bg: #597d9b;
    --bs-btn-border-radius: 4px;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-box-shadow: none;
    align-items: center;
  }

  &.btn-secondary {
    box-shadow: none;
    font-weight: 500;
    color: $azureBlue;
    background-color: transparent;
    border-color: $azureBlue;
    border-width: 2px;
    border-style: solid;
    --bs-gradient: none;
    --bs-btn-hover-bg: #{$white};
    --bs-btn-hover-color: #{$azureBlue};
    --bs-btn-hover-border-color: #{$azureBlue};
    --bs-btn-active-bg: #{$white};
    --bs-btn-active-color: #{$azureBlue};
    --bs-btn-active-border: #{$azureBlue};
    --bs-btn-active-border-color: #{$azureBlue};
    --bs-btn-border-radius: 4px;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-box-shadow: none;
    align-items: center;
  }

  &.btn-outline-secondary {
    font-weight: 500;
    color: $azureBlue;
    background-color: $white;
    border-color: $azureBlue !important;
    border-width: 2px;
    border-style: solid;
    --bs-gradient: none;
    --bs-btn-hover-bg: #{$white};
    --bs-btn-hover-color: #{$azureBlue};
    --bs-btn-hover-border-color: #{$azureBlue};
    --bs-btn-active-bg: #{$white};
    --bs-btn-active-color: #{$azureBlue};
    --bs-btn-active-border: #{$azureBlue};
    --bs-btn-active-border-color: #{$azureBlue};
    --bs-btn-border-radius: 4px;

    &:focus-visible {
      box-shadow: none;
    }

    &:active {
      box-shadow: none;
    }
  }

  &.btn-no-outline-secondary {
    font-weight: 500;
    color: $azureBlue;
    background-color: $white;
    border: none;
    --bs-gradient: none;
    --bs-btn-hover-bg: #{$white};
    --bs-btn-hover-color: #{$azureBlue};
    --bs-btn-hover-border-color: #{$azureBlue};
    --bs-btn-active-bg: #{$white};
    --bs-btn-active-color: #{$azureBlue};
    --bs-btn-active-border: #{$azureBlue};
    --bs-btn-active-border-color: #{$azureBlue};
    --bs-btn-border-radius: 4px;

    &:focus-visible {
      box-shadow: none;
    }

    &:active {
      box-shadow: none;
    }
  }

  &.btn-outline-danger {
    color: $bs-danger;
    background-color: $white;
    border-color: $white;
    --bs-gradient: none;
  }

  &.btn-outline-danger:hover {
    color: $bs-danger;
    background-color: $white;
    border-color: $white;
    --bs-gradient: none;
  }

  &.btn-outline-danger:first-child:active {
    color: $bs-danger;
    background-color: $white;
    border-color: $white;
    --bs-gradient: none;
  }

  &.btn-light {
    color: $azureBlue;
    background: $white;
    border: 1px solid $azureBlue;
    border-radius: 4px;
    display: flow;
  }

  &.btn-outline-light {
    color: $azureBlue;
    background: $white;
    border: 1px solid $azureBlue;
    border-radius: 4px;
  }

  &.btn-inline-icon-text {
    display: flex;
    justify-content: space-evenly;

    .inline-icon {
      margin-right: 5px;
    }
  }

  &.btn-cancel {
    border: none;
  }

  &.view-button {
    border-radius: 4px;
    border-color: transparent;
    color: white;
    border-width: 1px;
    background: none;
    font-size: 14px;
    margin-left: -1%;
    background-color: transparent;

    :active {
      background-color: transparent;
      border-color: transparent;
    }

    .btn:active {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

.add {
  width: 80%;
  text-align: center;
  padding-left: 15%;
  background: blue;
  color: white;
  border-width: 0;
  border-radius: 4px;
  padding-top: 3%;
  padding-bottom: 3%;
}

.browse-button {
  &.btn {
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
    color: $azureBlue;
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.428571px;
    padding-left: 0.3%;
    width: auto;

    &.btn:hover,
    &.btn:first-child:hover {
      border: none;
      background: none;
      text-decoration: underline;
      cursor: pointer;
      color: $azureBlue;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.428571px;
      padding-left: 0.3%;
    }
  }
}