//Main scss
@import "./Variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
// Neo component styles
@import "~@singularsystems/neo-react/styles/Neo.scss";
// Component scss
//@import "./Components/Misc.scss";
@import "./Components/Sidebar.scss";
@import "./Components/Table.scss";
//Views
@import "./MasterPage.scss";
//Liberty Component styles
@import "./LibertyButtons.scss";
// @import "./LibertyModal.scss";
@import "./ContainerFooter.scss";

@font-face {
  font-family: "NotoSans-Blackttf";
  src: url(../assets/fonts/NotoSans-Black.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url(../assets/fonts/NotoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans-Thin";
  src: url(../assets/fonts/NotoSans-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans-Medium";
  src: url(../assets/fonts/NotoSans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans-Bold";
  src: url(../assets/fonts/NotoSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans-SemiBold";
  src: url(../assets/fonts/NotoSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans-Light";
  src: url(../assets/fonts/NotoSans-Light.ttf) format("truetype");
}

// Include this if you want cards to collapse on mobile screen sizes to save space.
// @import "./Components/MobileSpaceSaver.scss";