@import "../Variables.scss";

.dashboard-divider {
  display: none;
}

.dashboard-divider2 {
  display: none;
}

.product-investment-card {
  width: 100%;
  height: 40%;
  background: $lightBlue;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  padding-right: 5px;
  margin: 8px 5px 9.5px 5px;
  border: none;

  &.card {
    height: 42%;
    padding-bottom: 3%;
    background: $lightBlue;
    border-radius: 8px;
    border: none;
  }
}

.progress-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 8% 0 auto 0;

  &.card-footer {
    border-top: none;
    padding: 11% 2% 0% 1.5%;
  }
}

.align-progress {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;
}

.align-complete {
  width: 20%;
  white-space: nowrap;
  margin-left: 3%;
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.133333px;
  color: $white;
}

.align-span {
  display: flex;
  padding-top: 2%;
  padding-bottom: 3%;
  width: 100%;

  &.card-footer {
    border-top: none;
  }
}

.box {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
}

.box_new {
  flex: 1 1;
  display: flex;
}

.investment-content {
  margin-left: 6%;
  margin-top: 2%;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  i {
    height: 69%;
  }
}

.investment-heading {
  margin-top: 12%;
  margin-bottom: 1%;
  font-family: "NotoSans-Medium";
  font-size: 20px;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  height: 30px;
  width: 65%;
  max-width: 100%;
  margin-right: 4%;

  @media (min-width: 768px) and (max-width: 1200px) {
    margin-right: 0%;
  }

  .progress-bar {
    border-radius: 6px;
  }

  .bg-danger {
    //As per the client's request with reference to figma designs changes has been implemented accordingly.
    background-color: #f46363 !important;
  }

  .bg-success {
    //As per the client's request with reference to figma designs changes has been implemented accordingly.
    background-color: #84c83a !important;
  }

  .bg-warning {
    //As per the client's request with reference to figma designs changes has been implemented accordingly.
    background-color: #fcb315 !important;
  }
}

.goal-card {
  &.card {
    height: 85%;
    border: none;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  }
}

.align-goal-inner-cards {
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  display: flex;
  overflow: hidden;
  justify-content: space-around;
  margin-right: 0px;
  margin-left: 1%;

  &.card-body {
    padding: 1% 0%;
  }
}

.align-goals-inner-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  h5 {
    font-size: 14px;
    font-family: "NotoSans-Regular";
    letter-spacing: 0.133333px;
    font-weight: 500;
  }

  p {
    font-size: 18px;
    font-family: "NotoSans-Thin";
    font-style: normal;
    font-weight: 400;
  }
}

.goals-achieved-inner-cards {
  width: 48%;
  font-family: "NotoSans-Regular";
  background: linear-gradient(58.72deg, #485563 7.71%, #29323c 92.87%);
  box-shadow: 0px 3px 11px rgba(241, 241, 241, 0.92);
  border-radius: 8px;
  justify-content: center;
  align-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  &.card {
    height: 93%;
    display: flex;
    border: none;
    border-radius: 10px;
  }
}

.align-goal-inner-card-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: $white;
  align-items: center;
  overflow: hidden;
  margin-top: 7%;

  h5 {
    font-size: 16px;
    font-family: "NotoSans-Medium";
    letter-spacing: 0.133333px;
    font-weight: 500;
  }

  p {
    font-size: 20px;
    font-family: "NotoSans-Thin";
    font-style: normal;
    font-weight: 400;
    margin-top: 7%;
  }

  &.card-body {
    padding-bottom: 6%;
  }
}

.goal-achieved-header {
  font-size: 16px;
  font-family: "NotoSans-Regular";
  letter-spacing: 0.133333px;
  font-weight: 500;
}

.monthly-income-inner-cards {
  width: 48%;
  font-family: "NotoSans-Regular";
  background: linear-gradient(225.16deg, #f46363 -5.03%, #ff3636 117.83%);
  box-shadow: 0px 3px 11px rgba(241, 241, 241, 0.92);
  border-radius: 8px;
  justify-content: center;
  display: flex;
  align-content: center;

  &.yellow {
    background: linear-gradient(212.61deg, #ffab00 -4.48%, #fe8655 83.79%);
  }

  &.green {
    background: linear-gradient(217.39deg, #84c83a 7.59%, #10a565 97.75%);
  }

  &.card {
    height: 93%;
    border-radius: 10px;
    border: none;
    margin-right: 1%;
  }
}

.retirement-card {
  background-size: 636px;
  mix-blend-mode: normal;
  background-position: center;
  background-attachment: initial;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/retirement-image.png");

  &.card {
    border: none;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    height: 85%;
  }
}

.retirement-card-title {
  &.card-title {
    color: $white;
  }
}

.display-footer {
  border-top: none;
  display: flex;

  &.card-footer {
    border-top: none;
    display: flex;
    justify-content: flex-end;
    background-color: none;
    padding-right: 16px;
    padding-bottom: 16px;
  }
}

.retirement-text {
  font-size: "14px";
  font-family: "NotoSans-Regular";
  font-weight: "400px";
  font-style: normal;
  font-size: 14px;
  padding-left: 18px;
  color: $white;
}

.learn-more-btn {
  float: right;
  font-family: "NotoSans-Regular";
  width: 217px;
  height: 40px;
  background: $azureBlue;
  border-radius: 4px;
  color: $white;
  border: none;

  &.btn {
    background: $azureBlue;
    color: $white;
    border: none;
  }
}

.profile-card {
  margin-top: 0rem;
  margin-left: 0%;
  color: $white;
  background: linear-gradient(180deg, $lightBlue 20%, $darkishBlue 80%);
  box-shadow: 0px 3px 11px rgba(241, 241, 241, 0.92);
  height: 92%;
  width: auto;

  h5 {
    color: $white;
  }

  p {
    color: $white;
    font-family: "NotoSans-Regular";
  }

  i {
    float: right;
  }

  &.card {
    border: none;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  }
}

.product-card {
  margin-top: 1.5rem;
  margin-left: 0;
  width: auto;

  i {
    float: right;
  }

  &.card {
    height: 90%;
    border: none;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  }
}

.align-product-inner-cards {
  padding: 0% 2%;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin-right: 1%;
  overflow: hidden;
  margin-left: 1%;

  &.card-body {
    padding: 0% 2%;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: $lightPurpleGrey;
  border-radius: 0.8px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.inner-cards {
  display: flex;
  width: 21%;
  background: linear-gradient(180deg, #0232a3 0%, #0c1e4d 100%);
  box-shadow: 0px 3px 11px rgba(241, 241, 241, 0.92);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  border: none;
  margin-bottom: 3%;

  &.col-md-3,
  &.col-6 {
    width: 20%;
  }

  &.card {
    height: 84%;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  }
}

.align-queries {
  width: 45px;
  height: 45px;
}

.align-vas {
  width: 45px;
  height: 45px;
  margin-top: 21px;

  @media (max-width: 768px) {
    margin-top: 13px;
  }
}

.align-vas-text {
  width: 45px;
  height: 45px;
  margin-top: 21px;
  width: 100px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }
}

.align-queries-compliments {
  width: 45px;
  height: 45px;
  margin-top: 21px;

  @media (max-width: 768px) {
    margin-top: 13px;
  }
}

.align-queries-compliments-text {
  margin-top: 22px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }
}

.align-inner-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  h5 {
    font-size: 16px;
    font-style: normal;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    text-align: center;
  }

  i {
    height: 50px;
    width: 50px;
  }
}

.product-inner-cards {
  margin-top: 8px;
  margin-bottom: 9.5px;
  width: 100%;
  height: 40%;
  background: $lightBlue;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
  border: none;

  &.card {
    height: 42%;
    background: $lightBlue;
    border-radius: 8px;
    border: none;
  }
}

.align-product-card-content {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  display: flex;

  i {
    margin-bottom: 0%;
    margin-left: 10px;
  }

  h5 {
    font-size: 20px;
    font-family: "NotoSans-Regular";
    font-weight: 100;
    height: 60%;
    top: calc(50% - 32px / 2 + 171px);
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.133333px;
    margin-left: 2%;
    margin-bottom: 4%;
  }

  .align-risk-img {
    margin-left: 1.5rem;
  }

  .align-risk {
    margin-top: 4%;
    font-family: "NotoSans-Medium";
    font-size: 20px;
  }
}

.get-in-touch-card {
  margin-top: 1.5rem;
  height: 80%;
  width: auto;

  h4 {
    i {
      float: right;
    }
  }

  &.card {
    height: 90%;
    border: none;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  }
}

.align-inner-cards {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  overflow: hidden;

  &.card-body {
    padding-bottom: 0%;
  }
}

.goal-divider {
  width: 55%;
  height: 1px;
  background: $white;
  border-radius: 0.8px;
}

.card-title {
  /* Heading */
  min-height: auto;
  text-decoration: none;
  margin-bottom: 15px;
  font-family: "NotoSans-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.171429px;
  color: $standard-black;
  padding-left: 16px;
}

.card-text {
  font-size: 14px;
  font-family: "NotoSans-Regular";
  font-weight: "400px";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  color: $standard-black;
  padding-left: 18px;
}

@media (max-width: 768px) {
  .dashboard-divider {
    display: flex;
    height: 1px;
    background: $lightPurpleGrey;
    border-radius: 0.5px;
    top: 15px;
    bottom: 15px;
    margin-bottom: 16px;
    margin-top: 126px;
    width: 98%;
    margin-left: 1%;
  }

  .dashboard-divider2 {
    display: flex;
    height: 1px;
    background: $lightPurpleGrey;
    border-radius: 0.5px;
    top: 15px;
    bottom: 15px;
    margin-bottom: 0px;
    margin-top: 37px;
    width: 98%;
    margin-left: 1%;
  }

  .align-span {
    padding-top: 0%;
  }

  .align-complete {
    font-size: 16px;
  }

  .goal-card {
    width: auto;

    &.card {
      height: 98%;
    }
  }

  .profile-card {
    &.card {
      margin-top: 1%;
    }
  }

  .beneficiary-card {
    &.card {
      margin-top: 4%;
    }
  }

  .align-goal-inner-cards {
    flex-direction: column;
    align-items: center;
    display: flex;
    overflow: hidden;
    justify-content: flex-start;
    flex-wrap: wrap;

    &.card-body {
      margin: 0% 0% 4% 0%;
      padding-bottom: 0%;
    }
  }

  .goals-achieved-inner-cards {
    &.card {
      border: none;
      height: 48%;
      width: 90%;
    }
  }

  .align-goal-inner-card-content {
    display: flex;
    margin-top: 0%;

    h5 {
      font-family: "NotoSans-Medium";
      letter-spacing: 0.133333px;
      font-weight: 500;
      font-size: 16px;
    }

    .speedometer {
      height: 106px !important;
      width: 165px !important;
      margin-left: 0%;
    }
  }

  .card-title {
    min-height: auto;
    text-decoration: none;
    margin-bottom: 15px;
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $standard-black;
  }

  .card-text {
    /* Type something */
    font-family: "NotoSans-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }

  .monthly-income-inner-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;

    &.card {
      width: 90%;
      height: 48%;
      border: none;
      margin-right: 0%;
    }
  }

  .align-get-in-touch {
    &.card {
      margin-top: 1rem;
    }
  }

  .divider {
    width: 500%;
    height: 1px;
    background: $lightPurpleGrey;
    border-radius: 0.8px;
    margin-bottom: 0px;
  }

  .retirement-card {
    width: auto;
    margin-left: 1%;

    &.card {
      border: none;
      height: 139%;
      background-image: url("../../assets/img/retirement-ipad.png");
      border-radius: 4px;
      background-size: cover;
      margin-left: 0%;
    }
  }

  .learn-more-btn {
    width: 100%;
    height: auto;
    background: $azureBlue;
    border-radius: 4px;
    color: $white;

    &.btn {
      background: $azureBlue;
      border-radius: 4px;
      color: $white;
    }
  }

  .align-inner-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-evenly;
    justify-content: space-evenly;

    &.card-body {
      padding: 0.5% 0% 1% 0%;
    }
  }

  .inner-cards {
    justify-content: center;
    align-items: center;
    width: 44%;
    margin-bottom: 1%;

    &.col-md-3,
    &.col-6 {
      width: 34%;
    }

    &.card {
      height: 46%;
    }
  }

  .align-inner-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;

    i {
      height: 40px;
      width: 40px;
    }
  }

  .progress {
    background-color: #d8d8d8;
    border-radius: 20px;
    position: relative;
    margin: 15px 0;
    height: 30px;
    max-width: 100%;
    width: 75%;

    @media (max-width: 360px) {
      width: 72%;
    }
  }

  .align-queries {
    width: 40px;
    height: 40px;
  }

  .align-star {
    width: 45px;
    height: 45px;
    margin-top: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1194px) {
  .dashboard-divider2 {
    display: none;
  }

  .dashboard-divider {
    display: none;
  }

  .col-md-4 {
    padding-left: 0.5rem;
  }

  .card-title {
    font-size: 18px;
  }

  .align-arrow {
    padding-top: 0px;
  }

  .align-complete {
    font-size: 14px;
    padding-left: 5px;
  }

  .align-progress {
    width: 99%;
  }

  .align-span {
    padding: 2% 5% 3% 5%;
  }

  .card-text {
    padding-left: 16px;
  }

  .goal-card-col {
    &.col-md-7 {
      padding-left: 0.5rem;
    }
  }

  .align-star {
    margin-top: 16px;
  }

  .profile-card-col {
    &.col-md-4 {
      padding-left: 0.5rem;

      padding-bottom: 0.5rem;
    }
  }

  .product-card-col {
    &.col-md-4 {
      padding-left: 0.5rem;
    }
  }

  .product-investment-card {
    margin-bottom: 1.5px;
    padding-bottom: 1%;

    &.card {
      height: 43%;
      width: 102%;
      margin-top: 0%;
    }
  }

  .investment-content {
    margin-top: 0%;
    padding-top: 0%;
    padding-bottom: 1%;
    align-content: flex-start;
  }

  .box {
    flex: 1;
    font-size: 10px;
  }

  .goals-achieved-inner-cards {
    &.card {
      border: none;
      height: 90%;
    }
  }

  .monthly-income-inner-cards {
    &.card {
      height: 90%;
      border: none;
    }
  }

  .goal-card {
    width: 100%;

    &.card {
      height: 83%;
    }
  }

  .align-goal-inner-card-content {
    h5 {
      font-family: "NotoSans-Medium";
      letter-spacing: 0.133333px;
      font-weight: 500;
      font-size: 13px;
    }

    .speedometer {
      //overriding speedometer height and width values
      height: 145px !important;

      .segment-value {
        // Overriding the font size for segment value of Speedometer
        font-size: 12px !important;
        font-family: "NotoSans-SemiBold" !important;
      }

      .current-value {
        // Overriding the current percentage font size of Speedometer
        font-size: 12px !important;
      }
    }

    &.card-body {
      padding: 1% 0% 4% 0%;
    }
  }

  .align-goal-inner-cards {
    justify-content: space-between;
    margin-left: 0px;
    margin-right: 0px;

    &.card-body {
      padding: 0px 12px 0px 16px;
    }
  }

  .profile-card {
    margin-top: 0rem;
    width: auto;

    &.card {
      height: 99%;
      border: none;
    }
  }

  .product-card {
    margin-top: 1rem;
    width: 110%;

    &.card {
      height: 85%;
    }
  }

  .align-product-inner-cards {
    justify-content: space-around;

    &.card-body {
      padding: 0% 16px;
    }
  }

  .retirement-card {
    background-image: url("../../assets/img/ipad-image.png");
    background-size: 464px;

    &.card {
      height: 83%;
      width: 103%;
    }
  }

  .retirement-card-col {
    &.col-md-5 {
      flex: 0 0 auto;
      width: 40.666667%;
    }
  }

  .get-in-touch-card {
    margin-top: 1rem;
    margin-left: 1rem;

    &.card {
      height: 85%;
      margin-left: 4%;
    }
  }

  .product-inner-cards {
    width: 100%;
    border: none;

    &.card {
      height: 43%;
      width: 102%;
    }
  }

  .inner-cards {
    border: none;
    margin-bottom: 1%;

    &.card {
      width: 24%;
      height: 91%;
      border-radius: 10px;
    }
  }

  .align-product-card-content {
    h5 {
      margin-bottom: 6%;
    }

    i {
      margin-bottom: 0%;
      margin-left: 10px;
    }

    .align-risk {
      font-size: 16px;
      margin-top: 2%;
    }

    .align-risk-img {
      margin-left: 1rem;
    }
  }

  .investment-content {
    i {
      height: 79%;
    }

    .investment-heading {
      font-size: 16px;
    }

    span {
      font-size: 13px;
      padding-top: 2%;
    }
  }

  .align-product-card-content {
    h5 {
      font-family: "NotoSans-Medium";
      font-size: 14px;
    }
  }

  .align-inner-card-content {
    h5 {
      font-size: 14px;
    }
  }

  .align-inner-cards {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
    overflow: hidden;

    &.card-body {
      margin-left: 0%;
      margin-right: 0%;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 0% 2% 0% 3%;
    }
  }
}

.dashboard {
  &.right-arrow {
    height: 24px;
    width: 24px;
    background-color: transparent;
    appearance: none;
    background-image: url("../../assets/img/down-right-black.png");
    background-repeat: no-repeat;
    border: none;
    float: right;
    margin: 7px 7px 0 0;

    &.btn:hover {
      background-color: transparent;
    }
  }
}

.speedometer-goal-dashboard {
  .speedometer {
    .label {
      .segment-value {
        font-family: "NotoSans-SemiBold";
        line-height: 41px;
        letter-spacing: 0.171429px;
        color: $standard-black;
      }
    }
  }
}

//////////////////////////////My Goals Detail component/////////////////

.my-goals-detail {
  @media (min-width: 1000px) {
    padding: 0px 10px;
  }

  @media (max-width: 1200px) {
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    padding-top: 10px;
    margin-top: 0px;
  }

  .background-card {
    margin-top: 10px;
    padding-bottom: 20px;

    &.retirement-projection-background {
      @media (max-width: 1200px) {
        width: 100%;
        margin-left: 0px;
      }

      @media (max-width: 768px) {
        margin-left: 5px;
        width: 98%;
      }
    }

    .pl-row {
      padding-left: 10px;
    }

    .icons {
      width: 34px;
      height: 38px;
      margin-left: 55px;
      padding-bottom: 3px;

      &.right {
        float: right;
        margin-right: 26px;
      }

      &.right-card {
        width: 24px;
        height: 26px;
        float: right;
        margin-left: 0px;
        margin-right: 0px;
        color: $white;
        cursor: pointer;

        @media (max-width: 1200px) {
          height: 24px;
        }
      }

      @media (max-width: 1194px) {
        margin-left: 15px;
        width: 28px;
        height: 28px;
        padding-bottom: 1px;
      }

      @media (max-width: 428px) {
        width: 18px;
        height: 14px;
      }
    }

    .card-header {
      font-family: "NotoSans-Medium";
      font-weight: 500;
      font-size: 18px;
      padding-left: 15px;
      line-height: 35px;
      letter-spacing: 0.2px;
      color: $standard-black;

      @media (max-width: 768px) {
        padding-left: 10px;
        line-height: 22px;
      }
    }

    .mb-statement {
      color: $azureBlue;
    }

    .member-benefit-statement {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.133333px;
      color: #0033aa;
      text-align: right;
      padding: 5px 10px 0px 0px;

      @media (max-width: 768px) {
        text-align: left;
        padding: 5px 0px 0px 0px;
        font-size: 14px;
      }
    }

    .pdf-icon {
      width: 24px;
      height: 24px;

      &.error {
        margin-right: 0px;
      }

      @media (max-width: 500px) {
        &.table-icon {
          width: 13px;
          height: 13px;
          margin-right: 1%;
        }
      }
    }

    .blue-card {
      height: 232px;
      background: $lightBlue;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
      border-radius: 8px;
      text-align: center;
      padding: 15px;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        padding: 15px 10px;
        height: 200px;
        margin-bottom: 10px;
      }

      .separating-line {
        &.separating-blue-card {
          width: 100%;
          box-shadow: none;
          padding: 0;
        }
      }

      .blue-card-text {
        font-family: "NotoSans-Regular";
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.133333px;
        color: $white;
        min-height: 75px;
        margin-top: 5px;

        @media (max-width: 1200px) {
          font-size: 16px;
          line-height: 22px;
        }

        @media (max-width: 768px) {
          font-size: 14px;
          min-height: 50px;
          line-height: 20px;

          @media (max-width: 400px) {
            &.padding-botom {
              margin-bottom: 5px;
            }
          }
        }
      }

      .blue-card-date {
        font-family: "NotoSans-Bold";
        font-weight: 700;
        font-size: 16px;
        margin-top: 20px;

        @media (max-width: 768px) {
          font-size: 12px;

          @media (max-width: 400px) {
            &.margin-top {
              margin-top: 10px;
            }
          }
        }

        &.form-group {
          .neo-datepicker-container {
            .form-control {
              font-family: "NotoSans-Bold";
              font-weight: 700;
              font-size: 16px;
              padding-top: 0;
              color: $white;
              background-color: transparent;
              border: none;
              text-align: center;

              @media (max-width: 768px) {
                font-size: 12px;
              }
            }
          }
        }
      }

      .blue-card-image {
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
      }

      .blue-card-money {
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
        margin-left: 28px;
      }
    }

    .expectations-header {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.2px;
      color: $standard-black;
      margin-top: 40px;
      margin-bottom: 15px;
      padding: 0 50px;

      @media (max-width: 1200px) {
        padding: 0 30px;
      }

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 25px;
        padding: 0 5px;
        margin-top: 20px;
        text-align: center;
        margin-bottom: 10px;
      }
    }

    .row {
      &.net-card {
        width: 97.5%;
        margin-inline: auto;

        @media (max-width: 1440px) {
          width: 98%;
        }

        @media (max-width: 786px) {
          width: 100%;

          .col-md-6 {
            &.outside-net-replacement-ratio-card {
              padding: 10px 0px 0px 0px;
            }

            &.estimated-monthly-income-card {
              padding: 0px;
            }
          }
        }

        .net-replacement-ratio-card {
          background: #2a333d;
          box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
          width: 100%;
          border-radius: 5px;
          height: 90%;
          color: $white;

          @media (min-width: 768px) and (max-width: 1200px) {
            height: 157px;
          }

          .net-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            i {
              padding-right: 10px;

              @media (min-width: 768px) and (max-width: 1200px) {
                margin-bottom: 13px;
              }
            }
          }

          @media (max-width: 768px) {
            height: 92%;
          }

          .card-body {
            padding: 0px;
          }

          h5 {
            font-size: 16px;
            font-family: "NotoSans-Medium";
            letter-spacing: 0.133333px;
            font-weight: 500;
            padding-top: 11px;
            padding-left: 16px;
          }

          .speedometer-goal-card-dashboard {
            display: flex;
            justify-content: center;
            padding-top: 8px;

            @media (min-width: 768px) and (max-width: 1200px) {
              padding-top: 0px;
            }

            .speedometer {
              .label {
                .segment-value {
                  font-family: "NotoSans-SemiBold";
                  line-height: 41px;
                  letter-spacing: 0.171429px;
                  color: $standard-black;
                }
              }
            }
          }
        }

        .monthly-income-card {
          background: var(--linear, linear-gradient(214deg, #f46363 0%, #ff3636 100%));
          border-radius: 5px;
          width: 100%;
          height: 90%;
          border: none;
          color: $white;

          &.yellow {
            background: linear-gradient(212.61deg, #ffab00 -4.48%, #fe8655 83.79%);
          }

          &.green {
            background: linear-gradient(217.39deg, #84c83a 7.59%, #10a565 97.75%);
          }

          .net-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            i {
              padding-right: 10px;
            }
          }

          @media (max-width: 768px) {
            width: 100%;
            margin-top: 0%;
            height: 100%;
          }

          .card-body {
            padding: 0px;
          }

          h5 {
            font-size: 16px;
            font-family: "NotoSans-Medium";
            letter-spacing: 0.133333px;
            font-weight: 500;
            padding-left: 5px;
          }

          .monthly-income-card-content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            padding-top: 8%;

            @media (max-width: 768px) {
              padding-top: 8%;
            }

            p {
              font-size: 24px;
              font-family: "NotoSans-Regular";
              font-style: normal;
              font-weight: 400;
            }

            .monthly-income-card-divider {
              width: 55%;
              height: 1px;
              background: $white;
              border-radius: 0.8px;
            }
          }
        }
      }
    }

    .expectation-card {
      height: 100px;
      background: $white;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
      border-radius: 5px;
      margin: 0px 0 20px 0;

      @media (max-width: 768px) {
        margin: 10px 0 2px 0;
        height: 100%;
      }

      .expectation-card-red {
        height: 40px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: #f46363;
        align-items: initial;

        &.yellow {
          background: #ffab00;
        }

        &.green {
          background: #84c83a;
        }
      }

      .expectation-card-header {
        font-family: "NotoSans-Bold";
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.133333px;
        color: $white;
        padding-top: 5px;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      .speedometer-goal-my-goal {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 25px;

        .speedometer {
          .label {
            .segment-value {
              font-family: "NotoSans-SemiBold";
              font-weight: 600 !important;
              line-height: 41px;
              letter-spacing: 0.171429px;
              color: $standard-black;
            }
          }
        }
      }

      .expectation-card-text {
        font-family: "NotoSans-SemiBold";
        font-weight: 600;
        font-size: 14px;
        line-height: 35px;
        letter-spacing: 0.2px;
        color: #f46363;
        text-align: center;
        padding-top: 10px;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 25px;
          padding-left: 5px;
          padding-right: 5px;
        }

        &.yellow {
          color: #ffab00;
        }

        &.green {
          color: #84c83a;
        }
      }
    }

    .monthly-income-card {
      height: 82px;
      background: $white;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 40px;

      @media (max-width: 768px) {
        margin-top: 10px;
        margin-bottom: 15px;
        padding: 15px 10px;
      }

      @media (max-width: 400px) {
        margin-top: 10px;
        margin-bottom: 5px;
        padding: 15px 10px;
      }

      .monthly-income-text {
        font-family: "NotoSans-Regular";
        font-weight: 400;
        font-size: 18px;
        line-height: 35px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #f46363;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 25px;
        }

        &.yellow {
          color: #ffab00;
        }

        &.green {
          color: #84c83a;
        }

        .bold-amount {
          font-family: "NotoSans-Bold";
          font-weight: 700;
        }
      }
    }

    .improve-header {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.2px;
      color: $standard-black;
      margin: 40px 0;

      @media (max-width: 1200px) {
        margin-bottom: 30px;
      }

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 25px;
        margin: 20px 0;
      }
    }

    .improve-card {
      height: 258px;
      background: $white;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 50px;
      text-align: center;

      @media (max-width: 1200px) {
        height: 280px;
        margin-bottom: 10px;
      }

      @media (max-width: 768px) {
        height: 200px;
        margin-bottom: 10px;
      }

      .improve-card-image {
        height: 80px;
        width: 80px;
        margin: 15px 0px 15px 28px;
      }

      .improve-card-text {
        font-family: "NotoSans-SemiBold";
        font-weight: 600;
        font-size: 20px;
        line-height: 35px;
        text-align: center;
        letter-spacing: 0.2px;
        color: $standard-black;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }

    .notes-text {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: $black;
      padding: 0px 80px 30px 80px;

      @media (max-width: 768px) {
        font-size: 10px;
        line-height: 20px;
        margin-top: 10px;
        padding: 0px 5px 20px 5px;
      }
    }

    .back-to-dash {
      font-family: "NotoSans-Regular";
      font-weight: 500;
      font-size: 16px;
      line-height: 35px;
      letter-spacing: 0.2px;
      color: $azureBlue;
      text-decoration: none;
      margin-left: 1rem;

      @media (max-width: 768px) {
        margin-left: 0px;
      }
    }

    .center-back-to-dash {
      @media (max-width: 768px) {
        text-align: center;
      }
    }

    .separating-line-max {
      &.back-to-dash-margin {
        margin-bottom: 20px;
      }
    }

    .col-3 {
      &.blue-card-size {
        width: 24%;

        @media (max-width: 768px) {
          width: 49%;
          padding: 0 7px;
        }
      }
    }

    .col-6 {
      &.improve-card-size {
        width: 29%;

        @media (max-width: 1200px) {
          width: 34%;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .col-12 {
      &.monthly-card-size {
        width: 96%;

        @media (max-width: 768px) {
          width: 99%;
        }
      }
    }

    .row {
      &.align-improve-cards {
        justify-content: center;
      }

      &.align-blue-card-center {
        justify-content: center;

        @media (max-width: 768px) {
          margin-bottom: 25px;
        }
      }

      &.monthly-card-center {
        justify-content: center;
      }
    }

    .separating-line {
      &.hidden-on-web {
        @media (max-width: 768px) {
          margin-top: 30px;
        }
      }
    }
  }
}

@media (min-width: 1880px) {
  .retirement-card {
    background: url("../../assets/img/retirement-image.png") no-repeat center center;
    background-size: 105%;
    overflow: hidden;
  }
}