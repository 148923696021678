@import "../../App/Styles/Variables.scss";

.mt-4 {
  &.employer-dashboard-card {
    width: auto;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    height: auto;
    border-radius: 5px;
    padding-left: 16px;
    padding-right: 16px;
    margin: 30px 15px 30px 5px;
  }
}

.employer-dashboard-card {
  .emp-dashboard-card-heading {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 35px;
  }

  .card-sub-heading-emp-dashboard {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: $standard-black;
    margin-top: 0px;
    padding-left: 2px;
    margin-bottom: 0px;
    padding-bottom: 20px;
  }

  .row {
    &.resize {
      padding-right: 0px;

      .col-md-12 {
        &.resize {
          padding: 0px 0px;
        }
      }
    }
  }

  .employer-dashboard-tabs {
    margin-top: 10px;
    padding: 10px;

    .tabs-header {
      margin-bottom: 28px;

      .nav {
        font-family: "NotoSans-Regular";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        --bs-nav-link-color: #0d1325;
        --bs-nav-link-hover-color: #0d1325;

        &.nav-tabs {
          font-family: "NotoSans-Regular";
          font-weight: 400;
          font-size: 14px;
          line-height: 35px;
          text-align: center;
          color: $standard-black;

          .nav-link {
            border: none;
            padding-bottom: 12px;
            margin-right: 40px;

            &.active {
              font-family: "NotoSans-SemiBold";
              font-weight: 600;
              border-bottom: 4px solid $azureBlue;
              color: $standard-black;
            }
          }
        }
      }
    }
  }

  .row {
    &.search-by {
      margin-left: 0px;
    }
  }

  .search-dropdown {
    display: inline-block;
    width: 93%;

    .form-control {
      border: none;
      width: 100%;

      &:focus {
        border: none;
        box-shadow: none;
      }
    }

    .css-26l3qy-menu {
      width: 110%;
    }

    .css-b8ldur-Input {
      margin: 2px 2px 2px 37px;
    }

    .css-g1d714-ValueContainer {
      background: url("../Assets/Img/img-search.png") no-repeat 0% 50%;
    }

    .css-1wa3eu0-placeholder {
      margin-left: 30px;
    }

    &.form-control:focus {
      border: none;
    }

    label {
      display: none;
    }

    .css-yk16xz-control {
      border: none;
      width: 100%;

      .css-1hb7zxy-IndicatorsContainer {
        display: none;
      }
    }

    .react-select-bs__indicators {
      &.css-1hb7zxy-IndicatorsContainer {
        display: none;
      }
    }

    .react-select-bs__indicators {
      &.css-1hb7zxy-IndicatorsContainer {
        display: none;
      }
    }

    &.react-select-bs {
      .react-select-bs__control {
        &.react-select-bs__control--is-focused {
          &.react-select-bs__control--menu-is-open {
            &.css-1pahdxg-control {
              border-color: white;
              box-shadow: none;

              &:hover {
                border-color: none;
              }
            }
          }
        }
      }
    }
  }

  .card {
    position: relative;
    padding-left: 0px;
    width: 83%;
    margin-bottom: 0.7%;
  }

  .card-body {
    height: 48px;
    padding-top: 5px;
    padding-left: 3px;
  }

  .align-filter {
    display: relative;
    float: right;
    padding-top: 5px;
    background: none;
    border: none;
    padding-right: 0px;

    &.btn:hover {
      border: none;
      background: none;
    }

    &:focus {
      border: none;
      background: none;
    }
  }

  .btn-filter {
    color: $white;
    --bs-gradient: none;
    background-color: $azureBlue;
    border-color: $azureBlue;
    height: 48px;
    width: 15.3%;
    margin-left: 1%;

    @media (min-width: 768px) and (max-width: 1194px) {
      width: 13.3%;
    }

    &.btn:hover {
      background-color: $azureBlue;
      --bs-gradient: none;
      color: white;
    }
  }

  .row {
    &.padding-bottom-radio {
      margin-bottom: 20px;

      .col-md-2 {
        &.expand-col-surface {
          padding-left: 20px;

          @media (max-width: 1500px) {
            width: 21%;
          }
        }
      }
    }
  }

  .filter-header {
    font-family: "NotoSans-Semibold";
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $standard-black;
    margin: 15px 0px 35px 0px;
  }

  .neo-radio-list {
    &.filter-radio-list {
      .custom-control {
        margin-bottom: 20px;
        display: flex;

        input[type="radio"] {
          accent-color: $azureBlue;
        }

        .custom-control-input {
          height: 18px;
          width: 18px;
        }

        .custom-control-label {
          font-family: "NotoSans-Medium";
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: $standard-black;
          margin-left: 10px;
        }
      }
    }
  }

  .custom-control {
    &.custom-checkbox {
      .custom-control-input {
        margin-bottom: 20px;
      }

      .custom-control-label {
        font-family: "NotoSans-Medium";
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: $standard-black;
        margin-left: 10px;
      }
    }
  }

  .filtered-by-header {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $standard-black;
    margin: 5% 0%;
  }

  .filtered-by-sub-header {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: $standard-black;
    margin: 2% 0%;
  }

  .selected-pill-items {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: $standard-black;
    margin: 2% 0%;

    .background-pill {
      width: 180px;
      background: #deecff;
      border-radius: 16px;
      height: 28px;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      margin-right: 10px;

      .close-button {
        display: flex;
        float: right;
        background: url(../../App/assets/img/cross-grey.png) no-repeat 96% 50%;
        margin-right: 8px;

        &.btn {
          &.btn-primary {
            --bs-btn-bg: none;
            --bs-btn-border-color: none;
          }
        }
      }
    }
  }

  .clear-all-button {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $standard-black;
    --bs-btn-color: none;
    --bs-btn-bg: none;
    --bs-btn-border-color: none;
    --bs-btn-hover-border-color: none;
    --bs-btn-hover-bg: none;
    --bs-btn-active-bg: none;
    --bs-btn-active-border-color: none;
  }

  .empty-grid-container {
    width: 100%;
    height: 621px;
    background-color: #f5f5f5;
    border-radius: 6px;
    border: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;

    .grid-info {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
      letter-spacing: 0.2px;
      color: $lighterGrey;
      padding-top: 40px;
      margin-bottom: 9rem;
    }

    @media (max-width: 768px) {
      height: 392px;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;

      .grid-info {
        margin-bottom: 0px;
        text-align: center;
      }
    }
  }

  .filter-inline-checkboxes {
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: $standard-black;

    input {
      height: 18px;
      width: 18px;
      accent-color: $azureBlue;
      margin-right: 10px;
    }

    .filter-inline-checkboxes-label {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  .show-dropdown {
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    padding: 12px;
    height: 27%;
    width: 83%;

    @media (min-width: 768px) and (max-width: 1194px) {
      width: 62.7%;
      height: 25%;
    }
  }

  .col-md-6 {
    &.scheme-options-align {
      margin-left: 18px;
    }
  }

  .align-filter-link {
    &.row {
      display: flex;
      align-items: baseline;
      margin-top: 20px;
      padding-right: 0px;
      padding-left: 0px;
      margin-bottom: 10px;
    }
  }

  .align-buttons {
    display: flex;
    justify-content: flex-end;

    .btn-cancel {
      box-sizing: border-box;
      background: $white;
      border: 2px solid $azureBlue;
      color: $azureBlue;
      border-radius: 4px;
      width: 30%;
      margin-right: 2%;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1.23px;
      height: 48px;

      &.btn:hover {
        color: $azureBlue;
        background-color: white;
      }
    }

    .btn-apply {
      background: $azureBlue;
      color: $white;
      border-radius: 4px;
      width: 30%;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1.23px;
      height: 48px;

      &.btn:hover {
        background-color: $azureBlue;
        --bs-gradient: none;
        color: white;
      }
    }
  }

  .table {
    &.neo-grid {
      .number-col {
        text-align: left;
      }

      &.table-bordered {
        thead {
          border: none;
          border-style: none;

          tr {
            text-overflow: ellipsis;
            max-width: 200px;
            background: none;
            color: $standard-black;
            font-family: "NotoSans-SemiBold";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 31px;
            border: none;
            border-style: hidden;
            text-align: left;

            th {
              background: none;
              border: none;

              .column-sort-icon {
                right: auto;
                margin: 7px 0px 0px 10px;
              }
            }
          }

          .dropdown-three-dots {
            display: none;
          }
        }

        .dropdown-three-dots {
          float: right;
          margin-right: 15px;
          height: 74px;

          .btn-group {
            height: 24px;
            width: 50px;
            background: url("../Assets/Img/android.png") no-repeat 98.2% 50%;
            margin-top: 24px;

            @media (max-width: 1700px) {
              width: 40px;
            }

            .dropdown-toggle::after {
              display: none;
            }

            .dropdown-menu {
              width: 242px;
              background: $white;
              box-shadow:
                0px 0px 2px rgba(0, 0, 0, 0.03),
                0px 6px 10px rgba(0, 0, 0, 0.08);
              border-radius: 5px;
              border: none;
              --bs-dropdown-link-hover-bg: none;
              --bs-dropdown-link-active-color: #0d1325;
              --bs-dropdown-link-active-bg: none;
            }

            .btn {
              &.btn-primary {
                border: none;
                --bs-btn-color: none;
                --bs-btn-bg: none;
                --bs-btn-hover-bg: none;
                --bs-btn-active-color: none;
                --bs-btn-active-bg: none;
              }
            }
          }
        }

        tbody {
          border-radius: 5px;

          tr {
            height: 75px;
            background: $white;
            box-shadow: 0px 1px 4px rgb(139 146 167 / 50%);
            border-radius: 4px;
            border: none;

            :first-child {
              border-top-left-radius: 5px 5px;
              border-bottom-left-radius: 5px 5px;
            }

            :last-child {
              border-top-right-radius: 5px 5px;
              border-bottom-right-radius: 5px 5px;
            }

            td {
              border: none;

              .scheme-name {
                margin-left: 10px;
              }

              .align-profile {
                width: 45px;
                height: 45px;
                margin-right: 2%;
                margin-left: 16px;
              }
            }

            .border-three-dots {
              border-left: 1px solid #dbdbdb;
            }
          }
        }
      }
    }
  }

  .neo-pager {
    table {
      border-collapse: unset;
      border-spacing: 0px 15px;
    }

    .table {
      &.neo-grid {
        thead {
          display: contents;
        }
      }
    }
  }

  .neo-pager-controls {
    .neo-pager-page-size {
      color: black;
      width: 182px;

      @media (min-width: 768px) and (max-width: 1194px) {
        width: 193px;
      }

      select.form-control {
        display: inline;
        width: auto;
        padding: 0;
        border-radius: 0px;
        border: none;
      }
    }

    .pagination {
      .page-item {
        &.active {
          .page-link {
            background-color: $azureBlue;
            color: white;
            border-radius: 50%;
            --bs-gradient: none;
          }
        }

        .page-link {
          border: none;
        }
      }
    }
  }

  .seperating-line {
    &.margin-top {
      margin-top: 20px;
    }
  }
}