@import "../../App/Styles/Variables.scss";

.fa-dashboard-card {
  width: auto;
  background: $white;
  box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  height: auto;
  border-radius: 5px;
  margin-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;

  .fa-dashboard-card-heading {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 35px;
  }

  .card-sub-heading-fa-dashboard {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: $standard-black;
    margin-top: 0px;
    padding-left: 2px;
    margin-bottom: 0px;
    padding-bottom: 35px;
  }

  .row {
    &.search-by {
      margin-left: 0px;
    }
  }

  .search-dropdown {
    display: inline-block;
    width: 93%;

    .form-control {
      border: none;
      width: 100%;

      &:focus {
        border: none;
        box-shadow: none;
      }
    }

    .css-26l3qy-menu {
      width: 110%;
    }

    .css-b8ldur-Input {
      margin: 2px 2px 2px 37px;
    }

    .css-g1d714-ValueContainer {
      background: url("../Assets/Img/img-search.png") no-repeat 0% 50%;
    }

    .css-1wa3eu0-placeholder {
      margin-left: 30px;
    }

    &.form-control:focus {
      border: none;
    }

    label {
      display: none;
    }

    .css-yk16xz-control {
      border: none;
      width: 100%;

      .css-1hb7zxy-IndicatorsContainer {
        display: none;
      }
    }

    .react-select-bs__indicators {
      &.css-1hb7zxy-IndicatorsContainer {
        display: none;
      }
    }

    .react-select-bs__indicators {
      &.css-1hb7zxy-IndicatorsContainer {
        display: none;
      }
    }

    &.react-select-bs {
      .react-select-bs__control {
        &.react-select-bs__control--is-focused {
          &.react-select-bs__control--menu-is-open {
            &.css-1pahdxg-control {
              border-color: white;
              box-shadow: none;

              &:hover {
                border-color: none;
              }
            }
          }
        }
      }
    }
  }

  .card {
    position: relative;
    margin-left: 10px;
    padding-left: 0px;
    width: 83%;
  }

  .card-body {
    height: 48px;
    padding-top: 5px;
    padding-left: 3px;
  }

  .align-filter {
    display: relative;
    float: right;
    padding-top: 5px;
    background: none;
    border: none;
    padding-right: 0px;

    &.btn:hover {
      border: none;
      background: none;
    }

    &:focus {
      border: none;
      background: none;
    }
  }

  .btn-filter {
    color: $white;
    --bs-gradient: none;
    background-color: $azureBlue;
    border-color: $azureBlue;
    height: 40px;
    width: 14.6%;
    margin-left: 1%;

    @media (min-width: 768px) and (max-width: 1194px) {
      width: 13.3%;
    }

    &.btn:hover {
      background-color: $azureBlue;
      --bs-gradient: none;
      color: white;
    }
  }

  .empty-grid-container {
    width: 100%;
    height: 621px;
    background-color: #f5f5f5;
    border-radius: 6px;
    border: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;

    .grid-info {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
      letter-spacing: 0.2px;
      color: $lighterGrey;
      padding-top: 40px;
      margin-bottom: 9rem;
    }

    @media (max-width: 768px) {
      height: 392px;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;

      .grid-info {
        margin-bottom: 0px;
        text-align: center;
      }
    }
  }

  .hide-grid {
    display: none;
  }

  .report-grid {
    display: block;

    .col-md-3 {
      width: 20%;
      padding-left: 21px;

      @media (min-width: 768px) and (max-width: 1194px) {
        padding-left: 14px;
      }

      .heading-text {
        font-family: "NotoSans-SemiBold";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 31px;
        color: $standard-black;
      }
    }

    .reports-grid-card {
      width: 99.3%;
      background: $white;
      box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
      border-radius: 4px;
      border: none;
      height: 74px;

      .col-md-1 {
        &.dropdown-three-dots {
          width: 4%;
        }
      }

      .dropdown-three-dots {
        float: right;
        margin-right: 15px;
        height: 74px;
        border-left: 1px solid #dbdbdb;

        .btn-group {
          height: 24px;
          width: 50px;
          background: url("../Assets/Img/android.png") no-repeat 98.2% 50%;
          margin-top: 23px;

          @media (max-width: 1700px) {
            width: 40px;
          }

          .dropdown-toggle::after {
            display: none;
          }

          .dropdown-menu {
            width: 242px;
            background: $white;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.03), 0px 6px 10px rgba(0, 0, 0, 0.08);
            border-radius: 5px;
            border: none;
            --bs-dropdown-link-hover-bg: none;
            --bs-dropdown-link-active-color: #0d1325;
            --bs-dropdown-link-active-bg: none;
          }

          .btn {
            &.btn-primary {
              border: none;
              --bs-btn-color: none;
              --bs-btn-bg: none;
              --bs-btn-hover-bg: none;
              --bs-btn-active-color: none;
              --bs-btn-active-bg: none;
            }
          }
        }
      }

      .border-left {
        border-left: 1px solid #dbdbdb;
        margin-right: 48px;
        height: 300px;
      }

      .card-body {
        padding: 0px;

        .card-value {
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: $standard-black;
          width: 20%;
          float: left;
          padding-top: 23px;

          &.scheme-text {
            @media (min-width: 768px) and (max-width: 1194px) {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 110px;
            }
          }

          &.status-text {
            padding-left: 0.4%;

            @media (min-width: 768px) and (max-width: 1194px) {
              padding-left: 8%;
            }
          }

          &.align-id {
            padding-left: 0.2%;

            @media (min-width: 768px) and (max-width: 1194px) {
              padding-left: 7.4%;
            }
          }

          &.align-member-name {
            padding-left: 0.7%;
            width: 10%;

            @media (min-width: 768px) and (max-width: 1194px) {
              padding-left: 8%;
            }
          }
        }

        .align-profile {
          width: 45px;
          height: 45px;
          margin-right: 2%;
          margin-left: 16px;
        }

        .align-img-value {
          font-family: "NotoSans-Semibold";
          font-style: normal;
          font-size: 14px;
          line-height: 19px;
          color: $standard-black;
          width: 20%;
          float: left;
          padding-top: 15px;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }

  .show-dropdown {
    width: 66.8%;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    padding: 16px;
    top: 29.5%;
    height: 27%;
    position: absolute;

    @media (min-width: 768px) and (max-width: 1194px) {
      width: 62.7%;
      top: 32%;
      height: 25%;
    }
  }

  .align-reports-dropdown {
    color: black;

    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }

    .css-yk16xz-control {
      font-family: "NotoSans-Regular";
      box-sizing: border-box;
      background: $shadowGrey;
      border: 1px solid #afb3c2;
      border-radius: 4px;
      height: 48px;
    }

    .css-b8ldur-Input {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #afb3c2;

      .react-select-bs__input {
        :input {
          width: 110px;
        }
      }
    }

    .css-4ljt47-MenuList {
      padding-bottom: 0px;
      padding-top: 0px;
    }

    .css-g1d714-ValueContainer {
      color: black;
      background: url(../../../src/App/assets/img/dropdown-blue.png) no-repeat 96% 50%;
      height: 48px;
      border: none;
      background-color: $white;
      border: 1px solid #8b92a7;
      border-radius: 2px;
    }

    .reports-label {
      margin-top: 0px;
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    @media (max-width: 768px) {
      padding-top: 10px;
    }

    .css-1uccc91-singleValue {
      width: 86%;
    }

    @media (min-width: 768px) and (max-width: 1194px) {
      .css-1uccc91-singleValue {
        width: 80%;
      }

      .react-select-bs__option {
        &.css-1n7v3ny-option {
          text-overflow: ellipsis;
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
        }

        &.css-yt9ioa-option {
          text-overflow: ellipsis;
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
        }

        &.react-select-bs__option--is-selected {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .align-filter-link {
    &.row {
      display: flex;
      align-items: baseline;
      margin-top: 7%;
      padding-right: 0px;
      padding-left: 0px;
    }


  }

  .align-buttons {
    display: flex;
    justify-content: flex-end;

    .btn-cancel {
      box-sizing: border-box;
      background: $white;
      border: 2px solid #002b99;
      color: $azureBlue;
      border-radius: 4px;
      width: 30%;
      margin-right: 2%;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1.23px;
      height: 40px;

      &.btn:hover {
        color: $azureBlue;
        background-color: white;
      }
    }

    .btn-apply {
      background: $azureBlue;
      color: $white;
      border-radius: 4px;
      width: 30%;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1.23px;
      height: 40px;

      &.btn:hover {
        background-color: $azureBlue;
        --bs-gradient: none;
        color: white;
      }
    }
  }
}