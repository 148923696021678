@import "../Variables.scss";

.modal-container {
  .modal-dialog {
    &.modal-lg {
      &.terms-conditions-modal {
        .modal-content {
          border-radius: 5px;
          max-width: 1000px;
          align-items: normal;

          @media (max-width: 768px) {
            width: 400px;
          }

          @media (max-width: 400px) {
            width: 344px;
          }

          .modal-header {
            padding-bottom: 0px;
            padding-left: 32px;
            padding-top: 20px;

            @media (max-width: 768px) {
              padding-left: 20px;
            }

            @media (min-width: 768px) and (max-width: 1200px) {
              padding-left: 28px;
            }

            h5 {
              font-family: "NotoSans-Medium";
              font-weight: 500;
              font-size: 18px;
              line-height: 35px;
              letter-spacing: 0.2px;
              color: $standard-black;
              padding-bottom: 17px;
            }
          }

          button.close {
            display: none;
          }

          .modal-body {
            border-top: 1px solid $greyLine;
            border-bottom: 1px solid $greyLine;
            padding: 0px;
            text-align: left;

            .modal-content {
              .modal-header {
                .display-alert-terms-conditions {
                  &.alert {
                    background: url(../../assets/img/info.png) no-repeat 2% 43%;
                    padding-left: 3%;
                    background-size: 2.5%;

                    h6 {
                      font-family: "NotoSans-Regular";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      color: $standard-black;
                      margin-left: 3%;
                      padding-top: 4px;
                      float: left;
                      text-align: left;
                    }
                  }

                  &.alert-primary {
                    background-color: $lightBlueGrey;
                    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
                    border: none;
                    width: 100%;
                    margin-right: 15px;
                  }

                  @media (min-width: 768px) and (max-width: 1194px) {
                    &.alert {
                      background-position-x: 2%;
                      background-position-y: 40%;
                      background-size: 2.5%;
                      padding-left: 4%;

                      h6 {
                        font-size: 14px;
                      }
                    }
                  }

                  @media (max-width: 768px) {
                    &.alert {
                      background-position-x: 2%;
                      background-position-y: 44%;
                      background-size: 6%;
                      padding-left: 10%;

                      h6 {
                        font-size: 14px;
                        margin-left: 0px;
                      }
                    }
                  }
                }
              }

              .modal-text {
                overflow-y: scroll;
                overflow-x: none;
                height: 400px;
                font-family: "NotoSans-Regular";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
                letter-spacing: 0.133333px;
                color: $standard-black;
                padding-left: 26px;
                padding-right: 23px;
                margin-bottom: 0px;

                @media (max-width: 768px) {
                  padding-left: 20px;
                  padding-right: 16px;
                }

                ul {
                  list-style-type: disc;
                }

                .sun-editor {
                  border: none;
                }
              }
            }
          }

          .modal-footer {
            display: flex;
            border: none;
            justify-content: center;
            flex-wrap: nowrap;
            width: 100%;

            @media (max-width: 768px) {
              width: 80%;
            }

            .btn-download {
              margin-right: 44%;
              padding-left: 30px;

              &.btn {
                font-size: 16px;
                letter-spacing: 0.133333px;
                color: #0033aa;
                border: none;
                background: url("../../assets/img/download-img.png") no-repeat 0% 50%;

                @media (max-width: 768px) {
                  width: 90%;
                }
              }
            }

            .disabled {
              &.btn-success {
                color: $standard-black;
                background: $lighterGrey;
                border-radius: 4px;
                border: none;
              }
            }

            .btn-success {
              font-family: "NotoSans-Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 1.23px;
              color: $white;
              --bs-gradient: none;
              background-color: $azureBlue;
              border-color: $azureBlue;
              height: 48px;
              width: 184px;
              border-radius: 4px;
            }

            .btn-secondary {
              border-radius: 4px;
              font-family: "NotoSans-Medium";
              font-style: normal;
              font-weight: 500;
              text-align: center;
              height: 48px;
              width: 184px;
            }

            @media (max-width: 768px) {
              flex-direction: column-reverse;

              .btn-success {
                width: 90%;
              }

              .btn-secondary {
                width: 90%;
              }

              .btn-download {
                margin-right: 0%;
                padding-left: 2px;

                &.btn {
                  background: url("../../assets/img/download-img.png") no-repeat 10% 50%;
                  margin-left: 10%;
                  margin-top: 4%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-container {
  .modal-dialog {
    &.modal-lg {
      &.terms-conditions-footer-modal {
        .modal-content {
          border: none;
          border-radius: 5px;
          max-width: 1000px;
          align-items: normal;

          @media (max-width: 768px) {
            width: 400px;
          }

          @media (max-width: 400px) {
            width: 344px;
          }

          .modal-header {
            color: $component-header-background;
            padding-bottom: 0px;
            padding-left: 32px;
            padding-top: 20px;

            h5 {
              font-family: "NotoSans-Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 35px;
              letter-spacing: 0.2px;
              color: $standard-black;
              padding-bottom: 17px;
            }
          }

          button.close {
            display: none;
          }

          .modal-body {
            border-top: 1px solid $greyLine;
            border-bottom: 1px solid $greyLine;
            padding: 0px;
            text-align: left;

            .modal-content {

              .modal-text {
                overflow-y: scroll;
                overflow-x: none;
                height: 400px;
                font-family: "NotoSans-Regular";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
                letter-spacing: 0.133333px;
                color: $standard-black;
                padding-left: 26px;
                padding-right: 23px;
                margin-bottom: 0px;

                @media (max-width: 768px) {
                  padding-left: 20px;
                  padding-right: 16px;
                }

                ul {
                  list-style-type: disc;
                }

                .sun-editor {
                  border: none;
                }
              }
            }
          }

          .modal-footer {
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;

            @media (max-width: 768px) {
              width: 80%;
            }

            .btn-download {
              width: 25%;

              &.btn {
                font-size: 16px;
                letter-spacing: 0.133333px;
                color: #0033aa;
                border: none;
                background: url("../../assets/img/download-img.png") no-repeat 0% 50%;

                @media (max-width: 768px) {
                  width: 90%;
                }
              }
            }

            .btn-success {
              font-family: "NotoSans-Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 1.23px;
              color: $white;
              --bs-gradient: none;
              background-color: $azureBlue;
              border-color: $azureBlue;
              height: 40px;
              width: 184px;
              border-radius: 4px;
            }

            @media (max-width: 768px) {
              flex-direction: column-reverse;

              .btn-success {
                width: 90%;
              }

              .btn-download {
                margin-right: 0%;
                padding-left: 2px;

                &.btn {
                  background: url("../../assets/img/download-img.png") no-repeat 10% 50%;
                  margin-left: 10%;
                }
              }
            }
          }
        }
      }
    }
  }
}