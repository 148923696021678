@import "../Variables.scss";

.blue-ribbon {
  display: flex;
  justify-content: center;
  align-items: center;

  .display-alert-profile {
    &.alert {
      background: url(../../assets/img/info.png) no-repeat 1% 50%;
      width: 91%;
      background-size: 24px;
      padding-left: 3rem;
      display: flex;
      align-items: center;
      height: auto;

      @media (max-width: 1200px) {
        background-size: 24px;
        margin-bottom: 0px;

        h6 {
          line-height: 18px;
        }
      }

      @media (max-width: 768px) {
        background-position-x: 2%;
      }

      h6 {
        font-family: "NotoSans-Regular";
        font-size: 14px;
        color: $standard-black;
        margin: 0;
        line-height: 1.5;
      }

      .semibold-text {
        font-family: "NotoSans-SemiBold";
      }
    }

    &.alert-primary {
      background-color: #deecff;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
      border: none;
    }
  }
}